import { createContext } from "react";

interface TargetContext {
  id: number;
  type: string;
}

export const ScopeContext = createContext<TargetContext | undefined>(undefined);

interface Props {
  children: React.ReactNode;
  target?: {
    id: number;
    type: string;
  };
}

const ScopeProvider = ({ children, target }: Props) => {
  return (
    <ScopeContext.Provider value={target}>{children}</ScopeContext.Provider>
  );
};

export default ScopeProvider;
