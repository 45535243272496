import { Stack } from "@mantine/core";
import { useCallback, useMemo } from "react";
import {
  DISTRIBUTION_PARAM,
  PRICE_METHOD,
} from "src/components/EstimationView/Table/constants";
import { useUpdateProfitCalculationMutation } from "src/data/api/api";
import { useProfitCalculation } from "src/hooks/useProfitCalculation";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import MarginMethods from "./components/MarginMethods";
import PercentageInputGroup from "./components/PercentageInputGroup";
import { applicationLevelButtons, priceMethodButtons } from "./constants";

interface Props {
  packageId: string;
}

const EstimationMarginForm = ({ packageId }: Props) => {
  const [priceMethod] = useQueryStringParams(PRICE_METHOD);
  const [level] = useQueryStringParams(DISTRIBUTION_PARAM);
  const profitCalculation = useProfitCalculation(packageId);
  const [updateProfitCalculation] = useUpdateProfitCalculationMutation();

  const percentage = useMemo(() => {
    if (
      [level, "package"].includes(profitCalculation?.distribution || "") &&
      profitCalculation?.formula === priceMethod
    ) {
      return profitCalculation.percentages;
    }

    return {};
  }, [
    level,
    priceMethod,
    profitCalculation?.distribution,
    profitCalculation?.formula,
    profitCalculation?.percentages,
  ]);

  const onMethodChange = useCallback(
    (method: string) => {
      if (profitCalculation != null && level != null) {
        updateProfitCalculation({
          id: profitCalculation.id,
          body: {
            profit_calculation: {
              formula: method,
              distribution: "package",
              percentages: {
                package: 0.0,
              },
            },
          },
        });
      }
    },
    [level, profitCalculation, updateProfitCalculation],
  );

  const onDistributionChange = useCallback(
    (distribution: string) => {
      if (profitCalculation != null && priceMethod != null) {
        updateProfitCalculation({
          id: profitCalculation.id,
          body: {
            profit_calculation: {
              formula: priceMethod,
              distribution: distribution,
              percentages: {
                package: 0.0,
              },
            },
          },
        });
      }
    },
    [priceMethod, profitCalculation, updateProfitCalculation],
  );

  const onValueChange = useCallback(
    (key: string, value: number) => {
      if (profitCalculation != null && priceMethod != null && level != null) {
        updateProfitCalculation({
          id: profitCalculation.id,
          body: {
            profit_calculation: {
              formula: priceMethod,
              distribution: level === "main" ? "package" : level,
              percentages: {
                ...percentage,
                [key]: value,
              },
            },
          },
        });
      }
    },
    [
      level,
      percentage,
      priceMethod,
      profitCalculation,
      updateProfitCalculation,
    ],
  );

  return (
    <Stack
      bd="1px solid grey"
      p="lg"
      style={{
        borderRadius: "10px",
      }}
      w="28%"
    >
      {/* Pricing methods */}
      <MarginMethods
        buttons={priceMethodButtons}
        groupName="Select pricing method"
        onMethodChange={onMethodChange}
        queryParam={PRICE_METHOD}
        type="pricing method"
      />

      {/* application levels */}
      <MarginMethods
        buttons={applicationLevelButtons}
        groupName="Choose application level"
        onMethodChange={onDistributionChange}
        queryParam={DISTRIBUTION_PARAM}
        type="application level"
      />

      {/* input fields based on price methods and/or application level */}
      {priceMethod && level ? (
        <PercentageInputGroup
          level={level}
          method={priceMethod}
          onChange={onValueChange}
          percentage={percentage}
        />
      ) : null}
    </Stack>
  );
};

export default EstimationMarginForm;
