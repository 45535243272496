import { Button, Group, NumberInput, NumberInputProps } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { EMPTY_STRING } from "src/utils/empty";
import { formatNumber } from "src/utils/formatNumber";
import { DEFAULT_PLACEHOLDER } from "../Table/constants";
import { ESCALATION_TYPES } from "./constants";
import styles from "./EscalationField.module.scss";
import { EscalationTypeSelectField } from "./EscalationTypeSelectField";

const ESCALATION_INPUT_STYLES: NumberInputProps["styles"] = {
  input: {
    textAlign: "right",
    paddingInlineEnd: "calc(70px + var(--mantine-spacing-xs))",
  },
};

interface Props {
  readonly onCancel: () => void;
  readonly onSubmit: (unit: string, value: number) => void;
  readonly unit: string;
  readonly value: number;
}

export const EscalationField = React.memo<Props>(function _EscalationField({
  onCancel,
  onSubmit,
  unit,
  value,
}) {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = React.useState<string>(unit);
  const [fieldValue, setFieldValue] = React.useState<string | number>(() =>
    unit === ESCALATION_TYPES.fixed ? value : value * 100,
  );

  const isFixed = selectedUnit === ESCALATION_TYPES.fixed;
  React.useEffect(() => {
    if (!isFocused && fieldValue === 0) {
      setFieldValue(EMPTY_STRING);
    }
  }, [fieldValue, isFocused]);

  const handleFocus = React.useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = React.useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleValueChange = React.useCallback(
    (inputtedValue: string | number) => {
      setFieldValue(typeof inputtedValue === "string" ? 0 : inputtedValue);
    },
    [],
  );

  const handleSave = React.useCallback(() => {
    const numberValue = typeof fieldValue === "string" ? 0 : fieldValue;
    const submittedValue = isFixed
      ? formatNumber(numberValue, 0, 2)
      : formatNumber(numberValue / 100, 0, 4);

    onSubmit(selectedUnit, submittedValue ?? 0);
  }, [fieldValue, isFixed, onSubmit, selectedUnit]);

  return (
    <Group className={styles.root}>
      <NumberInput
        className={styles.input}
        decimalScale={2}
        hideControls={true}
        onBlur={handleBlur}
        onChange={handleValueChange}
        onFocus={handleFocus}
        placeholder={isFixed ? "$ -" : DEFAULT_PLACEHOLDER}
        prefix={isFixed ? "$" : undefined}
        rightSection={
          <EscalationTypeSelectField
            className={styles.rightSection}
            onChange={setSelectedUnit}
            value={selectedUnit}
          />
        }
        rightSectionWidth={70}
        styles={ESCALATION_INPUT_STYLES}
        thousandSeparator=","
        value={fieldValue}
      />
      <Group className={styles.buttons}>
        <Button
          className={classNames(styles.button, styles.cancelButton)}
          onClick={onCancel}
          variant="outline"
        >
          Cancel
        </Button>
        <Button
          className={classNames(styles.button, styles.saveButton)}
          onClick={handleSave}
          variant="outline"
        >
          Save
        </Button>
      </Group>
    </Group>
  );
});
