import { useContextMenu } from "mantine-contextmenu";
import { DataTable, DataTableRowClickHandler } from "mantine-datatable";
import React from "react";
import { useExpandableLineItems } from "src/context/ExpandableLineItemsProvider";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import { useUpdateItemsMutation } from "src/data/api/api";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { WithClassname } from "src/types/withClassName";
import { CONTEXT_MENU_STYLES } from "../../constants";
import { useCurrentPackage } from "../../hooks/useCurrentPackage";
import { useEstimationColumnMain } from "../../hooks/useEstimationColumnMain";
import { useLineItemsContextMenu } from "../../hooks/useLineItemsContextMenu";
import InnerChildItems from "../InnerChildItems";
import TableContainer from "../TableContainer";
import { useRowFactory } from "../TableRow";
import styles from "./LineItemBundle.module.scss";

const isExpandable = () => true;

interface Props extends WithClassname {
  noHeader: boolean;
  lineItems: BundleLineItem[];
  packages: PackageId[];
  showNewItemButton?: boolean;
}

const LineItemBundle = React.memo<Props>(function _LineItemBundle({
  className,
  lineItems,
  packages,
}) {
  const { showContextMenu } = useContextMenu();
  const { expandableItems } = useExpandableLineItems();
  const { selectedItems, setSelectedItems } = useSelectedLineItems();
  const [updateItem] = useUpdateItemsMutation();
  const selectedPackageId = useCurrentPackage();
  const rowFactory = useRowFactory();

  const handleUpdate = React.useCallback(
    (bundleId: number, lineItem: LineItem) => {
      updateItem({
        bundle_id: bundleId,
        line_item: {
          line_items_attributes: [lineItem],
        },
      });
    },
    [updateItem],
  );

  const columnsMain = useEstimationColumnMain(handleUpdate, true, false);
  const createLineItemsContextMenu = useLineItemsContextMenu(
    packages,
    updateItem,
  );

  const handleNewLineItem = React.useCallback(() => {
    if (selectedPackageId != null && lineItems[0]?.bundle_id) {
      updateItem({
        bundle_id: lineItems[0].bundle_id,
        line_item: {
          line_items_attributes: [
            {
              package_id: Number(selectedPackageId),
            },
          ],
        },
      });
    }
  }, [lineItems, selectedPackageId, updateItem]);

  const expandable = React.useCallback(
    ({ record }: { record: BundleLineItem }) => {
      return (
        record.children.filter(
          (r) => r.display_attributes?.display !== "inline",
        ).length > 0
      );
    },
    [],
  );

  const handleRowContextMenu: DataTableRowClickHandler<BundleLineItem> =
    React.useCallback(
      ({ record, event }) => {
        const contextMenu = createLineItemsContextMenu(record, selectedItems);

        showContextMenu(contextMenu, CONTEXT_MENU_STYLES)(event);
      },
      [createLineItemsContextMenu, selectedItems, showContextMenu],
    );

  const handleInnerTable = React.useCallback(
    ({ record }: { record: BundleLineItem }) => {
      return (
        <InnerChildItems
          handleUpdate={handleUpdate}
          isEditable={true}
          parentItem={record}
        />
      );
    },
    [handleUpdate],
  );

  const bundleId = lineItems[0]?.bundle_id;
  if (!bundleId) return null;
  return (
    <TableContainer
      bundleId={bundleId}
      className={className}
      onClick={handleNewLineItem}
      showNewItemButton={false}
    >
      <DataTable
        borderColor="var(--mantine-color-gray-2)"
        classNames={{
          table: styles.root,
        }}
        columns={columnsMain}
        height="fit-content"
        highlightOnHover
        highlightOnHoverColor={"#00a69c0d"}
        noHeader={true}
        noRecordsIcon={<React.Fragment />}
        onRowContextMenu={handleRowContextMenu}
        onSelectedRecordsChange={setSelectedItems}
        records={lineItems}
        rowExpansion={{
          expanded: {
            recordIds: expandableItems,
          },
          expandable: expandable,
          allowMultiple: true,
          initiallyExpanded: isExpandable,
          content: handleInnerTable,
        }}
        rowFactory={rowFactory}
        selectedRecords={selectedItems}
        selectionColumnStyle={{ borderRightColor: "transparent" }}
        withColumnBorders
      />
    </TableContainer>
  );
});

export default LineItemBundle;
