import { TableTr, TableTrProps } from "@mantine/core";
import { useHoveredLineItem } from "src/context/HoveredLineItemProvider";
import { BundleLineItem } from "src/data/api/types/getBundles";

export interface TableRowProps {
  record: BundleLineItem;
  index: number;
  children: React.ReactNode;
  rowProps: TableTrProps;
  expandedElement?: React.ReactNode;
}

const TableRow = ({
  rowProps,
  children,
  expandedElement,
  record,
}: TableRowProps) => {
  const { setHoveredId } = useHoveredLineItem();

  return (
    <>
      <TableTr
        {...rowProps}
        onMouseEnter={() => setHoveredId(record.id)}
        onMouseLeave={() => setHoveredId(null)}
      >
        {children}
      </TableTr>
      {expandedElement}
    </>
  );
};

export default TableRow;
