import { ActionIcon, Group, Menu, Tooltip } from "@mantine/core";
import {
  IconCirclePlus,
  IconClipboardCopy,
  IconPlus,
} from "@tabler/icons-react";
import { memo, useCallback, useMemo, useState } from "react";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import {
  useCloneItemsMutation,
  useCreateLineItemMutation,
} from "src/data/api/api";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { useScrollIntoView } from "src/hooks/useScrollIntoView";
import { SCROLLABLE_ELEMENT } from "src/utils/scrollableElement";
import {
  CONTEXT_MENU_KEYSTROKE_STYLES,
  KEYSTROKES,
  PACKAGE_PARAM,
} from "../../constants";

interface Props2 {
  onClick: () => void;
}

const NewItemButton = ({ onClick }: Props2) => {
  const [packageId] = useQueryStringParams(PACKAGE_PARAM);
  const {
    action,
    selectedItems,
    setAction,
    setSelectedItems,
    selectedBundles,
    setSelectedBundles,
  } = useSelectedLineItems();
  const [opened, setOpened] = useState(false);
  const [cloneItems] = useCloneItemsMutation();
  const scrollIntoView = useScrollIntoView(SCROLLABLE_ELEMENT, 1000);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      setOpened(true);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const count = useMemo(() => {
    return selectedBundles.length + selectedItems.length;
  }, [selectedBundles.length, selectedItems.length]);

  const handlePaste = useCallback(() => {
    if (action != null && count > 0) {
      cloneItems({
        action_type: action,
        package_ids: [],
        bundle_ids: selectedBundles.map((b) => ({ id: b })),
        line_item_ids: selectedItems.map((i) => ({ id: i.id })),
        location: {
          type: "Package",
          id: Number(packageId),
        },
      }).then(() => {
        scrollIntoView();
      });

      setAction(null);
      setSelectedItems([]);
      setSelectedBundles([]);
    }
  }, [
    action,
    cloneItems,
    count,
    packageId,
    scrollIntoView,
    selectedBundles,
    selectedItems,
    setAction,
    setSelectedBundles,
    setSelectedItems,
  ]);

  const disabled = useMemo(() => {
    if (action == null) return true;

    return selectedBundles.length < 1 && selectedItems.length < 1;
  }, [action, selectedBundles.length, selectedItems.length]);

  return (
    <Menu
      arrowPosition="side"
      closeOnItemClick
      onClose={handleClose}
      opened={opened}
      position="right-start"
      shadow="md"
      styles={{
        itemSection: CONTEXT_MENU_KEYSTROKE_STYLES,
      }}
      width={200}
    >
      <Menu.Target>
        <Tooltip label="Add new item">
          <ActionIcon
            onClick={onClick}
            onContextMenu={handleContextMenu}
            radius="xl"
            size="xl"
            variant="filled"
          >
            <IconPlus />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={onClick} rightSection={KEYSTROKES.CTRL_A}>
          <Group gap="xs">
            <IconCirclePlus size="16px" />
            Add line item
          </Group>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item disabled={disabled} onClick={handlePaste}>
          <Group gap="xs">
            <IconClipboardCopy size="16px" />
            {`Paste ${count} item(s)`}
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

interface Props {
  packageId: string;
}

const CreateBundleButtons = memo(({ packageId }: Props) => {
  const [createBundle] = useCreateLineItemMutation();
  const scrollIntoView = useScrollIntoView(SCROLLABLE_ELEMENT, 700);

  const createLineItemBundle = useCallback(() => {
    createBundle({
      bundle: {
        package_id: packageId,
        bundle_type: "line_item",
      },
    }).then(scrollIntoView);
  }, [createBundle, packageId, scrollIntoView]);

  return (
    <Group
      bottom={40}
      gap="sm"
      left={10}
      pl="sm"
      pos="absolute"
      style={{
        zIndex: 110,
      }}
    >
      <NewItemButton onClick={createLineItemBundle} />
    </Group>
  );
});

export default CreateBundleButtons;
