import { Text } from "@mantine/core";
import React from "react";
import { PRICE_METHOD } from "src/components/EstimationView/Table/constants";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { calculatePercent } from "src/utils/calculatePercent";
import { calculateTotal } from "src/utils/calculateTotal";
import { formatCurrency } from "src/utils/formatCurrency";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import styles from "./MarginTotalCell.module.scss";

interface Props {
  records: PackageTotal[];
}

export const MarginTotalCell = React.memo<Props>(function _MarginTotalCell({
  records,
}) {
  const [priceMethod] = useQueryStringParams(PRICE_METHOD);
  const value: JSX.Element = React.useMemo(() => {
    const formula = priceMethod ?? records[0]?.formula;
    if (records[0]?.formula === formula) {
      const marginSum = calculateTotal(records, "margin");
      const sellSum = calculateTotal(records, "grand_total");
      const percent = calculatePercent(marginSum, sellSum);
      return (
        <>
          {formatCurrency(marginSum)}{" "}
          <span className={styles.subtext}>
            ({formatNumberHundredths(percent)}%)
          </span>
        </>
      );
    }

    return <>0.0%</>;
  }, [priceMethod, records]);

  return <Text className={styles.root}>{value}</Text>;
});
