import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useState } from "react";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { LabelValue } from "src/types/util/labelValue";
import MarginButton from "../MarginButton";

interface Props1 {
  onClose: () => void;
  opened: boolean;
  text: string;
  onConfirm: () => void;
}

const WarningModal = ({ onClose, opened, text, onConfirm }: Props1) => {
  return (
    <Modal
      onClose={onClose}
      opened={opened}
      title="Heads up!"
      withCloseButton={true}
    >
      <Stack>
        <Text>{text}</Text>
        <Group justify="end">
          <Button color="red" onClick={onConfirm}>
            Continue
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Group>
      </Stack>
    </Modal>
  );
};

interface Props {
  type: string;
  groupName: string;
  buttons: LabelValue[];
  queryParam: string;
  onMethodChange: (method: string) => void;
}

const MarginMethods = ({
  groupName,
  buttons,
  queryParam,
  onMethodChange,
  type,
}: Props) => {
  const [paramValue, setParamValue] = useQueryStringParams(queryParam);
  const [clicked, setClicked] = useState<string | null>(null);
  const [opened, { open, close }] = useDisclosure(false);

  const handleConfirmed = useCallback(() => {
    if (clicked) {
      setParamValue(clicked);
      onMethodChange(clicked);
    }

    close();
  }, [clicked, close, onMethodChange, setParamValue]);

  const handleClick = useCallback(
    (value: string | null) => {
      if (paramValue == null && value != null) {
        setParamValue(value);
        onMethodChange(value);
      } else {
        open();
        setClicked(value);
      }
    },
    [onMethodChange, open, paramValue, setParamValue],
  );

  return (
    <Stack>
      <Text fw={600}>{groupName}</Text>
      <Group>
        {buttons.map(({ label, value }) => (
          <MarginButton
            key={label}
            isSelected={value === paramValue}
            onClick={handleClick}
            title={label}
            value={value}
          />
        ))}
      </Group>
      <WarningModal
        onClose={close}
        onConfirm={handleConfirmed}
        opened={opened}
        text={`If you change ${type}, current ${paramValue} will be deleted. Do you want to continue?`}
      />
    </Stack>
  );
};

export default MarginMethods;
