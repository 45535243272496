import {
  IconClock,
  IconCurrencyDollar,
  IconFileCheck,
  IconHammer,
  IconPigMoney,
  IconRulerMeasure,
  IconSettings2,
  IconShieldCheck,
  IconSitemap,
  IconUrgent,
  IconUsers,
} from "@tabler/icons-react";
import {
  DESC_ENGINEERING_OPTIONS,
  DESC_SUBCONTRACTOR_OPTIONS,
  dropdownDisplayStyles,
  DropdownType,
  GroupDropdownOptions,
  QUANTITY_UNIT_OPTIONS,
  TRADE_OPTIONS,
} from "./Table/constants";
import styles from "./Table/DropdownCell.module.scss";

interface Returns {
  readonly displayClassName: string | undefined;
  readonly options: ReadonlyArray<GroupDropdownOptions>;
}

export function getDropdownOptions(dropdownType: DropdownType): Returns {
  const displayClassName = dropdownDisplayStyles[dropdownType];
  let options;
  switch (dropdownType) {
    case DropdownType.CostType:
      options = COST_TYPE_OPTIONS;
      break;
    case DropdownType.Trade:
      options = TRADE_OPTIONS;
      break;
    case DropdownType.DescEngineering:
      options = DESC_ENGINEERING_OPTIONS;
      break;
    case DropdownType.DescSubcontractor:
      options = DESC_SUBCONTRACTOR_OPTIONS;
      break;
    case DropdownType.LaborForChildUnits:
      options = LABOR_FOR_CHILD_HOURS_OPTIONS;
      break;
    case DropdownType.Units:
      options = QUANTITY_UNIT_OPTIONS;
      break;
  }
  return { displayClassName, options };
}

const COST_TYPE_OPTIONS: ReadonlyArray<GroupDropdownOptions> = [
  {
    label: "",
    options: [
      {
        className: styles.equipment,
        icon: <IconHammer size={14} />,
        label: "Equipment",
        value: "equipment",
      },
      {
        className: styles.material,
        icon: <IconSettings2 size={14} />,
        label: "Material",
        value: "material",
      },
      {
        className: styles.labor,
        icon: <IconSitemap size={14} />,
        label: "Labor",
        value: "labor",
      },
      {
        className: styles.subcontractor,
        icon: <IconUsers size={14} />,
        label: "Subcontractor",
        value: "subcontractor",
      },
      {
        className: styles.engineering,
        icon: <IconRulerMeasure size={14} />,
        label: "Engineering",
        value: "engineering",
      },
      {
        className: styles.buffer,
        icon: <IconClock size={14} />,
        label: "PM Default Add-On",
        value: "pm_time_buffer",
      },
      {
        className: styles.permit,
        icon: <IconFileCheck size={14} />,
        label: "Permit",
        value: "permit",
      },
      {
        className: styles.finders,
        icon: <IconCurrencyDollar size={14} />,
        label: "Finders fee",
        value: "finders_fee",
      },
      {
        className: styles.bond,
        icon: <IconShieldCheck size={14} />,
        label: "Bond",
        value: "bond",
      },
      {
        className: styles.contingency,
        icon: <IconUrgent size={14} />,
        label: "Contingency",
        value: "contingency",
      },
      {
        className: styles.allowance,
        icon: <IconPigMoney size={14} />,
        label: "Allowance",
        value: "allowance",
      },
      {
        className: styles.other,
        icon: <IconCurrencyDollar size={14} />,
        label: "Other",
        value: "other",
      },
    ],
  },
];

const LABOR_FOR_CHILD_HOURS_OPTIONS: ReadonlyArray<GroupDropdownOptions> = [
  {
    label: "",
    options: [
      {
        display: "hrs",
        icon: <IconClock size={14} />,
        label: "Hours (hrs)",
        value: "fixed_hours",
      },
      {
        display: "hrs/unit",
        icon: <IconClock size={14} />,
        label: "Hours per unit (hrs/unit)",
        value: "hours_per_unit",
      },
    ],
  },
];
