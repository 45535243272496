import React from "react";

interface Args {
  ref?: React.RefObject<HTMLInputElement>;
  onEscape?: () => void;
  onSubmit?: () => void;
}

interface Returns {
  handleEnterBlur: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleEnterEscapeBlur: (event: React.KeyboardEvent) => void;
  handleEnterSubmit: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleEscapeBlur: (event: React.KeyboardEvent) => void;
  handleTextCellKeystrokes: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
}

export function useCellKeystrokes({ ref, onEscape, onSubmit }: Args): Returns {
  const handleEscapeBlur = React.useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case "Escape":
          ref?.current?.blur();
          break;
        default:
          break;
      }
    },
    [ref],
  );

  const handleEnterBlur = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case "Enter":
          ref?.current?.blur();
          break;
        default:
          break;
      }
    },
    [ref],
  );

  const handleEnterSubmit = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case "Enter":
          onSubmit?.();
          break;
        default:
          break;
      }
    },
    [onSubmit],
  );

  const handleEnterEscapeBlur = React.useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case "Escape":
        case "Enter":
          ref?.current?.blur();
          break;
        default:
          break;
      }
    },
    [ref],
  );

  const handleTextCellKeystrokes = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case "Enter":
          onSubmit?.();
          break;
        case "ArrowRight":
          // TODO: add logic for pressing right key to autocomplete
          break;
        case "Escape":
          onEscape?.();
          break;
        default:
          break;
      }
    },
    [onEscape, onSubmit],
  );

  return {
    handleEnterBlur,
    handleEnterEscapeBlur,
    handleEnterSubmit,
    handleEscapeBlur,
    handleTextCellKeystrokes,
  };
}
