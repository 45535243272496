import { EMPTY_STRING } from "src/utils/empty";
import { DEFAULT_PLACEHOLDER } from "../Table/constants";
import {
  ITERABLE_CONTENT,
  ITERABLE_CONTENT_KEYS,
  POPULATED_CONTENT_STYLES,
  WHITELISTED_ATTRIBUTES,
} from "./constants";
import { PROPOSAL_VALUES_KEYS, ProposalValues } from "./useProposalValues";

interface IterateElementsArgs {
  readonly altCount: number;
  readonly content: string;
}

export function iterateElements({
  altCount,
  content,
}: IterateElementsArgs): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const iterableParents = doc.querySelectorAll(
    `[${WHITELISTED_ATTRIBUTES.iterable}]`,
  );
  iterableParents.forEach((parentElement) => {
    parentElement.replaceChildren();
    const attribute =
      parentElement.getAttribute(WHITELISTED_ATTRIBUTES.iterable) ??
      EMPTY_STRING;

    const key = attribute?.match(/{{(.*?)}}/)?.[1]?.trim();
    if (
      key != null &&
      key in ITERABLE_CONTENT &&
      ITERABLE_CONTENT[key] != null
    ) {
      if (shouldCreateTable(key)) {
        appendTableHeader(key, parentElement);
        const body = document.createElement("tbody");
        if (key.startsWith("package")) {
          appendBaseRow(body, key);
        }

        appendIterableRows(altCount, body, key);
        parentElement.appendChild(body);
      }
    }
  });

  return doc.documentElement.outerHTML;
}

function shouldCreateTable(key: string): boolean {
  return [
    ITERABLE_CONTENT_KEYS.alternateCostTable,
    ITERABLE_CONTENT_KEYS.alternateDescriptionTable,
    ITERABLE_CONTENT_KEYS.alternateListTable,
    ITERABLE_CONTENT_KEYS.alternateScopeTable,
    ITERABLE_CONTENT_KEYS.packageCostTable,
    ITERABLE_CONTENT_KEYS.packageDescriptionTable,
    ITERABLE_CONTENT_KEYS.packageListTable,
    ITERABLE_CONTENT_KEYS.packageScopeTable,
  ].includes(key);
}

function isTableWithDescription(key: string): boolean {
  return [
    ITERABLE_CONTENT_KEYS.alternateDescriptionTable,
    ITERABLE_CONTENT_KEYS.alternateListTable,
    ITERABLE_CONTENT_KEYS.packageDescriptionTable,
    ITERABLE_CONTENT_KEYS.packageListTable,
  ].includes(key);
}

function isTableWithCost(key: string): boolean {
  return [
    ITERABLE_CONTENT_KEYS.alternateCostTable,
    ITERABLE_CONTENT_KEYS.alternateListTable,
    ITERABLE_CONTENT_KEYS.packageCostTable,
    ITERABLE_CONTENT_KEYS.packageListTable,
  ].includes(key);
}

function isTableWithScope(key: string): boolean {
  return [
    ITERABLE_CONTENT_KEYS.alternateScopeTable,
    ITERABLE_CONTENT_KEYS.packageScopeTable,
  ].includes(key);
}

function appendTableHeader(key: string, parentElement: Element): void {
  const header = document.createElement("thead");
  const headerRow = document.createElement("tr");
  const dataHeaderItems = document.createElement("th");
  const dataHeaderDescription = document.createElement("th");
  const dataHeaderScope = document.createElement("th");
  const dataHeaderCost = document.createElement("th");
  const emTitle = document.createElement("em");
  const emDescription = document.createElement("em");
  const emScope = document.createElement("em");
  const emCost = document.createElement("em");

  // eslint-disable-next-line immutable/no-mutation
  emTitle.textContent = "Items";
  dataHeaderItems.appendChild(emTitle);
  // eslint-disable-next-line immutable/no-mutation
  emDescription.textContent = "Description";
  dataHeaderDescription.appendChild(emDescription);
  // eslint-disable-next-line immutable/no-mutation
  emScope.textContent = "Scope";
  dataHeaderScope.appendChild(emScope);
  // eslint-disable-next-line immutable/no-mutation
  emCost.textContent = "Total sum";
  dataHeaderCost.appendChild(emCost);

  headerRow.appendChild(dataHeaderItems);

  if (isTableWithDescription(key)) {
    headerRow.appendChild(dataHeaderDescription);
  }

  if (isTableWithCost(key)) {
    headerRow.appendChild(dataHeaderCost);
  }

  if (isTableWithScope(key)) {
    headerRow.appendChild(dataHeaderScope);
  }

  header.appendChild(headerRow);
  parentElement.appendChild(header);
}

function appendBaseRow(body: HTMLTableSectionElement, key: string): void {
  const baseRow = document.createElement("tr");
  const dataBaseTitle = document.createElement("td");
  const dataBaseDescription = document.createElement("td");
  const dataBaseScope = document.createElement("td");
  const dataBaseCost = document.createElement("td");
  const emBaseTitle = document.createElement("em");
  const emBaseDescription = document.createElement("em");
  const emBaseScope = document.createElement("em");
  const spanBaseTitle = document.createElement("span");
  const spanBaseDescription = document.createElement("span");
  const spanBaseScope = document.createElement("span");
  const spanBaseCost = document.createElement("span");

  spanBaseTitle.setAttribute(
    WHITELISTED_ATTRIBUTES.placeholder,
    `{{${PROPOSAL_VALUES_KEYS.baseTitle}}}`,
  );
  spanBaseTitle.setAttribute(
    WHITELISTED_ATTRIBUTES.style,
    POPULATED_CONTENT_STYLES,
  );
  spanBaseDescription.setAttribute(
    WHITELISTED_ATTRIBUTES.entry,
    `{{${PROPOSAL_VALUES_KEYS.baseDescription}}}`,
  );
  spanBaseDescription.setAttribute(
    WHITELISTED_ATTRIBUTES.placeholder,
    `{{${PROPOSAL_VALUES_KEYS.baseDescription}}}`,
  );
  spanBaseScope.setAttribute(
    WHITELISTED_ATTRIBUTES.entry,
    `{{${PROPOSAL_VALUES_KEYS.baseScope}}}`,
  );
  spanBaseScope.setAttribute(
    WHITELISTED_ATTRIBUTES.placeholder,
    `{{${PROPOSAL_VALUES_KEYS.baseScope}}}`,
  );
  spanBaseCost.setAttribute(
    WHITELISTED_ATTRIBUTES.placeholder,
    `{{${PROPOSAL_VALUES_KEYS.baseCost}}}`,
  );
  spanBaseCost.setAttribute(
    WHITELISTED_ATTRIBUTES.style,
    POPULATED_CONTENT_STYLES,
  );

  emBaseTitle.appendChild(spanBaseTitle);
  emBaseDescription.appendChild(spanBaseDescription);
  emBaseScope.appendChild(spanBaseScope);
  dataBaseTitle.appendChild(emBaseTitle);
  dataBaseDescription.appendChild(emBaseDescription);
  dataBaseCost.appendChild(spanBaseCost);
  dataBaseScope.appendChild(emBaseScope);
  baseRow.appendChild(dataBaseTitle);
  if (isTableWithDescription(key)) {
    baseRow.appendChild(dataBaseDescription);
  }

  if (isTableWithScope(key)) {
    baseRow.appendChild(dataBaseScope);
  }

  if (isTableWithCost(key)) {
    baseRow.appendChild(dataBaseCost);
  }

  body.appendChild(baseRow);
}

function appendIterableRows(
  altCount: number,
  body: HTMLTableSectionElement,
  key: string,
): void {
  if (key in ITERABLE_CONTENT && ITERABLE_CONTENT[key] != null) {
    for (let i = 1; i <= altCount; i++) {
      const iterableElement = document.createElement(
        ITERABLE_CONTENT[key].element,
      );

      const dataTitle = document.createElement("td");
      const dataDescription = document.createElement("td");
      const dataScope = document.createElement("td");
      const dataCost = document.createElement("td");
      const emTitle = document.createElement("em");
      const emDescription = document.createElement("em");
      const emScope = document.createElement("em");
      const spanTitle = document.createElement("span");
      const spanDescription = document.createElement("span");
      const spanScope = document.createElement("span");
      const spanCost = document.createElement("span");

      spanTitle.setAttribute(
        WHITELISTED_ATTRIBUTES.placeholder,
        `{{${ITERABLE_CONTENT[key].placeholderTitle}${i}}}`,
      );
      spanTitle.setAttribute(
        WHITELISTED_ATTRIBUTES.style,
        POPULATED_CONTENT_STYLES,
      );
      spanDescription.setAttribute(
        WHITELISTED_ATTRIBUTES.entry,
        `{{${ITERABLE_CONTENT[key].entryDescription}${i}}}`,
      );
      spanDescription.setAttribute(
        WHITELISTED_ATTRIBUTES.placeholder,
        `{{${ITERABLE_CONTENT[key].entryDescription}${i}}}`,
      );
      spanScope.setAttribute(
        WHITELISTED_ATTRIBUTES.entry,
        `{{${ITERABLE_CONTENT[key].entryScope}${i}}}`,
      );
      spanScope.setAttribute(
        WHITELISTED_ATTRIBUTES.placeholder,
        `{{${ITERABLE_CONTENT[key].entryScope}${i}}}`,
      );
      spanCost.setAttribute(
        WHITELISTED_ATTRIBUTES.placeholder,
        `{{${ITERABLE_CONTENT[key].placeholderCost}${i}}}`,
      );
      spanCost.setAttribute(
        WHITELISTED_ATTRIBUTES.style,
        POPULATED_CONTENT_STYLES,
      );

      emTitle.appendChild(spanTitle);
      emDescription.appendChild(spanDescription);
      emScope.appendChild(spanScope);
      dataTitle.appendChild(emTitle);
      dataDescription.appendChild(emDescription);
      dataScope.appendChild(emScope);
      dataCost.appendChild(spanCost);

      iterableElement.appendChild(dataTitle);

      if (isTableWithDescription(key)) {
        iterableElement.appendChild(dataDescription);
      }

      if (isTableWithCost(key)) {
        iterableElement.appendChild(dataCost);
      }

      if (isTableWithScope(key)) {
        iterableElement.appendChild(dataScope);
      }

      body.appendChild(iterableElement);
    }
  }
}

interface LoadTemplatesReturns {
  readonly blank: string | undefined;
  readonly construction: string | undefined;
  readonly retrofit: string | undefined;
  readonly service: string | undefined;
}

export async function loadTemplates(): Promise<LoadTemplatesReturns> {
  try {
    const blankTemplateFetch = await fetch("/matrixBlankProposalTemplate.html");
    const blankTemplate = await blankTemplateFetch.text();
    const constructionTemplateFetch = await fetch(
      "/matrixConstructionProposalTemplate.html",
    );

    const constructionTemplate = await constructionTemplateFetch.text();
    const retrofitTemplateFetch = await fetch(
      "/matrixRetrofitProposalTemplate.html",
    );

    const retrofitTemplate = await retrofitTemplateFetch.text();

    const serviceTemplateFetch = await fetch(
      "/matrixServiceProposalTemplate.html",
    );
    const serviceTemplate = await serviceTemplateFetch.text();

    return {
      blank: blankTemplate.split("TEMPLATE")[1] ?? EMPTY_STRING,
      construction: constructionTemplate.split("TEMPLATE")[1] ?? EMPTY_STRING,
      retrofit: retrofitTemplate.split("TEMPLATE")[1] ?? EMPTY_STRING,
      service: serviceTemplate.split("TEMPLATE")[1] ?? EMPTY_STRING,
    };
  } catch {
    return {
      blank: undefined,
      construction: undefined,
      retrofit: undefined,
      service: undefined,
    };
  }
}

function preserveWhitespace(content: string): string {
  return content.replace(/ {2,}/g, (match) => match.replace(/ /g, "&nbsp;"));
}

interface ReplacePlaceholdersArgs {
  readonly content: string;
  readonly values: ProposalValues;
}

export function replacePlaceholders({
  content,
  values,
}: ReplacePlaceholdersArgs): string {
  const preservedContent = preserveWhitespace(content);
  const parser = new DOMParser();
  const doc = parser.parseFromString(preservedContent, "text/html");
  const placeholderElements = doc.querySelectorAll(
    `[${WHITELISTED_ATTRIBUTES.placeholder}]`,
  );

  placeholderElements.forEach((element) => {
    const attribute =
      element.getAttribute(WHITELISTED_ATTRIBUTES.placeholder) ?? EMPTY_STRING;
    const key = attribute?.match(/{{(.*?)}}/)?.[1]?.trim();
    if (key != null) {
      if (key in values && typeof values[key] === "string") {
        // eslint-disable-next-line immutable/no-mutation, no-param-reassign
        element.textContent = values[key] ?? DEFAULT_PLACEHOLDER;
      } else if (key in values.altPackages) {
        // eslint-disable-next-line immutable/no-mutation, no-param-reassign
        element.textContent = values.altPackages[key] ?? DEFAULT_PLACEHOLDER;
      } else {
        // eslint-disable-next-line immutable/no-mutation, no-param-reassign
        element.textContent = DEFAULT_PLACEHOLDER;
      }
    }
  });

  return doc.body.innerHTML;
}

interface GetEntriesReturns {
  [key: string]: string;
}

export function getEntries(content: string): GetEntriesReturns {
  const preservedContent = preserveWhitespace(content);
  const parser = new DOMParser();
  const doc = parser.parseFromString(preservedContent, "text/html");
  const entryElementNodes = doc.querySelectorAll(
    `[${WHITELISTED_ATTRIBUTES.entry}]`,
  );

  const entryElements = Array.from(entryElementNodes);
  const entries = entryElements
    .map((element) => {
      const attribute =
        element.getAttribute(WHITELISTED_ATTRIBUTES.entry) ?? EMPTY_STRING;

      const key = attribute?.match(/{{(.*?)}}/)?.[1]?.trim();
      if (key != null) {
        return {
          [key]: element.textContent ?? EMPTY_STRING,
        };
      }
    })
    .filter((entry) => entry != null);

  return entries.reduce((acc, entry) => {
    return { ...acc, ...entry };
  }, {});
}

export function removePlaceholderStyles(content: string): string {
  const preservedContent = preserveWhitespace(content);
  const parser = new DOMParser();
  const doc = parser.parseFromString(preservedContent, "text/html");
  const placeholderElementNodes = doc.querySelectorAll(
    `[${WHITELISTED_ATTRIBUTES.placeholder}]`,
  );

  const placeholderElements = Array.from(placeholderElementNodes);
  placeholderElements.forEach((element) => {
    element.removeAttribute("style");
  });

  return doc.body.innerHTML;
}
