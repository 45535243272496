import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

interface Props {
  className: string;
  text: string;
  addInlineItem: () => void;
}

const NewLaborItemButton = ({ className, text, addInlineItem }: Props) => {
  return (
    <Button
      className={className}
      leftSection={<IconPlus size="16px" />}
      onClick={addInlineItem}
      variant="transparent"
    >
      {text}
    </Button>
  );
};

export default NewLaborItemButton;
