import { useContextMenu } from "mantine-contextmenu";
import { DataTable, DataTableRowClickHandler } from "mantine-datatable";
import React from "react";
import { useHoveredLineItem } from "src/context/HoveredLineItemProvider";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { CONTEXT_MENU_STYLES } from "../../constants";
import { useEstimationColumnLabor } from "../../hooks/useEstimationColumnLabor";
import { useInlineLaborContextMenu } from "../../hooks/useInlineLaborContextMenu";
import styles from "./InlineLaborItems.module.scss";
import NewLaborItemButton from "./NewLaborItemButton";

interface Props {
  grandParent?: BundleLineItem;
  laborItems: BundleLineItem[];
  parent: BundleLineItem;
  isEditable?: boolean;
  handleUpdate: (bundleId: number, lineItem: LineItem) => void;
}

const InlineLaborItems = React.memo<Props>(function _InlineLaborItems({
  laborItems,
  parent,
  grandParent,
  isEditable = true,
  handleUpdate,
}) {
  const { selectedItems } = useSelectedLineItems();
  const { showContextMenu } = useContextMenu();
  const columns = useEstimationColumnLabor(parent, isEditable, handleUpdate);
  const createLineItemsContextMenu = useInlineLaborContextMenu(handleUpdate);
  const { hoveredId } = useHoveredLineItem();

  const handleRowContextMenu: DataTableRowClickHandler<BundleLineItem> =
    React.useCallback(
      ({ record, event }) => {
        if (isEditable === true) {
          const contextMenu = createLineItemsContextMenu(record);

          showContextMenu(contextMenu, CONTEXT_MENU_STYLES)(event);
        }
      },
      [createLineItemsContextMenu, isEditable, showContextMenu],
    );

  const addInlineItem = React.useCallback(() => {
    handleUpdate(parent.bundle_id, {
      id: undefined,
      parent_id: parent.id,
      package_id: parent.package_id,
      cost_type: "labor",
      quantity_unit: "hours_per_unit",
      display_attributes: {
        display: "inline",
      },
    });
  }, [handleUpdate, parent.bundle_id, parent.id, parent.package_id]);

  const showAddButton = React.useMemo(() => {
    return laborItems.length > 1 && isEditable === true;
  }, [isEditable, laborItems.length]);

  if (laborItems.length === 0) {
    if (parent.id === hoveredId && isEditable === true) {
      return (
        <NewLaborItemButton
          addInlineItem={addInlineItem}
          className={styles.newLaborItem}
          text="Add labor"
        />
      );
    }
    return null;
  }

  return (
    <>
      <DataTable
        borderColor="var(--mantine-color-gray-2)"
        columns={columns}
        defaultColumnProps={{
          cellsStyle: () => {
            if (laborItems.length === 1) {
              return () => ({
                borderBottom: "none",
              });
            }
          },
        }}
        highlightOnHoverColor={"#00a69c0d"}
        noHeader={true}
        noRecordsIcon={<React.Fragment />}
        onRowContextMenu={handleRowContextMenu}
        records={laborItems}
        styles={{
          root: {
            background: "transparent",
          },
          table: {
            background:
              selectedItems.findIndex((item) => item.id === grandParent?.id) >
              -1
                ? "#ebf6f6"
                : "inherit",
          },
        }}
        withColumnBorders={true}
      />
      {showAddButton === true ? (
        <NewLaborItemButton
          addInlineItem={addInlineItem}
          className={styles.newItem}
          text="Add labor"
        />
      ) : null}
    </>
  );
});

export default InlineLaborItems;
