/**
 * use this hook to set application level(distribution on BE) for margin updates and summary
 * or anywhere else where cost_type, trade are being used
 */

import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useQueryStringParams = (
  key: string,
): [string | null, (v: string | null) => void] => {
  const [params, setSearchParams] = useSearchParams();
  const distribution = params.get(key);

  const setDistribution = useCallback(
    (value: string | null) => {
      if (value != null) {
        params.set(key, value);
        setSearchParams(params);
      }
    },
    [key, params, setSearchParams],
  );

  return useMemo(() => {
    return [distribution, setDistribution];
  }, [distribution, setDistribution]);
};
