import { LabelValue } from "src/types/util/labelValue";
import { ValuesUnion } from "src/types/valuesUnion";

export const EstimationResourceType = {
  Bundle: "Bundle",
  LineItem: "LineItem",
  Package: "Package",
} as const;
export type EstimationResourceType = ValuesUnion<typeof EstimationResourceType>;

export const LaborTypeTime = {
  DoubleTime: "double",
  OverTime: "over",
  StraightTime: "straight",
} as const;
export type LaborTypeTime = ValuesUnion<typeof LaborTypeTime>;

export const CrewMixMethodType = {
  Percent: "Percent",
  Person: "Person",
} as const;
export type CrewMixMethodType = ValuesUnion<typeof CrewMixMethodType>;

export const HoursMethodType = {
  Percent: "Percent",
  Hours: "Hours",
} as const;
export type HoursMethodType = ValuesUnion<typeof HoursMethodType>;

export const CREW_MIX_METHOD_LABELS: ReadonlyArray<LabelValue> = [
  {
    label: "By %",
    value: CrewMixMethodType.Percent,
  },
  {
    label: "By person",
    value: CrewMixMethodType.Person,
  },
];

export const HOURS_METHOD_LABELS: ReadonlyArray<LabelValue> = [
  {
    label: "By %",
    value: HoursMethodType.Percent,
  },
  {
    label: "By hours",
    value: HoursMethodType.Hours,
  },
];
