import { Paper, Select, Stack, UnstyledButton } from "@mantine/core";
import { IconSearch, IconTrash } from "@tabler/icons-react";
import React, { useMemo } from "react";
import { Control, useController } from "react-hook-form";
import { api } from "src/data/api/api";
import { CalculatedByType } from "src/data/api/types/shared/bid";
import { asMutableArray } from "src/utils/asMutableArray";
import { REQUIRED_RULES } from "../Forms/constants";
import { NewBid } from "../Forms/types/bid";
import { DEFAULT_SELECT_PLACEHOLDER, LOADING_PLACEHOLDER } from "./constants";
import styles from "./EstimationField.module.scss";
import { EstimationTotalBaseSalePrice } from "./EstimationTotalBaseSalePrice";
import { JobTypesMultiSelectField } from "./JobTypesMultiSelectField";
import { UserField } from "./UserField";

interface Props {
  estimationId?: string;
  calculatedBy?: CalculatedByType | null;
  readonly control: Control<NewBid["estimation"]>;
  readonly index: number;
  readonly onRemove?: (index: number) => void;
  readonly shouldRenderSellAmountField?: boolean;
}

export const EstimationField = React.memo<Props>(function _EstimationField({
  control,
  index,
  onRemove,
  estimationId,
  calculatedBy,
}) {
  const { currentData, isLoading } = api.endpoints.getBidOptions.useQuery();

  const salesLead = useController({
    control,
    name: `estimations.${index}.salesLead`,
    rules: REQUIRED_RULES,
  });

  const jobTypes = useController({
    control,
    name: `estimations.${index}.jobTypes`,
    rules: REQUIRED_RULES,
  });

  const status = useController({
    control,
    name: `estimations.${index}.status`,
    rules: REQUIRED_RULES,
  });

  const showTotalSellAmount = useMemo(() => {
    if (calculatedBy == null || calculatedBy !== CalculatedByType.System)
      return true;
    return ["submitted", "lost"].includes(status.field.value);
  }, [calculatedBy, status.field.value]);

  const handleSelfRemove = React.useCallback(() => {
    onRemove?.(index);
  }, [index, onRemove]);

  const selectPlaceholder = isLoading
    ? LOADING_PLACEHOLDER
    : DEFAULT_SELECT_PLACEHOLDER;

  return (
    <Paper
      className={styles.container}
      component={Stack}
      gap={0}
      p="md"
      shadow="xs"
      withBorder={true}
    >
      {onRemove != null && (
        <div>
          <UnstyledButton className={styles.button} onClick={handleSelfRemove}>
            <IconTrash size={16} />
          </UnstyledButton>
        </div>
      )}

      <UserField
        error={salesLead.fieldState.error?.message}
        label="Salesperson"
        leftSection={<IconSearch size={16} />}
        onChange={salesLead.field.onChange}
        required={true}
        value={salesLead.field.value}
      />
      <JobTypesMultiSelectField
        description="(select all)"
        error={jobTypes.fieldState.error?.message}
        label="Job type"
        leftSection={<IconSearch size={16} />}
        onChange={jobTypes.field.onChange}
        placeholder={selectPlaceholder}
        required={true}
        value={asMutableArray(jobTypes.field.value)}
      />
      <Select
        clearable={true}
        data={currentData?.status}
        disabled={currentData == null}
        error={status.fieldState.error?.message}
        label="Status"
        leftSection={<IconSearch size={16} />}
        onChange={status.field.onChange}
        placeholder={selectPlaceholder}
        required={true}
        value={status.field.value}
        withScrollArea={false}
      />
      {showTotalSellAmount && estimationId && (
        <EstimationTotalBaseSalePrice
          calculatedBy={calculatedBy}
          control={control}
          estimationId={estimationId}
          index={index}
          status={status.field.value}
        />
      )}
    </Paper>
  );
});
