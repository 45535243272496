import { ActionIcon, Flex, Group, Skeleton, Stack } from "@mantine/core";
import { useElementSize, useViewportSize } from "@mantine/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import { IconChevronLeft, IconFileDollar } from "@tabler/icons-react";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConsumerSingleton } from "src/cable/consumer";
import { subscriptions } from "src/cable/subscriptions";
import { PageHeader } from "src/components/Frames/PageHeader";
import { MatchParam } from "src/constants/matches";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { selectCurrentCompanyUuid } from "src/data/selectors/auth";
import { useAppSelector } from "src/data/store";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { AssemblyIcon } from "src/icons/AssemblyIcon";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { LoadingScreen } from "../Frames/LoadingScreen";
import Assemblies from "./Assemblies";
import styles from "./EstimationView.module.scss";
import { SPLIT_OPTIONS } from "./Proposal/constants";
import { ProposalView } from "./Proposal/ProposalView";
import { SplitScreenToggle } from "./SplitScreenToggle";
import { EstimationTable } from "./Table/EstimationTable";

const NAV_BAR_WIDTH = 80;

export const EstimationView = React.memo(function _EstimationView() {
  const { ref: refRoot, width: rootWidth } = useElementSize();
  const { height } = useViewportSize();
  const { ref: refHeader, height: headerHeight } = useElementSize();
  const { ref: refSplitToggleLeft, width: splitToggleWidthLeft } =
    useElementSize();
  const { ref: refSplitToggleRight, width: splitToggleWidthRight } =
    useElementSize();
  const companyUuid = useAppSelector(selectCurrentCompanyUuid);

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [split] = useQueryStringParams("split");

  const handleOpen = React.useCallback(() => {
    setOpen((o) => !o);
  }, []);

  const { bidId } = useParams<MatchParam<"BID_ID">>();
  const { currentData, isLoading, isFetching } = api.endpoints.getBid.useQuery(
    bidId ?? skipToken,
  );

  const bid = React.useMemo(() => {
    if (currentData != null) {
      return currentData.bid.data;
    }
  }, [currentData]);

  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();

  const handleBidClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.BIDS + Paths.SLASH + bidId);
  }, [bidId, navigate]);

  const [tableWidth, proposalWidth] = React.useMemo(() => {
    let proposalPercentage: number | undefined = undefined;
    switch (split) {
      case SPLIT_OPTIONS.mid:
      default:
        proposalPercentage =
          0.5 * (rootWidth === 0 ? 1 : 1 - NAV_BAR_WIDTH / rootWidth);
        break;
      case SPLIT_OPTIONS.left:
        proposalPercentage = 0;
        break;
      case SPLIT_OPTIONS.right:
        proposalPercentage = 1;
        break;
    }

    const table =
      rootWidth * (1 - proposalPercentage) -
      (open ? 350 : 0) -
      (split === SPLIT_OPTIONS.left ? splitToggleWidthLeft : 0);

    const proposal = rootWidth * proposalPercentage;
    return [table, proposal];
  }, [open, split, splitToggleWidthLeft, rootWidth]);

  useEffect(() => {
    console.log(
      `-------------------BID_LOADING-${isLoading}-EST: ${estimationId}}---------------------------`,
    );
  }, [isLoading, estimationId]);

  useEffect(() => {
    console.log(
      `-------------------BID_FETCHING-${isFetching}-EST: ${estimationId}}---------------------------`,
    );
  }, [isFetching, estimationId]);

  useEffect(() => {
    console.log(`-----------------INSIDE-EFFECT------------------`);
    return () => {
      console.log(
        `--------------------CALLING-CLEANUP-FUNCTION----------------------`,
      );
      if (companyUuid != null) {
        const subscription = subscriptions.get(companyUuid);

        if (subscription != null) {
          console.log(
            `---------------------UNSUBSCRIBING----------------------`,
          );
          subscription.unsubscribe();
          subscriptions.delete(companyUuid);
        }

        console.log(
          `---------------------RESETING-CONSUMER----------------------`,
        );

        ConsumerSingleton.resetConsumer();
      }
    };
  }, [companyUuid]);

  return (
    <Flex ref={refRoot} className={styles.root} direction="row" w="100%">
      <Stack className={styles.stack}>
        <Group ref={refHeader} className={styles.headerSection}>
          <PageHeader
            title={
              bid == null ? (
                <Skeleton
                  className={styles.darkLoader}
                  height={35}
                  width={200}
                />
              ) : (
                <Group
                  className={classNames(
                    styles.estimationTitle,
                    styles.headerFonts,
                  )}
                >
                  <ActionIcon onClick={handleBidClick} variant="transparent">
                    <IconChevronLeft color="white" size={26} />
                  </ActionIcon>
                  <IconFileDollar size={26} />
                  Estimation for {bid.attributes.name}
                </Group>
              )
            }
          />
          {split === SPLIT_OPTIONS.right ? null : (
            <Group align="center" justify="center" pr="lg">
              <ActionIcon onClick={handleOpen} size="sm" variant="transparent">
                <AssemblyIcon color="white" />
              </ActionIcon>
            </Group>
          )}
        </Group>

        {bidId == null || estimationId == null || rootWidth === 0 ? (
          <div className={styles.loadingScreen}>
            <LoadingScreen loadingScreenType={LoadingScreenType.Table} />
          </div>
        ) : (
          <Group className={styles.group} gap={0}>
            <Group
              className={classNames(
                split === SPLIT_OPTIONS.left ? styles.noProposal : null,
                styles.proposalWrapper,
              )}
              style={{
                width: `${proposalWidth}px`,
              }}
            >
              <ProposalView
                bid={bid}
                className={styles.proposal}
                componentHeight={height - headerHeight}
                componentWidth={
                  proposalWidth -
                  (split === SPLIT_OPTIONS.right ? splitToggleWidthRight : 0)
                }
              />
              {split === SPLIT_OPTIONS.right ? (
                <SplitScreenToggle ref={refSplitToggleRight} />
              ) : null}
            </Group>
            {split === SPLIT_OPTIONS.mid ? <SplitScreenToggle /> : null}
            <Group
              className={classNames(
                split === SPLIT_OPTIONS.right
                  ? styles.noTable
                  : split === SPLIT_OPTIONS.mid
                    ? styles.estimationBorder
                    : null,
                styles.tableWrapper,
              )}
            >
              {split === SPLIT_OPTIONS.left ? (
                <SplitScreenToggle ref={refSplitToggleLeft} />
              ) : null}
              <div
                style={{
                  width: `${tableWidth}px`,
                  height: "100%",
                  zIndex: "2",
                }}
              >
                <EstimationTable
                  className={styles.table}
                  estimationId={estimationId}
                />
              </div>
            </Group>

            {open ? (
              <Assemblies
                height={height - headerHeight}
                onClose={handleOpen}
                open={open}
              />
            ) : null}
          </Group>
        )}
      </Stack>
    </Flex>
  );
});
