import {
  IconLogout,
  IconPaperclip,
  IconTrash,
  IconUsers,
} from "@tabler/icons-react";
import React from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { EMPTY_STRING } from "src/utils/empty";
import { CONTEXT_MENU_ICON_SIZE } from "../constants";
import { useAttachments } from "./useAttachments";
import { useLineItemCrewMix } from "./useLineItemCrewMix";

export const useChildItemContextMenu = (
  updateBundle: (bundleId: number, lineItem: LineItem) => void,
) => {
  const openManageAttachments = useAttachments();
  const setLineItemCrewMix = useLineItemCrewMix();
  const [isDev] = useQueryStringParams("dev");

  const deleteLineItem = React.useCallback(
    (record: BundleLineItem) => {
      updateBundle(record.bundle_id, {
        id: record.id,
        _destroy: true,
      });
    },
    [updateBundle],
  );

  return React.useCallback(
    (record: BundleLineItem) => {
      const isLaborClicked = record.cost_type === "labor";
      return [
        {
          key: "manage_attachments",
          icon: <IconPaperclip size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Manage attachments",
          color: "var(--mantine-color-dark-6)",
          hidden: !isDev,
          onClick: () => openManageAttachments(record),
        },
        { key: "divider01", hidden: !isDev },
        {
          key: "specify_crew_and_hour_type",
          icon: <IconUsers size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Specify crew and hour type",
          color: "var(--mantine-color-dark-6)",
          hidden: !isDev || !isLaborClicked,
          disabled: record.trade === EMPTY_STRING,
          onClick: () => setLineItemCrewMix(record),
        },
        { key: "divider02", hidden: !isDev || !isLaborClicked },
        {
          key: "move_to",
          icon: <IconLogout size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Move to",
          disabled: true,
          onClick: () => {},
        },
        { key: "divider03" },
        {
          key: "delete",
          icon: <IconTrash size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Delete",
          color: "red",
          onClick: () => deleteLineItem(record),
        },
      ];
    },
    [deleteLineItem, isDev, openManageAttachments, setLineItemCrewMix],
  );
};
