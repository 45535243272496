import { Flex, Group, Loader, Stack, Text } from "@mantine/core";
import { useCallback, useMemo } from "react";
import AssemblyScope from "src/components/EstimationView/Assemblies/components/AssemblyScope";
import AssemblyTitle from "src/components/EstimationView/Assemblies/components/AssemblyTitle";
import UnitOfMeasure from "src/components/EstimationView/Assemblies/components/UnitOfMeasure";
import AssemblyBundle from "src/components/EstimationView/Table/components/AssemblyBundle";
import StickyTableHeaders from "src/components/EstimationView/Table/components/StickyTableHeaders";
import { useIsEditable } from "src/context/EditableProvider";
import ExpandableLineItemsProvider from "src/context/ExpandableLineItemsProvider";
import HoveredLineItemProvider from "src/context/HoveredLineItemProvider";
import ScopeProvider from "src/context/ScopeProvider";
import {
  useGetAssemblyQuery,
  useUpdateAssemblyMutation,
} from "src/data/api/api";
import { Bundle } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";

interface Props {
  assemblyId: number;
}

const AssemblyEditForm = ({ assemblyId }: Props) => {
  const { isEditable } = useIsEditable();
  const { currentData } = useGetAssemblyQuery(assemblyId);
  const [updateAssembly] = useUpdateAssemblyMutation();

  const assembly = useMemo(() => {
    if (currentData == null) {
      return null;
    }

    return currentData.assembly.data;
  }, [currentData]);

  const handleChange = useCallback(
    (bundle: Bundle) => {
      updateAssembly({
        bundle_id: assemblyId,
        line_item: bundle,
      });
    },
    [assemblyId, updateAssembly],
  );

  if (assembly == null) {
    return <Loader />;
  }

  return (
    <Flex component="form" direction="column" gap="8px">
      <Stack>
        <Group grow>
          <AssemblyTitle
            defaultValue={assembly.attributes.title}
            disabled={!isEditable}
            onChange={handleChange}
          />
          <UnitOfMeasure
            defaultQuantity={assembly.attributes.unit_of_measure?.split("/")[0]}
            defaultUnit={assembly.attributes.unit_of_measure?.split("/")[1]}
            disabled={!isEditable}
            onChange={handleChange}
          />
        </Group>
        <AssemblyScope
          defaultValue={assembly.attributes.scope ?? EMPTY_STRING}
          disabled={!isEditable}
          onChange={handleChange}
        />
      </Stack>

      <Text fw={500} fz="sm">
        Items
      </Text>
      <div
        style={{
          width: "100%",
          height: "400px",
          overflowX: "auto",
          background: "#f9fafb",
          padding: "2px",
        }}
      >
        <Stack gap="sm" style={{ width: "1503px" }}>
          <StickyTableHeaders isSelectable={false} />

          <ExpandableLineItemsProvider initialGroups={[]} initialItems={[]}>
            <ScopeProvider
              target={{
                id: assembly.attributes.id,
                type: "Bundle",
              }}
            >
              <HoveredLineItemProvider>
                <AssemblyBundle
                  isEditable={isEditable}
                  lineItems={assembly.attributes.line_items}
                />
              </HoveredLineItemProvider>
            </ScopeProvider>
          </ExpandableLineItemsProvider>
        </Stack>
      </div>
    </Flex>
  );
};

export default AssemblyEditForm;
