import { Text } from "@mantine/core";
import classNames from "classnames";
import { DataTable, DataTableColumnTextAlign } from "mantine-datatable";
import React from "react";
import {
  DEFAULT_PLACEHOLDER,
  PRICE_METHOD,
} from "src/components/EstimationView/Table/constants";
import { CostCell } from "src/components/Frames/Table/CostCell";
import { MarginCell } from "src/components/Frames/Table/MarginCell";
import { useGetCostTypeTotalsByTradeQuery } from "src/data/api/api";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { formatCurrency } from "src/utils/formatCurrency";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import styles from "./TradesInnerTable.module.scss";

interface Props {
  record: PackageTotal;
  packageId: string;
}

const TradesInnerTable = React.memo<Props>(function _TradesInnerTable({
  record,
  packageId,
}) {
  const [priceMethod] = useQueryStringParams(PRICE_METHOD);
  const WIDTH = "13%";

  const { data, isLoading } = useGetCostTypeTotalsByTradeQuery({
    packageId,
    trade: record.name,
  });

  const costTypes = React.useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.cost_types_summary
      .map((costType) => {
        const newCostType = [
          {
            display_name: costType.display_name,
            name: costType.name,
            rate: costType.rate,
            apply_margin: costType.apply_margin,
            grand_total: formatNumberHundredths(
              Number(costType.subtotal) + Number(costType.margin),
            ).toString(),
            margin: costType.margin,
            subtotal: costType.subtotal,
            taxes: costType.taxes,
            formula: costType.formula,
            total_hours: costType.total_hours,
            total_labor_cost: "",
            other_expenses: costType.other_expenses,
          },
        ];

        if (Number(costType.taxes) > 0) {
          newCostType.push({
            display_name: `Sales tax(${costType.name})`,
            name: `sales tax(${costType.name})`,
            rate: costType.rate,
            apply_margin: false,
            grand_total: costType.taxes,
            margin: "0",
            subtotal: costType.taxes,
            taxes: costType.taxes,
            formula: costType.formula,
            total_hours: costType.total_hours,
            total_labor_cost: "",
            other_expenses: "",
          });
        }

        return newCostType;
      })
      .flat();
  }, [data]);

  const tradesColumns = React.useMemo(() => {
    return [
      {
        width: "22%",
        accessor: "trade",
        title: "TRADE",
        render: (v: PackageTotal) => {
          return (
            <Text className={classNames(styles.content, styles.indent)}>
              {v.display_name}
            </Text>
          );
        },
        textAlign: "left" as DataTableColumnTextAlign,
      },
      {
        width: WIDTH,
        accessor: "percent_of_cost",
        title: "% OF COST",
      },
      {
        width: WIDTH,
        accessor: "rate",
        title: "RATE",
        render: (v: PackageTotal) => (
          <Text
            className={classNames(
              styles.content,
              v.name === "labor" ? null : styles.placeholder,
            )}
          >
            {v.name === "labor" ? formatCurrency(v.rate) : DEFAULT_PLACEHOLDER}
          </Text>
        ),
      },
      {
        width: WIDTH,
        accessor: "labor_hrs",
        title: "LABOR HRS",
        render: (v: PackageTotal) => (
          <Text
            className={classNames(
              styles.content,
              v.name === "labor" ? null : styles.placeholder,
            )}
          >
            {v.name === "labor" ? `${v.total_hours} hrs` : DEFAULT_PLACEHOLDER}
          </Text>
        ),
      },
      {
        width: WIDTH,
        accessor: "cost",
        title: "COST",
        render: (v: PackageTotal) => <CostCell record={v} withTaxes={false} />,
      },
      {
        width: WIDTH,
        accessor: "margin",
        title: priceMethod === "markup" ? "MARKUP" : "MARGIN",
        render: (v: PackageTotal) => <MarginCell record={v} />,
      },
      {
        width: WIDTH,
        accessor: "sell_price",
        title: "SELL PRICE",
        render: (v: PackageTotal) => (
          <Text className={styles.content}>
            {formatCurrency(Number(v.grand_total))}
          </Text>
        ),
      },
    ];
  }, [priceMethod]);

  return (
    <DataTable
      backgroundColor="var(--mantine-color-gray-0)"
      columns={tradesColumns}
      defaultColumnProps={{
        textAlign: "right",
      }}
      fetching={isLoading}
      height="100%"
      idAccessor={"name"}
      minHeight="33px"
      noHeader
      noRecordsIcon={<div />}
      noRecordsText="No records to show"
      records={costTypes}
    />
  );
});

export default TradesInnerTable;
