import {
  CheckIcon,
  CloseButton,
  Combobox,
  Group,
  Input,
  InputWrapperProps,
  TextInput,
  TextInputProps,
  useCombobox,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import React from "react";
import { PREVENT_DEFAULT } from "src/constants/preventDefault";
import { api } from "src/data/api/api";
import {
  CrewMixDefault,
  GetLaborSourceOptionsReturns,
} from "src/data/api/types/getLaborSourceOptions";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";
import styles from "./UnionSelectField.module.scss";
import {
  CREW_MIX_ENTRY_KEYS,
  ResetEntryArgs,
  SetCrewMixEntryArgs,
} from "./useCrewMixData";

const UNION_INPUT_STYLES: TextInputProps["styles"] = {
  input: {
    fontSize: "var(--mantine-font-size-xs)",
  },
};

interface Props extends Omit<InputWrapperProps, "onChange"> {
  readonly crewMixDefault?: CrewMixDefault;
  readonly disabled?: boolean;
  readonly laborSourceId: string;
  readonly resetCrewMixEntry: (arg: ResetEntryArgs) => void;
  readonly setCrewMixEntry: (arg: SetCrewMixEntryArgs) => void;
  readonly trade: keyof GetLaborSourceOptionsReturns["collection"];
}

export const UnionSelectField = React.memo<Props>(function _UnionSelectField({
  disabled = false,
  laborSourceId,
  resetCrewMixEntry,
  setCrewMixEntry,
  trade,
  ...inputWrapperProps
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [search, setSearch] = React.useState(EMPTY_STRING);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);

  const laborSources =
    api.endpoints.getLaborSources.useQuery().currentData?.collection.data;

  const initialLabel = React.useMemo(() => {
    return (
      laborSources?.find(
        (laborSourceEntry) => laborSourceEntry.id === laborSourceId,
      )?.attributes.name ?? EMPTY_STRING
    );
  }, [laborSourceId, laborSources]);

  React.useEffect(() => {
    if (initialLabel !== EMPTY_STRING) {
      setSearch(initialLabel);
    }
  }, [initialLabel]);

  const options = React.useMemo(() => {
    return (
      laborSources
        ?.map((laborSourceEntry) => {
          return {
            label: laborSourceEntry.attributes.name,
            value: laborSourceEntry.id,
          };
        })
        .filter((laborSourceOption) => laborSourceOption != null) ?? EMPTY_ARRAY
    );
  }, [laborSources]);

  const selectedOption = React.useMemo(() => {
    return (
      options.find((option) => option.label === search)?.label ?? initialLabel
    );
  }, [initialLabel, options, search]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const filteredOptions = React.useMemo(() => {
    return options?.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase().trim()),
    );
  }, [options, search]);

  const handleDropdownOpen = React.useCallback(() => {
    combobox.openDropdown();
    setIsEdit(true);
  }, [combobox]);

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
      combobox.openDropdown();
      combobox.updateSelectedOptionIndex();
    },
    [combobox],
  );

  const handleOptionSubmit = React.useCallback(
    (submittedValue: string) => {
      combobox.closeDropdown();
      inputRef.current?.blur();
      if (submittedValue === initialLabel) {
        return;
      }

      setSearch(submittedValue);
      const selectedLaborSourceId =
        options.find((option) => option.label === submittedValue)?.value ??
        EMPTY_STRING;

      resetCrewMixEntry({ trade, laborSourceId: selectedLaborSourceId });
      setCrewMixEntry({
        key: CREW_MIX_ENTRY_KEYS.laborSourceId,
        trade,
        value: selectedLaborSourceId,
      });
    },
    [
      combobox,
      initialLabel,
      options,
      resetCrewMixEntry,
      setCrewMixEntry,
      trade,
    ],
  );

  const handleSearchBlur = React.useCallback(() => {
    combobox.closeDropdown();
    setIsEdit(false);
    setSearch(selectedOption);
    if (selectedOption !== initialLabel) {
      handleOptionSubmit(selectedOption);
    }
  }, [combobox, handleOptionSubmit, initialLabel, selectedOption]);

  const handleClear = React.useCallback(() => {
    setSearch(EMPTY_STRING);
    combobox.updateSelectedOptionIndex();
  }, [combobox]);

  React.useEffect(() => {
    // wait for options to render before we can select first one
    combobox.selectFirstOption();
  }, [combobox]);

  return (
    <Input.Wrapper {...inputWrapperProps} className={styles.textField}>
      <Combobox
        floatingStrategy="fixed"
        middlewares={{ shift: false }}
        onOptionSubmit={handleOptionSubmit}
        store={combobox}
      >
        <Combobox.Target>
          <TextInput
            ref={inputRef}
            className={styles.input}
            disabled={disabled}
            onBlur={handleSearchBlur}
            onChange={handleSearchChange}
            onFocus={handleDropdownOpen}
            rightSection={
              isEdit ? (
                <CloseButton
                  aria-label="Clear value"
                  onClick={handleClear}
                  // prevent losing focus when clicking clear button
                  onMouseDown={PREVENT_DEFAULT}
                  size="sm"
                />
              ) : (
                <IconChevronDown onMouseDown={PREVENT_DEFAULT} size={14} />
              )
            }
            rightSectionPointerEvents={isEdit ? "all" : "none"}
            styles={UNION_INPUT_STYLES}
            value={search}
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((item) => (
                <Combobox.Option key={item.label} value={item.label}>
                  <Group gap="xs">
                    {item.label === search ? (
                      <CheckIcon size={12} />
                    ) : undefined}
                    {item.label}
                    {/* TODO: add template literal and append item.description once BE has it */}
                  </Group>
                </Combobox.Option>
              ))
            ) : (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Input.Wrapper>
  );
});
