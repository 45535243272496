import { ActionIcon } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import styles from "./ExpandIcon.module.scss";

interface Props {
  isExpanded: boolean;
  onClick?: () => void;
}

export const ExpandIcon = React.memo<Props>(function _ExpandIcon({
  isExpanded,
  onClick,
}) {
  const handleExpandable = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick != null) {
        event.stopPropagation();
      }

      onClick?.();
    },
    [onClick],
  );

  return (
    <ActionIcon onClick={handleExpandable} variant="transparent">
      <IconChevronRight
        className={classNames(styles.icon, styles.expandIcon, {
          [styles.expandIconRotated]: isExpanded,
        })}
        size={18}
      />
    </ActionIcon>
  );
});
