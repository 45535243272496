import { SegmentedControl, Text } from "@mantine/core";
import React from "react";
import { DISTRIBUTION_PARAM } from "src/components/EstimationView/Table/constants";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { EMPTY_STRING } from "src/utils/empty";

const Distributions = React.memo(function _Distributions() {
  const [distribution, setDistribution] =
    useQueryStringParams(DISTRIBUTION_PARAM);

  return (
    <>
      <Text fw={700} fz={14}>
        View by:
      </Text>
      <SegmentedControl
        data={[
          { label: "Cost type", value: "cost_type" },
          { label: "Trade", value: "trade" },
          { label: "Group", value: "group" },
        ]}
        onChange={setDistribution}
        value={distribution ?? EMPTY_STRING}
        w={400}
      />
    </>
  );
});

export default Distributions;
