import { NumberInput, NumberInputProps } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import classNames from "classnames";
import React from "react";
import { EMPTY_STRING } from "src/utils/empty";
import { formatNumber } from "src/utils/formatNumber";
import { DEFAULT_PLACEHOLDER } from "../Table/constants";
import { useCellKeystrokes } from "../Table/hooks/useCellKeystrokes";
import { UNITS } from "./constants";
import styles from "./LaborRateCell.module.scss";
import { ClassificationEntry, TIME_TYPE_KEYS } from "./useCrewMixData";
import { LaborTypeTime } from "./util/utils";
import { formatCrewMixHours } from "src/utils/formatCrewMixHours";
import { formatCrewMixPercentage } from "src/utils/formatCrewMixPercentage";

const CREW_MIX_CELL_INPUT_STYLES: NumberInputProps["styles"] = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "4px",
  },
  input: {
    height: "min-content",
    minHeight: "min-content",
    padding: "0",
    lineHeight: "1.5",
    textAlign: "right",
    fontSize: "var(--mantine-font-size-xs)",
  },
  section: {
    position: "relative",
    top: "0",
    width: "max-content",
    fontSize: "11px",
  },
};

const DISABLED_CELL_INPUT_STYLES: NumberInputProps["styles"] = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "4px",
  },
  input: {
    height: "min-content",
    minHeight: "min-content",
    padding: "0",
    lineHeight: "1.5",
    textAlign: "right",
    fontSize: "var(--mantine-font-size-xs)",
    opacity: "1",
    backgroundColor: "var(--mantine-color-gray-0)",
    color: "var(--mantine-color-dimmed)",
  },
  section: {
    position: "relative",
    top: "0",
    width: "max-content",
    fontSize: "11px",
  },
};

interface Props {
  readonly data?: ClassificationEntry;
  readonly disabled?: boolean;
  readonly isByHoursMethod: boolean;
  readonly onSubmit: (value: number) => void;
  readonly timeType: LaborTypeTime;
}

export const LaborRateCell = React.memo<Props>(function _LaborRateCell({
  data,
  disabled = false,
  isByHoursMethod,
  onSubmit,
  timeType,
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [cellValue, setCellValue] = useInputState<number | string>(
    EMPTY_STRING,
  );

  React.useEffect(() => {
    if (data != null && !isEdit) {
      let value: number | undefined = undefined;
      const laborRatePercentage = data[
        TIME_TYPE_KEYS[timeType].percentage
      ] as number;

      if (isByHoursMethod) {
        value =
          formatCrewMixHours(laborRatePercentage * data.allottedHours);
      } else {
        value = formatCrewMixPercentage(laborRatePercentage * 100);
      }
      setCellValue(value === 0 ? EMPTY_STRING : value);
    }
  }, [data, isByHoursMethod, isEdit, setCellValue, timeType]);

  const handleFocus = React.useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setIsEdit(false);
    const numberValue = typeof cellValue === "string" ? 0 : cellValue;
    const globalValue = data?.[TIME_TYPE_KEYS[timeType].percentage] as number;
    if (
      (isByHoursMethod &&
        (formatNumber(numberValue / (data?.allottedHours ?? 0), 0, 2) ?? 0) !==
          globalValue) ||
      (!isByHoursMethod &&
        (formatNumber(numberValue / 100, 0, 4) ?? 0) !== globalValue)
    ) {
      onSubmit(numberValue);
    }
  }, [cellValue, data, isByHoursMethod, onSubmit, timeType]);

  const { handleEnterBlur: handleKeyPresses } = useCellKeystrokes({
    ref: inputRef,
  });

  const rightSection = React.useMemo(() => {
    if (!isByHoursMethod && data != null) {
      const numberValue = typeof cellValue === "string" ? 0 : cellValue;
      const hours =
        formatCrewMixHours((numberValue / 100) * data.allottedHours);
      const hoursDisplay = hours === 0 ? DEFAULT_PLACEHOLDER : hours;
      return `${hoursDisplay} ${hours === 1 ? UNITS.hour : UNITS.hours}`;
    }

    return undefined;
  }, [cellValue, data, isByHoursMethod]);

  return (
    <div className={classNames(styles.root, isEdit ? styles.focus : undefined)}>
      {/* TODO: add focus styles to outer div */}
      <NumberInput
        ref={inputRef}
        allowDecimal={true}
        allowLeadingZeros={false}
        allowNegative={false}
        className={styles.field}
        decimalScale={2}
        disabled={disabled}
        hideControls={true}
        onBlur={handleSubmit}
        onChange={setCellValue}
        onFocus={handleFocus}
        onKeyDown={handleKeyPresses}
        placeholder={!isByHoursMethod ? "- %" : `- ${UNITS.hours}`}
        rightSection={rightSection}
        styles={
          disabled ? DISABLED_CELL_INPUT_STYLES : CREW_MIX_CELL_INPUT_STYLES
        }
        suffix={
          !isByHoursMethod
            ? " %"
            : cellValue === 1
              ? ` ${UNITS.hour}`
              : ` ${UNITS.hours}`
        }
        thousandSeparator=","
        value={cellValue}
        variant="unstyled"
        withKeyboardEvents={false}
      />
    </div>
  );
});
