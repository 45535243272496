export function uploadS3File(
  file: File,
  url: string,
  headers: Record<string, string>,
): Promise<Response> {
  return fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      ...headers,
    },
  });
}
