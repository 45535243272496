import { Tooltip, TooltipProps } from "@mantine/core";
import { Link, RichTextEditor } from "@mantine/tiptap";
import { IconIndentDecrease, IconIndentIncrease } from "@tabler/icons-react";
import { Node } from "@tiptap/core";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Heading } from "@tiptap/extension-heading";
import { Highlight } from "@tiptap/extension-highlight";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph } from "@tiptap/extension-paragraph";
import SubScript from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import { Table } from "@tiptap/extension-table";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableRow } from "@tiptap/extension-table-row";
import { TextAlign } from "@tiptap/extension-text-align";
import { Underline } from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import classNames from "classnames";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import { EMPTY_STRING } from "src/utils/empty";
import { WHITELISTED_ATTRIBUTES } from "./constants";
import styles from "./EstimationProposalTiptap.module.scss";

const TOOLTIP_STYLES: TooltipProps["styles"] = {
  tooltip: {
    fontSize: "11px",
    fontWeight: "500",
    backgroundColor: "var(--mantine-color-gray-7)",
    padding: "1px 6px",
    borderRadius: "0",
  },
};

interface Props extends WithClassname {
  readonly content: string | null;
  readonly contentHeight?: number;
  readonly isReadonly?: boolean;
  readonly onChange?: (arg: string) => void;
  readonly setFocusState?: (arg: boolean) => void;
}

export const EstimationProposalTiptap = React.memo<Props>(
  function _EstimationProposalTiptap({
    className,
    content,
    contentHeight,
    isReadonly = false,
    onChange,
    setFocusState,
  }) {
    const CustomParagraph = Paragraph.extend({
      addAttributes() {
        return {
          [WHITELISTED_ATTRIBUTES.placeholder]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.placeholder)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.placeholder)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.iterable]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.iterable)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.iterable)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.style]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.style)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.style)
                : null;
            },
          },
        };
      },
    });

    const CustomBulletList = BulletList.extend({
      addAttributes() {
        return {
          [WHITELISTED_ATTRIBUTES.placeholder]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.placeholder)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.placeholder)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.iterable]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.iterable)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.iterable)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.style]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.style)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.style)
                : null;
            },
          },
        };
      },
    });

    const CustomTable = Table.extend({
      addAttributes() {
        return {
          [WHITELISTED_ATTRIBUTES.entry]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.entry)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.entry)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.iterable]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.iterable)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.iterable)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.style]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.style)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.style)
                : null;
            },
          },
        };
      },
    });

    const CustomOrderedList = OrderedList.extend({
      addAttributes() {
        return {
          [WHITELISTED_ATTRIBUTES.iterable]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.iterable)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.iterable)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.type]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.type)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.type)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.start]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.start)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.start)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.style]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.style)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.style)
                : null;
            },
          },
        };
      },
    });

    const CustomListItem = ListItem.extend({
      addAttributes() {
        return {
          [WHITELISTED_ATTRIBUTES.iterable]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.iterable)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.iterable)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.type]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.type)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.type)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.start]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.start)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.start)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.style]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.style)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.style)
                : null;
            },
          },
        };
      },
    });

    const CustomSpan = Node.create<{
      HTMLAttributes: Record<string, unknown>;
    }>({
      name: "span",
      content: "inline*",
      group: "inline",
      inline: true,
      atom: true,
      selectable: false,
      addAttributes() {
        return {
          [WHITELISTED_ATTRIBUTES.placeholder]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.placeholder)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.placeholder)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.entry]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.entry)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.entry)
                : null;
            },
          },
          [WHITELISTED_ATTRIBUTES.style]: {
            default: null,
            parseHTML: (element: HTMLElement) => {
              return element.hasAttribute(WHITELISTED_ATTRIBUTES.style)
                ? element.getAttribute(WHITELISTED_ATTRIBUTES.style)
                : null;
            },
          },
        };
      },
      addOptions() {
        return {
          HTMLAttributes: {},
        };
      },
      parseHTML() {
        return [{ tag: "span" }];
      },
      renderHTML({ HTMLAttributes }) {
        const attributes = [
          {
            condition: HTMLAttributes[WHITELISTED_ATTRIBUTES.entry] == null,
            key: WHITELISTED_ATTRIBUTES.contenteditable,
            value: "false",
          },
        ].reduce((result, { condition, key, value }) => {
          if (condition) {
            return { ...result, [key]: value };
          }
          return result;
        }, HTMLAttributes);

        return ["span", attributes, 0];
      },
    });

    const editor = useEditor({
      autofocus: true,
      content: content,
      editable: !isReadonly,
      extensions: [
        StarterKit.configure({
          bulletList: false,
          listItem: false,
          orderedList: false,
          paragraph: false,
          heading: false,
        }),
        CustomOrderedList,
        CustomBulletList,
        CustomListItem,
        CustomParagraph,
        CustomSpan,
        CustomTable.configure({
          lastColumnResizable: false,
          resizable: true,
        }),
        Heading.configure({
          levels: [1, 2, 3, 4, 5, 6],
        }),
        Highlight,
        Link,
        SubScript,
        Superscript,
        TableCell.configure({
          HTMLAttributes: {
            style: null,
          },
        }),
        TableHeader.configure({
          HTMLAttributes: {
            class: "table-header",
            style: null,
            contenteditable: "false",
          },
        }),
        TableRow.configure({
          HTMLAttributes: {
            style: null,
          },
        }),
        TextAlign.configure({ types: ["heading", "paragraph"] }),
        Underline,
      ],
      onUpdate({ editor: _editor }) {
        if (onChange != null) {
          onChange(_editor.getHTML());
        }
      },
    });

    React.useEffect(() => {
      if (editor != null && content !== editor.getHTML()) {
        editor.commands.setContent(content ?? EMPTY_STRING);
      }
    }, [content, editor]);

    const handleBlur = React.useCallback(() => {
      if (setFocusState != null) {
        setFocusState(false);
      }
    }, [setFocusState]);

    const handleFocus = React.useCallback(() => {
      if (setFocusState != null) {
        setFocusState(true);
      }
    }, [setFocusState]);

    const handleListSink = React.useCallback(() => {
      editor?.chain().focus().sinkListItem("listItem").run();
    }, [editor]);

    const handleListLift = React.useCallback(() => {
      editor?.chain().focus().liftListItem("listItem").run();
    }, [editor]);

    const editorControls = (
      <RichTextEditor.Toolbar sticky={true} stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
          <RichTextEditor.H5 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <Tooltip
            label="Increase list indent"
            offset={{ mainAxis: 6, crossAxis: 15 }}
            openDelay={800}
            position="bottom-start"
            styles={TOOLTIP_STYLES}
          >
            <RichTextEditor.Control
              disabled={!editor?.can().sinkListItem("listItem")}
              onClick={handleListSink}
            >
              <IconIndentIncrease size={18} stroke={1} />
            </RichTextEditor.Control>
          </Tooltip>
          <Tooltip
            label="Decrease list indent"
            offset={{ mainAxis: 6, crossAxis: 15 }}
            openDelay={800}
            position="bottom-start"
            styles={TOOLTIP_STYLES}
          >
            <RichTextEditor.Control
              disabled={!editor?.can().liftListItem("listItem")}
              onClick={handleListLift}
            >
              <IconIndentDecrease size={18} stroke={1} />
            </RichTextEditor.Control>
          </Tooltip>
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Undo />
          <RichTextEditor.Redo />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>
    );

    return (
      <RichTextEditor
        className={!isReadonly ? styles.root : styles.previewReadonly}
        editor={editor}
        onBlur={handleBlur}
        onFocus={handleFocus}
      >
        {isReadonly ? null : editorControls}
        <RichTextEditor.Content
          className={classNames(className, styles.content)}
          style={
            contentHeight != null
              ? {
                  height: `${contentHeight}px`,
                }
              : undefined
          }
        />
      </RichTextEditor>
    );
  },
);
