import { Button, Group, Stack, Tooltip } from "@mantine/core";
import { IconEdit, IconInfoCircle, IconPlus } from "@tabler/icons-react";
import React from "react";
import { LabelValue } from "src/types/util/labelValue";
import { formatCurrency } from "src/utils/formatCurrency";
import { formatPercentage } from "src/utils/formatPercentage";
import { DEFAULT_PLACEHOLDER } from "../Table/constants";
import styles from "./CompositeRateSection.module.scss";
import { ESCALATION_TOOLTIP_LABEL, ESCALATION_TYPES } from "./constants";
import { EscalationField } from "./EscalationField";
import {
  CREW_MIX_ENTRY_KEYS,
  CrewMixEntry,
  SetCrewMixEntryArgs,
} from "./useCrewMixData";

interface Props {
  readonly getCrewMixEntry: (arg: string) => CrewMixEntry | undefined;
  readonly setCrewMixEntry: (arg: SetCrewMixEntryArgs) => void;
  readonly trade: LabelValue;
}

export const CompositeRateSection = React.memo<Props>(
  function _CompositeRateSection({ getCrewMixEntry, setCrewMixEntry, trade }) {
    const [isRateAdded, setIsRateAdded] = React.useState<boolean>(false);
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const crewMixEntry = React.useMemo(() => {
      return getCrewMixEntry(trade.value);
    }, [getCrewMixEntry, trade.value]);

    React.useEffect(() => {
      if (crewMixEntry != null && crewMixEntry.compositeEscalation !== 0) {
        setIsRateAdded(true);
      } else {
        setIsRateAdded(false);
      }
    }, [crewMixEntry]);

    const handleEditRate = React.useCallback(() => {
      setIsEdit(true);
    }, []);

    const handleCancelEdit = React.useCallback(() => {
      setIsEdit(false);
    }, []);

    const handleSubmitEdit = React.useCallback(
      (unit: string, value: number): void => {
        setIsEdit(false);
        if (
          unit === crewMixEntry?.compositeEscalationType &&
          value === crewMixEntry?.compositeEscalation
        ) {
          return;
        }

        setCrewMixEntry({
          key: CREW_MIX_ENTRY_KEYS.compositeEscalation,
          trade: trade.value,
          value: value,
        });

        setCrewMixEntry({
          key: CREW_MIX_ENTRY_KEYS.compositeEscalationType,
          trade: trade.value,
          value: unit,
        });

        if (value === 0) {
          setIsRateAdded(false);
        }
      },
      [crewMixEntry, setCrewMixEntry, trade.value],
    );

    const leftSection = React.useMemo(() => {
      const escalation =
        crewMixEntry == null
          ? DEFAULT_PLACEHOLDER
          : crewMixEntry.compositeEscalationType === ESCALATION_TYPES.percentage
            ? formatPercentage(crewMixEntry.compositeEscalation)
            : formatCurrency(crewMixEntry.compositeEscalation);

      if (isRateAdded && !isEdit) {
        return (
          <span className={styles.escalationDisplay}>
            Rate escalation: +{escalation}{" "}
            <IconEdit
              className={styles.editIcon}
              onClick={handleEditRate}
              size={18}
            />
          </span>
        );
      }
    }, [crewMixEntry, handleEditRate, isEdit, isRateAdded]);

    const rightSection = React.useMemo(() => {
      if (isEdit) {
        return (
          <EscalationField
            onCancel={handleCancelEdit}
            onSubmit={handleSubmitEdit}
            unit={
              crewMixEntry == null
                ? ESCALATION_TYPES.fixed
                : crewMixEntry.compositeEscalationType
            }
            value={crewMixEntry?.compositeEscalation ?? 0}
          />
        );
      } else if (!isRateAdded) {
        return (
          <Group>
            <Button
              className={styles.addEscalationButton}
              leftSection={<IconPlus size={14} />}
              onClick={handleEditRate}
              variant="outline"
            >
              Add rate escalation
            </Button>
            <Tooltip
              className={styles.tooltip}
              label={ESCALATION_TOOLTIP_LABEL}
              multiline={true}
              offset={{ mainAxis: 5, crossAxis: 5 }}
              position="top-end"
              w={280}
            >
              <IconInfoCircle className={styles.infoIcon} size={24} />
            </Tooltip>
          </Group>
        );
      }
    }, [
      crewMixEntry,
      handleCancelEdit,
      handleEditRate,
      handleSubmitEdit,
      isEdit,
      isRateAdded,
    ]);

    return (
      <Group className={styles.root}>
        <Group>
          <Stack className={styles.rateDisplay}>
            Composite rate
            <span className={styles.value}>
              {formatCurrency(crewMixEntry?.compositeRate ?? 0, 2, 2)}
            </span>
          </Stack>
          {leftSection}
        </Group>
        {rightSection}
      </Group>
    );
  },
);
