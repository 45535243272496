import { ActionIcon, Button, Group, Stack, Text } from "@mantine/core";
import { IconStar } from "@tabler/icons-react";
import { useCallback } from "react";
import { PACKAGE_PARAM } from "src/components/EstimationView/Table/constants";
import { useIsEditable } from "src/context/EditableProvider";
import {
  useAttachAssemblyMutation,
  useDeleteAssemblyMutation,
  useFavoriteAssemblyMutation,
  useUnfavoriteAssemblyMutation,
} from "src/data/api/api";
import { AssemblyType } from "src/data/api/types/getAssemblies";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { useScrollIntoView } from "src/hooks/useScrollIntoView";
import { SCROLLABLE_ELEMENT } from "src/utils/scrollableElement";
import AssemblyMenu from "../AssemblyMenu";
import styles from "./Assembly.module.scss";

interface Props3 {
  onFavorite: (assemblyId: number) => void;
  assembly: AssemblyType;
  onSelect: (assembly: AssemblyType) => void;
}

const Assembly = ({ assembly, onSelect, onFavorite }: Props3) => {
  const [packageId] = useQueryStringParams(PACKAGE_PARAM);
  const [deleteAssembly] = useDeleteAssemblyMutation();
  const [attachAssembly] = useAttachAssemblyMutation();
  const { setIsEditable } = useIsEditable();
  const scrollIntoView = useScrollIntoView(SCROLLABLE_ELEMENT, 1000);
  const [favoriteAssembly] = useFavoriteAssemblyMutation();
  const [unfavoriteAssembly] = useUnfavoriteAssemblyMutation();

  const isFavorite = assembly.attributes.is_favorite;

  const handleDelete = useCallback(() => {
    deleteAssembly(assembly.attributes.id);
  }, [assembly.attributes.id, deleteAssembly]);

  const handleAdd = useCallback(() => {
    if (packageId != null) {
      attachAssembly({
        assemblyId: assembly.attributes.id,
        body: {
          package_id: packageId,
        },
      }).then(() => scrollIntoView());
    }
  }, [assembly.attributes.id, attachAssembly, packageId, scrollIntoView]);

  const handleFavorite = useCallback(() => {
    onFavorite(assembly.attributes.id);
    if (isFavorite) {
      unfavoriteAssembly(assembly.attributes.id);
    } else {
      favoriteAssembly(assembly.attributes.id);
    }
  }, [
    assembly.attributes.id,
    favoriteAssembly,
    isFavorite,
    onFavorite,
    unfavoriteAssembly,
  ]);

  const handleView = useCallback(() => {
    onSelect(assembly);
    setIsEditable(false);
  }, [assembly, onSelect, setIsEditable]);

  const handleEdit = useCallback(() => {
    onSelect(assembly);
    setIsEditable(true);
  }, [assembly, onSelect, setIsEditable]);

  return (
    <Stack className={styles.root}>
      <Group justify="space-between">
        <Text className={styles.id} h={30}>
          {assembly.attributes.id}
        </Text>

        <Group gap="5px">
          <Button
            className={styles.button}
            onClick={handleAdd}
            variant="outline"
          >
            Add
          </Button>
          <Button
            className={styles.button}
            onClick={handleView}
            variant="outline"
          >
            View
          </Button>
          <ActionIcon
            color="var(--mantine-color-gray-5)"
            onClick={handleFavorite}
            size={22}
            variant="transparent"
          >
            <IconStar
              color={
                isFavorite
                  ? "var(--mantine-color-yellow-6)"
                  : "var(--mantine-color-gray-5)"
              }
              fill={
                isFavorite ? "var(--mantine-color-yellow-6)" : "transparent"
              }
            />
          </ActionIcon>

          <AssemblyMenu handleDelete={handleDelete} handleEdit={handleEdit} />
        </Group>
      </Group>
      <Text className={styles.title}>{assembly.attributes.title}</Text>
      {assembly.attributes.unit_of_measure ? (
        <Text className={styles.value}>
          {assembly.attributes.unit_of_measure.split("/")[0]}
          <span className={styles.units}>
            /{assembly.attributes.unit_of_measure.split("/")[1]}
          </span>
        </Text>
      ) : null}
      <Text
        className={styles.metadata}
      >{`Created by ${assembly.meta.created_by.first_name} ${assembly.meta.created_by.last_name} - ${assembly.meta.updated_at.split("T")[0]}`}</Text>
    </Stack>
  );
};

export default Assembly;
