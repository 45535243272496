import { Combobox, Group, InputBase, useCombobox } from "@mantine/core";
import {
  IconChevronDown,
  IconCurrencyDollar,
  IconPercentage,
} from "@tabler/icons-react";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import { DropdownOption } from "../Table/constants";
import { ESCALATION_TYPES } from "./constants";
import styles from "./EscalationTypeSelectField.module.scss";

const OPTIONS: ReadonlyArray<DropdownOption> = [
  {
    display: "%",
    icon: <IconPercentage size={14} />,
    label: "Percentage",
    value: ESCALATION_TYPES.percentage,
  },
  {
    display: "Fixed",
    icon: <IconCurrencyDollar size={14} />,
    label: "Fixed",
    value: ESCALATION_TYPES.fixed,
  },
];

const DEFAULT_ESCALATION_SELECTION = "Fixed";

interface Props extends WithClassname {
  readonly onChange: (value: string) => void;
  readonly value: string;
}

export const EscalationTypeSelectField = React.memo<Props>(
  function _EscalationTypeSelectField({ className, onChange, value }) {
    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const handleOptionSubmit = React.useCallback(
      (submittedValue: string) => {
        onChange(submittedValue);
        combobox.closeDropdown();
      },
      [combobox, onChange],
    );

    const handleClick = React.useCallback(() => {
      combobox.toggleDropdown();
    }, [combobox]);

    return (
      <div className={className}>
        <Combobox
          onOptionSubmit={handleOptionSubmit}
          store={combobox}
          width={150}
        >
          <Combobox.Target>
            <InputBase
              className={styles.button}
              component="button"
              onClick={handleClick}
              pointer={true}
              type="button"
              variant="unstyled"
            >
              <Group className={styles.buttonDisplay}>
                {OPTIONS.find((option) => option.value === value)?.display ??
                  DEFAULT_ESCALATION_SELECTION}
                <IconChevronDown size={16} />
              </Group>
            </InputBase>
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options>
              {OPTIONS.map((option) => (
                <Combobox.Option key={option.value} value={option.value}>
                  <Group gap="xs">
                    {option.icon}
                    {option.label}
                  </Group>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </div>
    );
  },
);
