import { Divider, Flex, Select, Stack, Text, TextInput } from "@mantine/core";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useUnitOfMeasure } from "src/components/EstimationView/Table/hooks/useUnitOfMeasure";

interface Props4 {
  onChange: (unitOfMeasure: string) => void;
}

const UnitOfMeasure = ({ onChange }: Props4) => {
  const unitOfMeasure = useUnitOfMeasure();
  const [unit, setUnit] = useState<string | null>(null);
  const [quantity, setQuantity] = useState("");

  const handleQuantity = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQuantity(value);
  }, []);

  const handleUnit = useCallback((value: string | null) => {
    setUnit(value);
  }, []);

  useEffect(() => {
    if (quantity.length > 0 && unit != null) {
      onChange(`${quantity}/${unit}`);
    }
  }, [onChange, quantity, unit]);

  return (
    <Stack gap={0} h={"60.8px"} justify="end" m={0} p={0}>
      <Text fw={500} fz="sm">
        Unit of measure
      </Text>
      <Flex
        style={{
          border: "1px solid #ced4da",
          borderRadius: "8px",
          boxSizing: "border-box",
        }}
      >
        <TextInput
          onChange={handleQuantity}
          px="xs"
          styles={{
            root: {
              height: "34px",
            },
          }}
          value={quantity}
          variant="unstyled"
          w={"70%"}
        />
        <Divider orientation="vertical" />
        <Select
          comboboxProps={{ width: 220, position: "bottom-start" }}
          data={unitOfMeasure}
          onChange={handleUnit}
          px="xs"
          style={{
            alignSelf: "end",
          }}
          styles={{
            root: {
              height: "34px",
            },
          }}
          value={unit}
          variant="unstyled"
          w={"30%"}
          withScrollArea={false}
        />
      </Flex>
    </Stack>
  );
};

export default UnitOfMeasure;
