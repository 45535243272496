import {
  DragDropContext,
  type DraggableLocation,
  type DragStart,
  type DropResult,
} from "@hello-pangea/dnd";
import { createContext, useCallback, useMemo } from "react";
import { useMoveItemsMutation, useSortItemsMutation } from "src/data/api/api";
import { PackageBundle } from "src/data/api/types/getBundles";
import { findById } from "src/utils/findById";
import { getLineItems } from "src/utils/getLineItems";
import { useExpandableLineItems } from "../ExpandableLineItemsProvider";

const useDragAndDrop = (bundles: PackageBundle[], packageId: string) => {
  const { setExpandedItems } = useExpandableLineItems();

  const [sortItems] = useSortItemsMutation();
  const [moveItems] = useMoveItemsMutation();

  const allBundles = useMemo(() => {
    return bundles
      .map((bundle) =>
        bundle.bundle_type === "group"
          ? [bundle].concat(bundle.children.map((child) => child.attributes))
          : bundle,
      )
      .flat();
  }, [bundles]);

  const handleSameBundle = useCallback(
    (
      draggableId: number,
      destination: DraggableLocation,
      source: DraggableLocation,
    ) => {
      if (destination.index === source.index) {
        return;
      }

      //find position in DB for the element in the current position
      const position =
        findById(allBundles, Number(destination.droppableId))?.line_items[
          destination.index
        ]?.position ?? 1;

      sortItems({
        meta: {
          bundleDestinationId: destination.droppableId,
          packageId,
        },
        line_item_ids: [
          {
            id: draggableId,
            position: position,
            index: destination.index,
          },
        ],
      });
    },
    [allBundles, packageId, sortItems],
  );

  const handleNewBundle = useCallback(
    (
      draggableId: number,
      destination: DraggableLocation,
      source: DraggableLocation,
    ) => {
      //current line item at the index
      const position =
        findById(allBundles, Number(destination.droppableId))?.line_items[
          destination.index
        ]?.position ?? 1;

      moveItems({
        meta: {
          packageId,
          bundleDestinationId: destination.droppableId,
          bundleSourceId: source.droppableId,
        },
        action_type: "cut",
        bundle_ids: [],
        package_ids: [],
        line_item_ids: [
          {
            id: draggableId,
            position: position,
            index: destination.index,
          },
        ],
        location: {
          type: "Bundle",
          id: Number(destination.droppableId),
        },
      });
    },
    [allBundles, moveItems, packageId],
  );

  const handleExpandedItems = useCallback(
    (lineItemId: number) => {
      const lineItem = findById(getLineItems(bundles), lineItemId);

      if (lineItem) {
        setExpandedItems((items) => items.concat(lineItem?.id));
      }
    },
    [bundles, setExpandedItems],
  );

  const handleBundleDrop = useCallback(
    (
      draggableId: number,
      source: DraggableLocation,
      destination: DraggableLocation,
    ) => {
      if (destination.index === source.index) {
        return;
      }

      //find position in DB for the element in the current position
      const position = bundles[destination.index]?.position ?? 1;

      sortItems({
        meta: {
          bundleDestinationId: destination.droppableId,
          bundleSourceId: source.droppableId,
          packageId,
        },
        bundle_ids: [{ id: draggableId, position, index: destination.index }],
      });
    },
    [bundles, packageId, sortItems],
  );

  const handleLineItemDrop = useCallback(
    (
      draggableId: number,
      source: DraggableLocation,
      destination: DraggableLocation,
    ) => {
      if (source.droppableId === destination.droppableId) {
        handleSameBundle(draggableId, destination, source);
      } else {
        handleNewBundle(draggableId, destination, source);
      }

      handleExpandedItems(draggableId);
    },
    [handleExpandedItems, handleNewBundle, handleSameBundle],
  );

  const handleDragStart = useCallback(
    (start: DragStart) => {
      const { draggableId } = start;
      setExpandedItems((items) => {
        return items.filter((item) => item !== Number(draggableId));
      });
    },
    [setExpandedItems],
  );

  const handleDropEnd = useCallback(
    (result: DropResult) => {
      if (result.destination == null) {
        return;
      }

      switch (result.type) {
        case "LineItem":
          return handleLineItemDrop(
            Number(result.draggableId),
            result.source,
            result.destination,
          );
        case "Bundle":
          return handleBundleDrop(
            Number(result.draggableId),
            result.source,
            result.destination,
          );
        default:
          return;
      }
    },
    [handleBundleDrop, handleLineItemDrop],
  );

  return { handleDragStart, handleDropEnd };
};

const DragAndDropContextInner = createContext(null);

interface Props {
  children: React.ReactNode;
  bundles: PackageBundle[];
  packageId: string;
}

const DragAndDropProvider = ({ children, bundles, packageId }: Props) => {
  const { handleDragStart, handleDropEnd } = useDragAndDrop(bundles, packageId);

  return (
    <DragAndDropContextInner.Provider value={null}>
      <DragDropContext onDragEnd={handleDropEnd} onDragStart={handleDragStart}>
        {children}
      </DragDropContext>
    </DragAndDropContextInner.Provider>
  );
};

export default DragAndDropProvider;
