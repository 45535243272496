import { Group, Stack, Title } from "@mantine/core";
import React from "react";
import { GetLaborSourceOptionsReturns } from "src/data/api/types/getLaborSourceOptions";
import { LabelValue } from "src/types/util/labelValue";
import { formatNumericValue } from "src/utils/formatNumericValue";
import { DEFAULT_PLACEHOLDER } from "../Table/constants";
import { CompositeRateSection } from "./CompositeRateSection";
import { TRADE_OPTIONS_FOR_LABOR, UNITS } from "./constants";
import { CrewMixControls } from "./CrewMixControls";
import { CrewMixTable } from "./CrewMixTable";
import { DifferentialField } from "./DifferentialField";
import styles from "./PackageCrewMixMenu.module.scss";
import { UnionSelectField } from "./UnionSelectField";
import {
  CLASSIFICATION_ENTRY_KEYS,
  CREW_MIX_ENTRY_KEYS,
  CrewMixEntry,
  ResetEntryArgs,
  SetClassificationEntryArgs,
  SetCrewMixEntryArgs,
} from "./useCrewMixData";
import { CrewMixMethodType, HoursMethodType } from "./util/utils";

interface Props {
  readonly getCrewMixEntry: (arg: string) => CrewMixEntry | undefined;
  readonly isTabActive: boolean;
  readonly resetCrewMixEntry: (arg: ResetEntryArgs) => void;
  readonly setClassificationEntry: (arg: SetClassificationEntryArgs) => void;
  readonly setCrewMixEntry: (arg: SetCrewMixEntryArgs) => void;
  readonly trade: LabelValue;
}

export const PackageCrewMixMenu = React.memo<Props>(
  function _PackageCrewMixMenu({
    getCrewMixEntry,
    isTabActive,
    resetCrewMixEntry,
    setClassificationEntry,
    setCrewMixEntry,
    trade,
  }) {
    const [crewMixMethod, setCrewMixMethod] = React.useState<CrewMixMethodType>(
      CrewMixMethodType.Percent,
    );
    const [hoursMethod, setHoursMethod] = React.useState<HoursMethodType>(
      HoursMethodType.Hours,
    );

    const crewMixEntry = React.useMemo(() => {
      return getCrewMixEntry(trade.value);
    }, [getCrewMixEntry, trade.value]);

    React.useEffect(() => {
      if (crewMixEntry != null && crewMixEntry.crewHeads > 0) {
        setCrewMixMethod(CrewMixMethodType.Person);
      }
    }, [crewMixEntry]);

    const handleCrewMixMethodSelect = React.useCallback(
      (value: string) => {
        setCrewMixMethod(value as CrewMixMethodType);
        if (crewMixEntry == null) {
          return;
        }

        const classificationIds = crewMixEntry.classificationEntries?.map(
          (classification) => classification.classificationId,
        );

        if (value === CrewMixMethodType.Percent) {
          // Reset to default crew mix percentages, preserving differential
          const differential = crewMixEntry.differential;
          resetCrewMixEntry({
            trade:
              trade.value as keyof GetLaborSourceOptionsReturns["collection"],
            laborSourceId: crewMixEntry.laborSourceId.toString(),
          });

          setCrewMixEntry({
            key: CREW_MIX_ENTRY_KEYS.differential,
            trade: trade.value,
            value: differential,
          });

          classificationIds?.map((id) => {
            const classificationEntry =
              crewMixEntry?.classificationEntries?.find(
                (classification) => classification.classificationId === id,
              );

            if (
              classificationEntry?.straightTimeWageTotal == null ||
              classificationEntry.straightTimeWageBase == null
            ) {
              return;
            }

            setClassificationEntry({
              classificationId: id,
              key: CLASSIFICATION_ENTRY_KEYS.straightTimeWageAdjusted,
              trade: trade.value,
              value:
                (differential / 100) *
                  classificationEntry.straightTimeWageBase +
                classificationEntry.straightTimeWageTotal,
            });
          });
        } else if (value === CrewMixMethodType.Person) {
          setCrewMixEntry({
            key: CREW_MIX_ENTRY_KEYS.crewHeads,
            trade: trade.value,
            value: 0,
          });

          classificationIds?.map((id) => {
            // Reset mix, ST/OT/DT all to zero
            setClassificationEntry({
              classificationId: id,
              key: CLASSIFICATION_ENTRY_KEYS.crewHeads,
              trade: trade.value,
              value: 0,
            });

            setClassificationEntry({
              classificationId: id,
              key: CLASSIFICATION_ENTRY_KEYS.crewPercentage,
              trade: trade.value,
              value: 0,
            });

            setClassificationEntry({
              classificationId: id,
              key: CLASSIFICATION_ENTRY_KEYS.straightTimePercentage,
              trade: trade.value,
              value: 0,
            });

            setClassificationEntry({
              classificationId: id,
              key: CLASSIFICATION_ENTRY_KEYS.overTimePercentage,
              trade: trade.value,
              value: 0,
            });

            setClassificationEntry({
              classificationId: id,
              key: CLASSIFICATION_ENTRY_KEYS.doubleTimePercentage,
              trade: trade.value,
              value: 0,
            });
          });
        }
      },
      [
        crewMixEntry,
        resetCrewMixEntry,
        setClassificationEntry,
        setCrewMixEntry,
        trade.value,
      ],
    );

    return (
      <Stack>
        <Title order={2}>{trade.label}</Title>
        <Group>
          <Stack>
            <span className={styles.unionDifferentialLabel}>Union code</span>
            <UnionSelectField
              disabled={
                trade.value === TRADE_OPTIONS_FOR_LABOR.projectManagement ||
                trade.value === TRADE_OPTIONS_FOR_LABOR.warehouse
              }
              laborSourceId={(crewMixEntry?.laborSourceId ?? 0).toString()}
              resetCrewMixEntry={resetCrewMixEntry}
              setCrewMixEntry={setCrewMixEntry}
              trade={
                trade.value as keyof GetLaborSourceOptionsReturns["collection"]
              }
            />
          </Stack>
          <Stack>
            <span className={styles.unionDifferentialLabel}>
              Shift differential
            </span>
            <DifferentialField
              disabled={
                trade.value === TRADE_OPTIONS_FOR_LABOR.projectManagement
              }
              getCrewMixEntry={getCrewMixEntry}
              setClassificationEntry={setClassificationEntry}
              setCrewMixEntry={setCrewMixEntry}
              trade={trade.value}
            />
          </Stack>
        </Group>
        <Title order={3}>Crew mix</Title>
        <Group className={styles.controlsSection}>
          <Stack className={styles.laborHoursDisplay}>
            Total labor hours
            <span
              className={styles.laborHoursTotal}
            >{`${crewMixEntry?.totalHours != null ? formatNumericValue(crewMixEntry.totalHours, 0, 0) : DEFAULT_PLACEHOLDER} ${crewMixEntry?.totalHours === 1 ? UNITS.hour : UNITS.hours}`}</span>
          </Stack>
          <CrewMixControls
            crewMixMethod={crewMixMethod}
            hoursMethod={hoursMethod}
            onCrewMixMethodSelect={handleCrewMixMethodSelect}
            onHoursMethodSelect={setHoursMethod}
          />
        </Group>
        <CrewMixTable
          crewMixMethod={crewMixMethod}
          displayTooltips={isTabActive}
          getCrewMixEntry={getCrewMixEntry}
          hoursMethod={hoursMethod}
          setClassificationEntry={setClassificationEntry}
          setCrewMixEntry={setCrewMixEntry}
          trade={trade.value}
        />
        <CompositeRateSection
          getCrewMixEntry={getCrewMixEntry}
          setCrewMixEntry={setCrewMixEntry}
          trade={trade}
        />
      </Stack>
    );
  },
);
