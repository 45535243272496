import { CheckIcon, Group } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { DropdownOption } from "../../constants";
import CategoryPopover from "../CategoryPopover";

interface Props {
  currentCategory: string | null;
  item: DropdownOption;
  currentItem: string | null;
  handleUpdate: (value: string, category?: string | null) => void;
  openPop: boolean;
  setOpenPop: React.Dispatch<React.SetStateAction<boolean>>;
}

const CostTypeDropdownOption = ({
  item,
  currentItem,
  currentCategory,
  handleUpdate,
  openPop,
  setOpenPop,
}: Props) => {
  return (
    <>
      <Group gap="xs" justify="space-between">
        <Group gap="xs">
          {item.value === currentItem ? <CheckIcon size={12} /> : item.icon}
          {item.label}
        </Group>
        {item.categories?.length ? <IconChevronRight /> : null}
      </Group>
      {currentItem === item.value && (item.categories?.length ?? 0) > 0 && (
        <CategoryPopover
          currentCategory={currentCategory}
          handleUpdate={handleUpdate}
          item={item}
          open={openPop}
          setOpenPopover={setOpenPop}
        />
      )}
    </>
  );
};

export default CostTypeDropdownOption;
