import { useCallback } from "react";

export const useScrollIntoView = (elementId: string, delay: number = 500) => {
  return useCallback(() => {
    setTimeout(() => {
      const element = document.getElementById(elementId);

      element?.scrollIntoView({ behavior: "instant" });
    }, delay);
  }, [delay, elementId]);
};
