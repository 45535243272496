import { Button, Group, Modal, Stack } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { useController, useForm } from "react-hook-form";
import { BranchField } from "src/components/Fields/BranchField";
import { EstimationField } from "src/components/Fields/EstimationField";
import { REQUIRED_RULES } from "src/components/Forms/constants";
import { NewBid } from "src/components/Forms/types/bid";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api, useCalculateEstimationMutation } from "src/data/api/api";
import {
  Association,
  AssociationType,
  EstimationAttributes,
  ResourceType,
  SALES_LEAD_ROLE,
} from "src/data/api/types/bulkTransaction";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { CalculatedByType, LoadedBid } from "src/data/api/types/shared/bid";
import { EstimationPackages } from "./EstimationPackages";

type FormValue = NewBid["estimation"];

interface Props {
  readonly bid: LoadedBid;
  readonly estimationId: string;
  readonly onClose: () => void;
}

export const EditEstimationDialog = React.memo<Props>(
  function _EditEstimationDialog({ bid, estimationId, onClose }) {
    const [step, setStep] = useState(0);
    const [selectedItems, setSelectedItems] = useState<PackageTotal[]>([]);
    const [calculateEstimation] = useCalculateEstimationMutation();

    const estimation = React.useMemo(
      () =>
        bid.attributes.estimations.find(
          (entry) => entry.attributes.uuid === estimationId,
        ),
      [bid.attributes.estimations, estimationId],
    );

    const { control, handleSubmit } = useForm<FormValue>({
      defaultValues: {
        branch: bid.attributes.branch_id?.toString(),
        estimations: [
          {
            jobTypes: estimation?.attributes.job_types,
            salesLead: estimation?.users[0]?.uuid,
            status: estimation?.attributes.status,
            total: estimation?.attributes.total,
            calculated_by: estimation?.attributes.calculated_by,
          },
        ],
      },
    });

    const branch = useController({
      control,
      name: "branch",
      rules: REQUIRED_RULES,
    });

    const [trigger, mutationState] =
      api.endpoints.bulkTransaction.useMutation();

    const handleEstimationUpdate = React.useCallback(
      async (submittedValue: FormValue) => {
        try {
          const [newEstimation] = submittedValue.estimations;

          if (newEstimation == null || estimation?.attributes.uuid == null) {
            throw new Error();
          }

          if (
            newEstimation.status === "won" &&
            step === 0 &&
            newEstimation.calculated_by === CalculatedByType.System
          ) {
            setStep(1);
            return;
          }

          const estimations_attributes: Array<EstimationAttributes> = [];
          const associations: Array<Association> = [];
          estimations_attributes.push({
            id: estimation.attributes.id,
            job_types: newEstimation.jobTypes,
            status: newEstimation.status,
            total: newEstimation.total,
            calculated_by: newEstimation.calculated_by,
          });
          associations.push({
            type: AssociationType.Role,
            id: estimation.users[0]?.roles.find(
              (entry) =>
                entry.resource_type === ResourceType.Estimation &&
                entry.resource_id === estimation.attributes.id,
            )?.id,
            user_id: newEstimation.salesLead,
            resource_type: ResourceType.Estimation,
            resource_id: estimation.attributes.uuid,
            metadata: {
              name: SALES_LEAD_ROLE,
            },
          });

          const promises: unknown[] = [
            trigger({
              bulk_transaction: {
                associations,
                job_sites_attributes: [
                  {
                    id: bid.attributes.job_site.id,
                    bids_attributes: [
                      {
                        id: bid.attributes.id,
                        branch_id: submittedValue.branch,
                        estimations_attributes,
                      },
                    ],
                  },
                ],
              },
            }),
          ];

          if (step === 1) {
            promises.push(
              calculateEstimation({
                id: estimation.attributes.id,
                options: {
                  package_ids: selectedItems.map((item) => ({ id: item.name })),
                },
              }),
            );
          }

          await Promise.allSettled(promises);

          onClose();
        } catch (error) {
          notifications.show({
            title: "Error",
            message: "Failed to update estimation",
          });
        }
      },
      [
        bid.attributes.id,
        bid.attributes.job_site.id,
        calculateEstimation,
        estimation?.attributes.id,
        estimation?.attributes.uuid,
        estimation?.users,
        onClose,
        selectedItems,
        step,
        trigger,
      ],
    );

    return (
      <Modal
        centered={true}
        onClose={onClose}
        opened={true}
        size="lg"
        title={step === 1 ? "Mark status as Won" : "Update estimation"}
      >
        {step === 0 && (
          <Stack>
            <BranchField
              error={branch.fieldState.error?.message}
              onChange={branch.field.onChange}
              value={branch.field.value}
            />

            {estimation != null ? (
              <EstimationField
                calculatedBy={estimation.attributes.calculated_by}
                control={control}
                estimationId={estimation.attributes.id.toString()}
                index={0}
                shouldRenderSellAmountField={true}
              />
            ) : null}
          </Stack>
        )}

        {step === 1 && estimation != null && (
          <EstimationPackages
            estimationId={estimation.attributes.id.toString()}
            selectedPackages={selectedItems}
            setSelectedPackages={setSelectedItems}
          />
        )}

        <FormFooter
          rightSection={
            <Group gap={10}>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                loading={mutationState.isLoading}
                onClick={handleSubmit(handleEstimationUpdate)}
              >
                Save
              </Button>
            </Group>
          }
        />
      </Modal>
    );
  },
);
