import { Button, Group, Stack, Textarea, TextInput } from "@mantine/core";
import { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { useCreateAssemblyMutation } from "src/data/api/api";
import UnitOfMeasure from "./components/UnitOfMeasure";

interface Props {
  onSuccess: () => void;
  onClose: () => void;
  selectedItemIds: number[];
}

const CreateAssemblyForm = ({ onClose, selectedItemIds, onSuccess }: Props) => {
  const [title, setTitle] = useState("");
  const [unitOfMeasure, setUnitOfMeasure] = useState<string | null>(null);
  const [scope, setScope] = useState("");
  const [createAssembly] = useCreateAssemblyMutation();

  const handleCreate = useCallback(
    (event: FormEvent<HTMLDivElement>) => {
      event.preventDefault();

      createAssembly({
        scope,
        title,
        unit_of_measure: unitOfMeasure,
        line_item_ids: selectedItemIds,
      }).then(() => onSuccess());
    },
    [createAssembly, onSuccess, scope, selectedItemIds, title, unitOfMeasure],
  );

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  }, []);

  const handleScope = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setScope(event.currentTarget.value);
  }, []);
  return (
    <Stack component={"form"} onSubmit={handleCreate}>
      <TextInput
        label="Assembly title"
        onChange={handleChange}
        required
        value={title}
      />
      <UnitOfMeasure onChange={setUnitOfMeasure} />
      <Textarea
        autosize
        label="Scope"
        maxRows={7}
        minRows={3}
        onChange={handleScope}
        required
        value={scope}
      />
      <Group justify={"end"}>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button type="submit">Create</Button>
      </Group>
    </Stack>
  );
};

export default CreateAssemblyForm;
