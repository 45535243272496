import { Button, ButtonProps, Stack } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import classNames from "classnames";
import React, { forwardRef } from "react";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { SPLIT_OPTIONS } from "./Proposal/constants";
import styles from "./SplitScreenToggle.module.scss";

const BUTTON_STYLES: ButtonProps["styles"] = {
  label: {
    overflow: "visible",
  },
};

export const SplitScreenToggle = React.memo(
  forwardRef<HTMLDivElement>(function _SplitScreenToggle(_, ref) {
    const [split, setSplit] = useQueryStringParams("split");

    const handleRightResize = React.useCallback(() => {
      if (split === SPLIT_OPTIONS.left) {
        setSplit(SPLIT_OPTIONS.mid);
      } else if (split === SPLIT_OPTIONS.mid) {
        setSplit(SPLIT_OPTIONS.right);
      }
    }, [setSplit, split]);

    const handleLeftResize = React.useCallback(() => {
      if (split === SPLIT_OPTIONS.right) {
        setSplit(SPLIT_OPTIONS.mid);
      } else if (split === SPLIT_OPTIONS.mid) {
        setSplit(SPLIT_OPTIONS.left);
      }
    }, [setSplit, split]);

    return (
      <div
        ref={ref}
        className={classNames(
          styles.root,
          split === SPLIT_OPTIONS.mid
            ? styles.noWidth
            : split === SPLIT_OPTIONS.left
              ? styles.left
              : styles.right,
        )}
      >
        {split === SPLIT_OPTIONS.left ? (
          <span
            className={styles.label}
            style={{
              paddingLeft: "20px",
              paddingRight: "10px",
            }}
          >
            PROPOSAL
          </span>
        ) : null}
        <Stack className={styles.buttonWrapper}>
          {split === SPLIT_OPTIONS.right ? null : (
            <Button
              className={styles.button}
              onClick={handleRightResize}
              styles={BUTTON_STYLES}
              variant="subtle"
            >
              <IconChevronRight size={18} strokeWidth={2} />
            </Button>
          )}
          {split === SPLIT_OPTIONS.left ? null : (
            <Button
              className={styles.button}
              onClick={handleLeftResize}
              styles={BUTTON_STYLES}
              variant="subtle"
            >
              <IconChevronLeft size={18} strokeWidth={2} />
            </Button>
          )}
        </Stack>
        {split === SPLIT_OPTIONS.right ? (
          <span
            className={styles.label}
            style={{
              paddingRight: "20px",
              paddingLeft: "10px",
            }}
          >
            ESTIMATE
          </span>
        ) : null}
      </div>
    );
  }),
);
