import { Group } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { formatNumericValue } from "src/utils/formatNumericValue";
import { DEFAULT_PLACEHOLDER, UnitPosition } from "./constants";
import styles from "./ValueDisplayCell.module.scss";

interface Props {
  readonly displayClassName?: string | undefined;
  readonly maxPrecision?: number;
  readonly minPrecision?: number;
  readonly unitDisplay: string;
  readonly unitPosition: UnitPosition;
  readonly value?: number;
}

export const ValueDisplayCell = React.memo<Props>(function _ValueDisplayCell({
  displayClassName,
  maxPrecision = 0,
  minPrecision = 0,
  unitDisplay,
  unitPosition,
  value,
}) {
  const valueDisplay =
    value != null
      ? formatNumericValue(value, minPrecision, maxPrecision)
      : DEFAULT_PLACEHOLDER;

  return (
    <Group
      className={classNames(
        styles.root,
        displayClassName,
        value == null ? styles.emptyDisplay : undefined,
      )}
    >
      {unitPosition === UnitPosition.Prefix ? (
        <>
          <span>{unitDisplay}</span>
          {valueDisplay}
        </>
      ) : (
        <>
          {valueDisplay}
          <span>{unitDisplay}</span>
        </>
      )}
    </Group>
  );
});
