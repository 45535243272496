import { Box, Button, Stack } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import classNames from "classnames";
import { useContextMenu } from "mantine-contextmenu";
import { DataTable, DataTableRowClickHandler } from "mantine-datatable";
import React from "react";
import { useExpandableLineItems } from "src/context/ExpandableLineItemsProvider";
import {
  useCreateLineItemMutation,
  useUpdateItemsMutation,
} from "src/data/api/api";
import { PackageBundle } from "src/data/api/types/getBundles";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { CONTEXT_MENU_STYLES } from "../../constants";
import { useCurrentPackage } from "../../hooks/useCurrentPackage";
import { useGroupContextMenu } from "../../hooks/useGroupContextMenu";
import GroupTitle from "../GroupTitle";
import LineItemBundle from "../LineItemBundle/LineItemBundle";
import TableContainer from "../TableContainer";
import styles from "./GroupBundle.module.scss";

interface Props {
  noHeader: boolean;
  bundle: PackageBundle;
  packages: PackageId[];
}

const isExpandable = () => true;

const GroupBundle = React.memo<Props>(function _GroupBundle({
  bundle,
  packages,
}) {
  const { showContextMenu } = useContextMenu();
  const [updateBundle] = useUpdateItemsMutation();
  const [createLineItem] = useCreateLineItemMutation();
  const selectedPackageId = useCurrentPackage();
  const { expandableGroups } = useExpandableLineItems();

  const createGroupContextMenu = useGroupContextMenu(packages);

  const handleNewBundle = React.useCallback(() => {
    if (selectedPackageId != null) {
      createLineItem({
        id: undefined,
        bundle: {
          package_id: selectedPackageId,
          bundle_type: "line_item",
          parent_id: bundle.id,
        },
      });
    }
  }, [bundle.id, createLineItem, selectedPackageId]);

  const handleGroupRowContextMenu: DataTableRowClickHandler<PackageBundle> =
    React.useCallback(
      ({ record, event }) => {
        const contextMenu = createGroupContextMenu(record);

        showContextMenu(contextMenu, CONTEXT_MENU_STYLES)(event);
      },
      [createGroupContextMenu, showContextMenu],
    );

  const handleInnerTable = React.useCallback(
    ({ record }: { record: PackageBundle }) => {
      const bundles = record.children;

      return (
        <Box className={styles.root}>
          <Stack gap={0}>
            {bundles.map((innerBundle) => (
              <LineItemBundle
                key={innerBundle.id}
                className={classNames(styles.innerTable)}
                lineItems={innerBundle.attributes.line_items}
                noHeader={true}
                packages={packages}
                showNewItemButton={true}
              />
            ))}
          </Stack>
          <Button
            className={styles.newItem}
            leftSection={<IconPlus size="16px" />}
            onClick={handleNewBundle}
            variant="transparent"
          >
            New item
          </Button>
        </Box>
      );
    },
    [handleNewBundle, packages],
  );

  const renderGroupTitle = React.useCallback(
    (v: PackageBundle) => <GroupTitle bundle={v} handleUpdate={updateBundle} />,
    [updateBundle],
  );

  const columns = React.useMemo(() => {
    return [
      {
        width: "800px",
        title: "Name",
        accessor: "title",
        cellsClassName: styles.column,
        render: renderGroupTitle,
      },
      {
        width: "160px",
        title: "summary",
        accessor: "total",
        cellsClassName: styles.column,
        render: () => {
          return <p>{null}</p>;
        },
      },
    ];
  }, [renderGroupTitle]);

  return (
    <TableContainer
      bundleId={bundle.id}
      onClick={handleNewBundle}
      showNewItemButton={true}
    >
      <DataTable
        borderColor="var(--mantine-color-gray-2)"
        columns={columns}
        height="fit-content"
        highlightOnHover={true}
        noHeader={true}
        noRecordsIcon={<React.Fragment />}
        onRowContextMenu={handleGroupRowContextMenu}
        records={[bundle]}
        rowExpansion={{
          expanded: {
            recordIds: expandableGroups,
          },
          allowMultiple: true,
          initiallyExpanded: isExpandable,
          content: handleInnerTable,
        }}
        withColumnBorders={true}
      />
    </TableContainer>
  );
});

export default GroupBundle;
