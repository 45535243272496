import { Address } from "src/types/address";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";
import { ExistingContact, NewContact } from "./contact";
import { ExistingCustomer, NewCustomer } from "./customer";

interface BidReceiver {
  readonly customer: NewCustomer | ExistingCustomer | null;
  readonly contacts: ReadonlyArray<NewContact | ExistingContact>;
  readonly dueDate: Date | null;
  readonly walkDate: Date | null;
}

export const DEFAULT_BID_RECEIVER_VALUE: BidReceiver = {
  customer: null,
  contacts: EMPTY_ARRAY,
  dueDate: null,
  walkDate: null,
};

interface Estimation {
  readonly jobTypes: ReadonlyArray<string>;
  readonly salesLead: string;
  readonly status: string;
  readonly total?: number;
  readonly id?: number;
  readonly calculated_by?: string;
}

export const DEFAULT_ESTIMATION_VALUE: Estimation = {
  jobTypes: EMPTY_ARRAY,
  salesLead: EMPTY_STRING,
  status: EMPTY_STRING,
};

export interface NewBid {
  readonly attributes: {
    readonly name: string;
    readonly jobSiteAddress: Address | null;
    readonly jobSiteAddressDetails?: string;
    readonly constructionType: string;
    readonly bidStage: string;
    readonly relationship: string;
    readonly difficulty: string;
    readonly priority: string;
  };
  readonly bidReceivers: {
    readonly bidReceivers: ReadonlyArray<BidReceiver>;
  };
  readonly estimation: {
    readonly branch: string;
    readonly estimations: ReadonlyArray<Estimation>;
  };
}

export const DEFAULT_NEW_BID_VALUE: NewBid = {
  attributes: {
    name: EMPTY_STRING,
    jobSiteAddress: null,
    jobSiteAddressDetails: EMPTY_STRING,
    constructionType: EMPTY_STRING,
    bidStage: EMPTY_STRING,
    relationship: EMPTY_STRING,
    difficulty: EMPTY_STRING,
    priority: EMPTY_STRING,
  },
  bidReceivers: {
    bidReceivers: [DEFAULT_BID_RECEIVER_VALUE],
  },
  estimation: {
    branch: EMPTY_STRING,
    estimations: [DEFAULT_ESTIMATION_VALUE],
  },
};
