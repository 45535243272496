import { Group } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import styles from "./FormFooter.module.scss";

interface Props extends WithClassname {
  readonly leftSection?: React.ReactElement;
  readonly rightSection?: React.ReactElement;
}

export const FormFooter = React.memo<Props>(function _FormFooter({
  className,
  leftSection = <div />,
  rightSection = <div />,
}) {
  return (
    <Group
      className={classNames(className, styles.footer)}
      justify="space-between"
    >
      {leftSection}
      {rightSection}
    </Group>
  );
});
