import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useParams } from "react-router-dom";
import { MatchParam } from "src/constants/matches";
import { api } from "src/data/api/api";
import { Estimation, LoadedBid } from "src/data/api/types/shared/bid";
import { asMutableArray } from "src/utils/asMutableArray";
import { EMPTY_STRING } from "src/utils/empty";
import { formatCurrency } from "src/utils/formatCurrency";
import { DEFAULT_PLACEHOLDER } from "../Table/constants";
import { getEntries } from "./utils";

export interface ProposalValues {
  [key: string]: string | { [key: string]: string };
  addressDetails: string;
  addressDisplay: string;
  addressFull: string;
  altPackages: {
    [key: string]: string;
  };
  altCount: string;
  baseCost: string;
  baseDescription: string;
  baseScope: string;
  baseTitle: string;
  bidNumber: string;
  contactName: string;
  currentDate: string;
  customerName: string;
  projectName: string;
  salesLead: string;
}

export const PROPOSAL_VALUES_KEYS = {
  addressDetails: "addressDetails",
  addressDisplay: "addressDisplay",
  addressFull: "addressFull",
  altPackages: "altPackages",
  altCount: "altCount",
  baseCost: "baseCost",
  baseDescription: "baseDescription",
  baseScope: "baseScope",
  baseTitle: "baseTitle",
  bidNumber: "bidNumber",
  contactName: "contactName",
  currentDate: "currentDate",
  customerName: "customerName",
  projectName: "projectName",
  salesLead: "salesLead",
};

interface ProposalValuesArgs {
  readonly bid: LoadedBid | undefined;
  readonly estimation: Estimation | undefined;
  readonly proposal: string | null;
}

interface ProposalValuesReturns {
  readonly clearEntries: () => ProposalValues | null;
  readonly values: ProposalValues | null;
}

export function useProposalValues({
  bid,
  estimation,
  proposal,
}: ProposalValuesArgs): ProposalValuesReturns {
  const [values, setValues] = React.useState<ProposalValues | null>(null);

  const addressInfo = React.useMemo(() => {
    return bid?.attributes.address;
  }, [bid?.attributes.address]);

  /*
  Query for customer & contact
  */
  const { currentData: contactQuery } = api.endpoints.getContact.useQuery(
    bid?.attributes.contacts[0]?.id.toString() ?? skipToken,
  );
  const contact = contactQuery?.contact.data;

  const { currentData: customerQuery } = api.endpoints.getCustomer.useQuery(
    bid?.attributes.customers[0]?.id.toString() ?? skipToken,
  );

  const customer = React.useMemo(() => {
    return customerQuery?.customer.data;
  }, [customerQuery?.customer.data]);

  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();

  /* 
  Query for packages
  */
  const packagesQuery = api.endpoints.getPackages.useQuery(
    estimationId ?? skipToken,
  );

  const { isLoaded: isPackagesLoaded, data: packages } = React.useMemo(() => {
    const isLoaded = packagesQuery.isSuccess || packagesQuery.isError;
    const data = packagesQuery.currentData?.collection;
    return { isLoaded, data };
  }, [packagesQuery]);

  const sortedPackages = React.useMemo(() => {
    if (isPackagesLoaded && packages != null) {
      return asMutableArray(packages)
        ?.slice()
        .sort((a, b) => {
          if (b.title == null) return -1;
          if (a.title == null) return 1;
          return a.title.localeCompare(b.title);
        });
    }
  }, [isPackagesLoaded, packages]);

  const { index: baseIndex, data: base } = React.useMemo(() => {
    const index = sortedPackages?.findIndex(
      (_package) => _package.title != null && /^base/i.test(_package.title),
    );

    const data = sortedPackages?.[index ?? 0];
    return { index, data };
  }, [sortedPackages]);

  const proposalEntries = React.useMemo(() => {
    return getEntries(proposal ?? EMPTY_STRING);
  }, [proposal]);

  React.useEffect(() => {
    if (
      !isPackagesLoaded ||
      packages == null ||
      sortedPackages == null ||
      bid == null ||
      estimation == null ||
      contact == null ||
      customer == null
    ) {
      return;
    }

    const packageValues = sortedPackages
      .map((_package, index) => {
        if (index === baseIndex) {
          return;
        }

        const suffix = index < (baseIndex ?? 999) ? index + 1 : index;
        return {
          [`title${suffix}`]: _package.title ?? EMPTY_STRING,
          [`cost${suffix}`]: formatCurrency(
            parseFloat(_package.grand_total),
            0,
            2,
          ),
          [`description${suffix}`]:
            proposalEntries[`description${suffix}`] ?? DEFAULT_PLACEHOLDER,
          [`scope${suffix}`]:
            proposalEntries[`scope${suffix}`] ?? DEFAULT_PLACEHOLDER,
        };
      })
      .filter((value) => value != null);

    setValues({
      addressDetails: bid.attributes.address_details ?? EMPTY_STRING,
      addressDisplay:
        addressInfo?.city == null ||
        addressInfo.region == null ||
        addressInfo.street1 == null
          ? addressInfo?.full_address ?? EMPTY_STRING
          : `${addressInfo.street1}${bid.attributes.address_details != null ? ` ${bid.attributes.address_details}` : EMPTY_STRING}, ${addressInfo.city}, ${addressInfo?.region_abbrev ?? addressInfo.region} ${addressInfo?.zip_code ?? EMPTY_STRING}`,
      addressFull: addressInfo?.full_address ?? EMPTY_STRING,
      altPackages: packageValues.reduce((acc, _values) => {
        return { ...acc, ..._values };
      }, {}),
      altCount: packageValues.length.toString(),
      baseCost: formatCurrency(parseFloat(base?.grand_total ?? "0"), 0, 2),
      baseDescription: proposalEntries.baseDescription ?? DEFAULT_PLACEHOLDER,
      baseScope: proposalEntries.baseScope ?? DEFAULT_PLACEHOLDER,
      baseTitle: base?.title ?? EMPTY_STRING,
      bidNumber: bid.id,
      contactName: `${contact.attributes.first_name} ${contact.attributes.last_name}`,
      currentDate: new Date().toLocaleDateString(),
      customerName: `${customer?.attributes.name}`,
      projectName: bid.attributes.name,
      salesLead: `${estimation.users[0]?.first_name ?? EMPTY_STRING} ${estimation.users[0]?.last_name ?? EMPTY_STRING}`,
    });
  }, [
    addressInfo,
    base,
    baseIndex,
    bid,
    contact,
    customer,
    estimation,
    isPackagesLoaded,
    packages,
    proposalEntries,
    sortedPackages,
  ]);

  const clearEntries = React.useCallback((): ProposalValues | null => {
    if (values == null) {
      return null;
    }

    const updatedAltPackages = Object.fromEntries(
      Object.entries(values.altPackages).map(([key, value]) => [
        key,
        key.startsWith("description") || key.startsWith("scope")
          ? DEFAULT_PLACEHOLDER
          : value,
      ]),
    );

    const updatedValues = {
      ...values,
      altPackages: updatedAltPackages,
      baseDescription: DEFAULT_PLACEHOLDER,
    };

    setValues(updatedValues);
    return updatedValues;
  }, [values]);

  return { clearEntries, values };
}
