import { DataTable } from "mantine-datatable";
import React from "react";
import { useCostTypeInnerTableColumns } from "src/components/EstimationSummaryView/hooks/useCostTypeInnerTableColumns";
import { PackageTotal } from "src/data/api/types/getPackageTotals";

interface Props {
  record: PackageTotal;
  records: PackageTotal[];
}

const CostTypeInnerTable = React.memo<Props>(function _CostTypeInnerTable({
  record,
  records,
}) {
  const columnsInner = useCostTypeInnerTableColumns(records);

  const rows = React.useMemo(() => {
    return [
      {
        display_name: record.display_name,
        name: record.name,
        apply_margin: true,
        grand_total: (
          Number(record.subtotal) + Number(record.margin)
        ).toString(),
        margin: record.margin,
        subtotal: record.subtotal,
        taxes: record.taxes,
        formula: record.formula,
        total_hours: record.total_hours,
        total_labor_cost: "",
        other_expenses: record.other_expenses,
      },
      {
        display_name: "Sales tax",
        name: "sales tax",
        apply_margin: false,
        grand_total: record.taxes,
        margin: "0",
        subtotal: record.taxes,
        taxes: record.taxes,
        formula: record.formula,
        total_hours: record.total_hours,
        total_labor_cost: "",
        other_expenses: "",
      },
    ];
  }, [
    record.display_name,
    record.formula,
    record.margin,
    record.name,
    record.other_expenses,
    record.subtotal,
    record.taxes,
    record.total_hours,
  ]);

  return (
    <DataTable
      backgroundColor="var(--mantine-color-gray-0)"
      columns={columnsInner}
      defaultColumnProps={{
        textAlign: "right",
      }}
      height="100%"
      idAccessor={"name"}
      noHeader
      records={rows}
    />
  );
});

export default CostTypeInnerTable;
