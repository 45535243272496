import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUsers } from "@tabler/icons-react";
import React from "react";
import { ESTIMATION_HEADER_BUTTON_STYLES } from "../constants";
import styles from "./SetCrewMixButton.module.scss";
import { SetCrewMixDialog } from "./SetCrewMixDialog";

export const SetCrewMixButton = React.memo(function _SetCrewMixButton() {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Button
        className={styles.root}
        leftSection={<IconUsers size={14} />}
        onClick={open}
        styles={ESTIMATION_HEADER_BUTTON_STYLES}
        variant="outline"
      >
        Set crew mix
      </Button>

      {opened && <SetCrewMixDialog onClose={close} />}
    </>
  );
});
