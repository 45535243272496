import React from "react";

interface Props {
  color: string;
  size?: number;
}

export const EditIcon = React.memo<Props>(function _EditIcon({
  color,
  size = 16,
}) {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="edit">
        <path
          d="M4.66797 4.66667H4.0013C3.64768 4.66667 3.30854 4.80714 3.05849 5.05719C2.80844 5.30724 2.66797 5.64638 2.66797 6V12C2.66797 12.3536 2.80844 12.6928 3.05849 12.9428C3.30854 13.1929 3.64768 13.3333 4.0013 13.3333H10.0013C10.3549 13.3333 10.6941 13.1929 10.9441 12.9428C11.1942 12.6928 11.3346 12.3536 11.3346 12V11.3333M10.668 3.33333L12.668 5.33333M13.5913 4.39007C13.8539 4.12751 14.0014 3.77139 14.0014 3.40007C14.0014 3.02875 13.8539 2.67264 13.5913 2.41007C13.3287 2.14751 12.9726 2 12.6013 2C12.23 2 11.8739 2.14751 11.6113 2.41007L6.0013 8.00007V10.0001H8.0013L13.5913 4.39007Z"
          id="Vector"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});
