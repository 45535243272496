import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { useExpenseQuantity } from "./hooks/useExpenseQuantity";
import { QuantityCellV2 } from "./QuantityCellV2";

interface Props {
  column: "main" | "labor";
  parent?: BundleLineItem;
  record: BundleLineItem;
  handleUpdate: (bundleId: number, lineItem: LineItem) => void;
  isEditable: boolean;
}

export const QuantityCell = ({
  record,
  handleUpdate,
  column,
  isEditable,
}: Props) => {
  const { value, unit, updateQuantity, options, total } = useExpenseQuantity(
    record,
    handleUpdate,
    column,
  );

  return (
    <QuantityCellV2
      key={`${value}-${unit}`}
      disabled={!isEditable}
      handleUpdate={updateQuantity}
      isInlineLabor={column === "labor"}
      options={options}
      total={total}
      unit={unit}
      value={value.toString()}
    />
  );
};
