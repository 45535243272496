import { Group, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import styles from "./GroupNameCell.module.scss";

interface Props {
  label: string;
  expandable: boolean;
  expanded: boolean;
}

export const GroupNameCell = React.memo<Props>(function _GroupNameCell({
  label,
  expandable,
  expanded,
}) {
  return (
    <Group className={styles.root}>
      {expandable ? (
        <IconChevronRight
          className={classNames(styles.icon, styles.expandIcon, {
            [styles.expandIconRotated]: expanded,
          })}
          size={18}
        />
      ) : null}
      <Text className={styles.content}>{label}</Text>
    </Group>
  );
});
