import { Text } from "@mantine/core";
import React from "react";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { calculatePercent } from "src/utils/calculatePercent";
import { calculateTotal } from "src/utils/calculateTotal";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";

interface Props {
  record: PackageTotal;
  records: PackageTotal[];
  withTaxes?: boolean;
}

export const PercentOfCostCell = React.memo<Props>(function _PercentOfCostCell({
  record,
  records,
  withTaxes = true,
}) {
  const value = React.useMemo(() => {
    const costSum =
      calculateTotal(records, "subtotal") + calculateTotal(records, "taxes");
    const total = withTaxes
      ? Number(record.subtotal) + Number(record.taxes)
      : Number(record.subtotal);

    const percent = calculatePercent(total, costSum);

    return `${formatNumberHundredths(percent)} %`;
  }, [record.subtotal, record.taxes, records, withTaxes]);

  return <Text fz="xs">{value}</Text>;
});
