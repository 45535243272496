import { NumberFormatter, Stack, Text } from "@mantine/core";
import { useShallowEffect } from "@mantine/hooks";
import { DataTable } from "mantine-datatable";
import { useCallback, useMemo } from "react";
import { CostCell } from "src/components/Frames/Table/CostCell";
import { MarginCell } from "src/components/Frames/Table/MarginCell";
import { useGetPackagesQuery } from "src/data/api/api";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { buildPackageTotal } from "src/utils/buildPackageTotal";
import { calculateTotal } from "src/utils/calculateTotal";
import { formatCurrency } from "src/utils/formatCurrency";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";

interface Props {
  estimationId: string;
  selectedPackages: PackageTotal[];
  setSelectedPackages: React.Dispatch<React.SetStateAction<PackageTotal[]>>;
}

export const EstimationPackages = ({
  estimationId,
  selectedPackages,
  setSelectedPackages,
}: Props) => {
  const { currentData, isLoading } = useGetPackagesQuery(estimationId);

  const packages = useMemo(() => {
    if (currentData == null) {
      return [];
    }

    return currentData.collection.map(buildPackageTotal);
  }, [currentData]);

  const totalPrice = useMemo(() => {
    return formatNumberHundredths(
      calculateTotal(selectedPackages, "grand_total"),
    );
  }, [selectedPackages]);

  const renderTotalCost = useCallback((v: PackageTotal) => {
    return <CostCell record={v} />;
  }, []);

  const renderMargin = useCallback((v: PackageTotal) => {
    return <MarginCell record={v} />;
  }, []);

  const renderSellPrice = useCallback(
    (v: PackageTotal) => (
      <Text fz="sm">{formatCurrency(Number(v.grand_total))}</Text>
    ),
    [],
  );

  useShallowEffect(() => {
    if (currentData != null) {
      setSelectedPackages(
        currentData.collection
          .filter((_package) => _package.accepted)
          .map(buildPackageTotal),
      );
    }
  }, [currentData]);

  return (
    <Stack>
      <Text>
        To calculate total contract value, select packages accepted by customer.
      </Text>
      <DataTable
        columns={[
          { title: "Package", accessor: "display_name" },
          {
            title: "Total Cost",
            accessor: "subtotal",
            render: renderTotalCost,
          },
          { title: "Margin", accessor: "margin", render: renderMargin },
          {
            title: "Sell price",
            accessor: "grand_total",
            render: renderSellPrice,
          },
        ]}
        fetching={isLoading}
        height={300}
        idAccessor={"name"}
        onSelectedRecordsChange={setSelectedPackages}
        records={packages}
        selectedRecords={selectedPackages}
        withColumnBorders
      />
      <Stack
        bg={"var(--mantine-primary-color-0)"}
        gap="0"
        px="lg"
        py="sm"
        styles={{
          root: {
            borderRadius: "var(--mantine-radius-md)",
          },
        }}
      >
        <Text fw="bolder" fz="sm">
          Total Sell Price
        </Text>
        <NumberFormatter
          prefix={"$"}
          style={{
            color: "var(--mantine-primary-color-9)",
            fontWeight: 700,
          }}
          thousandSeparator
          value={totalPrice}
        />
      </Stack>
    </Stack>
  );
};
