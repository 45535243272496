import React from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";
import {
  UnitPosition,
  VALUE_CELL_UNIT_DISPLAYS,
  valueDisplayStyles,
} from "../constants";
import { CostTypeCell } from "../CostTypeCell";
import styles from "../EstimationColumnMain.module.scss";
import { LaborCell } from "../LaborCell";
import { QuantityCell } from "../QuantityCell";
import { TextCell } from "../TextCell";
import { TradeCell } from "../TradeCell";
import { UnitCostCell } from "../UnitCostCell";
import { ValueDisplayCell } from "../ValueDisplayCell";

type ColumnType = {
  cellsClassName?: string;
  width: string;
  accessor: string;
  render?: (record: BundleLineItem) => JSX.Element | null;
};

export function useEstimationColumnMain(
  handleUpdate: (bundleId: number, lineItem: LineItem) => void,
  isEditable: boolean,
  isAssembly: boolean,
  parentLineItem?: BundleLineItem,
) {
  const WIDTH = "150px";
  const WIDTH_DOUBLE = "180px";

  const renderCostType = React.useCallback(
    (record: BundleLineItem) => (
      <CostTypeCell
        handleUpdate={handleUpdate}
        isEditable={isEditable}
        record={record}
      />
    ),
    [handleUpdate, isEditable],
  );

  const renderTrades = React.useCallback(
    (record: BundleLineItem) => (
      <TradeCell
        key={record.trade}
        handleUpdate={handleUpdate}
        isEditable={isEditable}
        record={record}
      />
    ),
    [handleUpdate, isEditable],
  );

  const renderQuantity = React.useCallback(
    (record: BundleLineItem) => (
      <QuantityCell
        column="main"
        handleUpdate={handleUpdate}
        isEditable={isEditable}
        parent={parentLineItem}
        record={record}
      />
    ),
    [handleUpdate, isEditable, parentLineItem],
  );

  const renderLabor = React.useCallback(
    (record: BundleLineItem) => (
      <LaborCell
        handleUpdate={handleUpdate}
        isEditable={isEditable}
        parent={parentLineItem}
        record={record}
      />
    ),
    [handleUpdate, isEditable, parentLineItem],
  );

  const renderUnitCost = React.useCallback(
    (record: BundleLineItem) => (
      <UnitCostCell
        key={record.cost}
        handleUpdate={handleUpdate}
        isEditable={isEditable}
        record={record}
      />
    ),
    [handleUpdate, isEditable],
  );

  return React.useMemo(() => {
    const columns: ColumnType[] = [];

    if (isAssembly === false && parentLineItem != null) {
      columns.push({
        cellsClassName: styles.selector,
        width: "41px",
        accessor: "check",
        render: () => <div />,
      });
    }

    return columns.concat([
      {
        cellsClassName: styles.column,
        width: "190px",
        accessor: "cost_type",
        render: renderCostType,
      },
      {
        cellsClassName: styles.column,
        accessor: "trade",
        width: WIDTH,
        render: renderTrades,
      },
      {
        cellsClassName: styles.column,
        accessor: "description",
        width: WIDTH_DOUBLE,
        render: (v: BundleLineItem) => {
          const handleDescription = (value: string) => {
            handleUpdate(v.bundle_id, {
              id: v.id,
              description: value,
            });
          };

          return (
            <TextCell
              crewMixId={v.crew_mix_id}
              disabled={!isEditable}
              handleUpdate={handleDescription}
              value={v.description ?? EMPTY_STRING}
            />
          );
        },
      },
      {
        cellsClassName: styles.column,
        width: "130px",
        accessor: "qty",
        render: renderQuantity,
      },
      {
        cellsClassName: styles.column,
        width: "130px",
        accessor: "cost",
        render: renderUnitCost,
      },
      {
        cellsClassName: styles.column,
        accessor: "subtotal",
        width: "130px",
        render: (v: BundleLineItem) => {
          if (v.subtotal != null) {
            return (
              <ValueDisplayCell
                displayClassName={valueDisplayStyles}
                maxPrecision={2}
                minPrecision={2}
                unitDisplay={VALUE_CELL_UNIT_DISPLAYS.DOLLAR}
                unitPosition={UnitPosition.Prefix}
                value={Number(v.subtotal)}
              />
            );
          }

          return null;
        },
      },
      {
        cellsClassName: styles.labor,
        accessor: "labor",
        width: "100%",
        render: renderLabor,
      },
    ]);
  }, [
    handleUpdate,
    isAssembly,
    isEditable,
    parentLineItem,
    renderCostType,
    renderLabor,
    renderQuantity,
    renderTrades,
    renderUnitCost,
  ]);
}
