import { Loader, Stack } from "@mantine/core";
import { useParams } from "react-router-dom";
import { MatchParam } from "src/constants/matches";
import { Paths } from "src/constants/paths";
import { BackToEstimationViewButton } from "../Frames/BackToEstimationViewButton";
import EstimationMargin from "./components/EstimationMargin";

const EstimationMarginView = () => {
  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();

  if (estimationId == null) {
    return <Loader />;
  }

  return (
    <Stack h="100%" p="sm" w="100%">
      <BackToEstimationViewButton view={Paths.MARGINS} />
      <EstimationMargin estimationId={estimationId} />
    </Stack>
  );
};

export default EstimationMarginView;
