import { Group } from "@mantine/core";
import { memo, useMemo } from "react";
import { laborColumns, mainColumns } from "../../constants";
import { StickyHeader } from "./StickyHeader";
import styles from "./StickyTableHeaders.module.scss";

interface Props {
  isSelectable?: boolean;
}

const StickyTableHeaders = memo(({ isSelectable = true }: Props) => {
  const main = useMemo(() => {
    if (isSelectable === true) {
      return mainColumns;
    }

    return mainColumns.filter(
      (column) => !["checkbox", "drag_and_drop"].includes(column.value),
    );
  }, [isSelectable]);

  return (
    <Group className={styles.root}>
      <StickyHeader columns={main} title="MAIN" />
      <StickyHeader columns={laborColumns} title="LABOR" />
    </Group>
  );
});

export default StickyTableHeaders;
