import {
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  Tooltip,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { PREVENT_DEFAULT } from "src/constants/preventDefault";
import { api } from "src/data/api/api";
import {
  AddCrewMixArgs,
  ClassificationLaborAttributes,
} from "src/data/api/types/addCrewMix";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { LoadedCrewMix } from "src/data/api/types/shared/crewMix";
import { LaborSource } from "src/data/api/types/shared/laborSource";
import { UpdateCrewMixArgs } from "src/data/api/types/updateCrewMix";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";
import {
  DEFAULT_CLASSIFICATION,
  DIFFERENTIAL_INPUT_STYLES,
  HOUR_TYPE_LOOKUP,
  HOUR_TYPE_OPTIONS,
  TRADE_OPTIONS_FOR_LABOR,
} from "../EstimationView/CrewMix/constants";
import {
  EstimationResourceType,
  LaborTypeTime,
} from "../EstimationView/CrewMix/util/utils";
import { DEFAULT_PLACEHOLDER } from "../EstimationView/Table/constants";
import { FormFooter } from "../Frames/FormFooter";
import styles from "./LineItemCrewMixFormContent.module.scss";
import {
  DEFAULT_LINE_ITEM_CREW_MIX_VALUE,
  LineItemCrewMix,
} from "./types/crewMix";

interface Props {
  estimationId: string | undefined;
  onClose: () => void;
  packageId: string;
  record: BundleLineItem;
}

export const LineItemCrewMixFormContent = React.memo<Props>(
  function _LineItemCrewMixFormContent({
    estimationId,
    onClose,
    packageId,
    record,
  }) {
    const [addCrewMix, addState] = api.endpoints.addCrewMix.useMutation();
    const [deleteCrewMix, deleteState] =
      api.endpoints.deleteCrewMix.useMutation();
    const [updateCrewMix, updateState] =
      api.endpoints.updateCrewMix.useMutation();

    /* 
      Load package -> crew mixes -> crew mix for selected trade labor
    */
    const packagesQuery = api.endpoints.getPackages.useQuery(
      estimationId ?? skipToken,
    );
    const { isPackagesLoaded, packages } = React.useMemo(() => {
      return {
        isPackagesLoaded: packagesQuery.isSuccess || packagesQuery.isError,
        packages: packagesQuery.currentData?.collection,
      };
    }, [packagesQuery]);

    const selectedPackage = React.useMemo(() => {
      if (isPackagesLoaded) {
        return (
          packages?.find((entry) => entry.id.toString() === packageId) ??
          packages?.[0]
        );
      }
    }, [isPackagesLoaded, packages, packageId]);

    const crewMixIds = selectedPackage?.crew_mix_id ?? EMPTY_ARRAY;
    const crewMixQueries = crewMixIds?.map((crewMixId) =>
      api.endpoints.getCrewMix.useQuery(crewMixId),
    );

    const { isLoaded: isCrewMixLoaded, data: packageCrewMix } =
      React.useMemo(() => {
        const isLoaded = crewMixQueries?.every(
          (queryResult) => queryResult.isSuccess || queryResult.isError,
        );

        // Package comes with crew mixes for every possible trade
        const data = crewMixQueries
          ?.map((queryResult) => queryResult.currentData?.crew_mix.data)
          .filter((_crewMix) => record?.trade === _crewMix?.attributes.trade)
          .filter((_crewMix) => _crewMix != null);

        return { isLoaded, data };
      }, [crewMixQueries, record]);

    /* 
      Load labor source for this trade's package crew mix
    */
    const laborSourceQuery = api.endpoints.getLaborSource.useQuery(
      packageCrewMix[0]?.attributes.labor_source.id.toString() ?? skipToken,
    );

    const { isLaborSourceLoaded, laborSource } = React.useMemo(() => {
      return {
        isLaborSourceLoaded:
          laborSourceQuery.isSuccess || laborSourceQuery.isError,
        laborSource: laborSourceQuery.currentData?.labor_source.data,
      };
    }, [laborSourceQuery]);

    const classificationOptions = React.useMemo(() => {
      return [
        DEFAULT_CLASSIFICATION,
        ...(laborSource?.attributes.labor_types
          .map((laborType) => laborType.name)
          .filter((option) => option != null) ?? EMPTY_ARRAY),
      ];
    }, [laborSource]);

    /* 
      Load crew mix on this line item
    */
    const lineItemCrewMix = api.endpoints.getCrewMix.useQuery(
      record.crew_mix_id ?? skipToken,
    ).currentData?.crew_mix.data;

    const isLoading =
      !isLaborSourceLoaded ||
      !isCrewMixLoaded ||
      (record.crew_mix_id != null && lineItemCrewMix == null);

    const isWarehouse = record.trade === TRADE_OPTIONS_FOR_LABOR.warehouse;
    const isProjectManagement =
      record.trade === TRADE_OPTIONS_FOR_LABOR.projectManagement;

    const selectedCrewMixPercentage =
      lineItemCrewMix?.attributes.crew_mix_percentages.find(
        (obj) => obj.percentage === 1,
      );

    const initialClassification = laborSource?.attributes.labor_types.find(
      (laborType) => laborType.id === selectedCrewMixPercentage?.labor_type_id,
    )?.name;

    const initialHourType =
      Object.values(HOUR_TYPE_LOOKUP)[
        Object.keys(HOUR_TYPE_LOOKUP).indexOf(
          selectedCrewMixPercentage?.labor_type_time ?? EMPTY_STRING,
        )
      ];

    const initialDifferential = parseFloat(
      lineItemCrewMix?.attributes.differential ?? EMPTY_STRING,
    );

    const {
      control,
      formState: { isDirty },
      handleSubmit,
      reset,
    } = useForm<LineItemCrewMix>(
      record.crew_mix_id == null ||
        (record.crew_mix_id != null && lineItemCrewMix == null)
        ? {
            defaultValues: {
              classification: isWarehouse
                ? classificationOptions[1]
                : DEFAULT_LINE_ITEM_CREW_MIX_VALUE.classification,
              hourType: isProjectManagement
                ? HOUR_TYPE_OPTIONS[1]
                : DEFAULT_LINE_ITEM_CREW_MIX_VALUE.hourType,
              differential: DEFAULT_LINE_ITEM_CREW_MIX_VALUE.differential,
            },
          }
        : {
            defaultValues: {
              classification: initialClassification,
              hourType: initialHourType,
              differential: initialDifferential,
            },
          },
    );

    /* 
      Side effect to update form with loaded in values
    */
    React.useEffect(() => {
      if (!isLoading) {
        reset({
          classification: isWarehouse
            ? classificationOptions[1]
            : initialClassification,
          hourType: initialHourType,
          differential: initialDifferential,
        });
      }
    }, [
      classificationOptions,
      initialClassification,
      initialDifferential,
      initialHourType,
      isLoading,
      isWarehouse,
      reset,
    ]);

    const classification = useController({
      control,
      name: "classification",
    });

    const hourType = useController({
      control,
      name: "hourType",
    });

    const differential = useController({
      control,
      name: "differential",
    });

    const isFormInvalid = React.useMemo(() => {
      return (
        (classification.field.value === DEFAULT_CLASSIFICATION &&
          hourType.field.value !== HOUR_TYPE_OPTIONS[0] &&
          !isProjectManagement) ||
        (classification.field.value !== DEFAULT_CLASSIFICATION &&
          hourType.field.value === HOUR_TYPE_OPTIONS[0] &&
          !isWarehouse)
      );
    }, [classification, hourType, isProjectManagement, isWarehouse]);

    const handleDialogSubmit = React.useCallback(
      async (formValues: LineItemCrewMix) => {
        if (laborSource == null) return;
        if (!isDirty) onClose(); // user made no changes

        try {
          await saveLineItemCrewMix({
            addCrewMix: async (args: AddCrewMixArgs) => {
              addCrewMix(args);
            },
            crewMixSelections: formValues,
            crewMixOnLineItem: lineItemCrewMix,
            deleteCrewMix: async (arg: number) => {
              deleteCrewMix(arg);
            },
            laborSource,
            record,
            selectedPackage,
            updateCrewMix: async (args: UpdateCrewMixArgs) => {
              updateCrewMix(args);
            },
          });
        } catch (error) {
          notifications.show({
            message: "Crew mix couldn't be updated. Please try again.",
          });
        }
      },
      [
        addCrewMix,
        deleteCrewMix,
        isDirty,
        laborSource,
        lineItemCrewMix,
        onClose,
        record,
        selectedPackage,
        updateCrewMix,
      ],
    );

    React.useEffect(() => {
      if (
        addState.isSuccess ||
        updateState.isSuccess ||
        deleteState.isSuccess ||
        deleteState.isError
      ) {
        onClose();
      }
    }, [addState, deleteState, onClose, updateState]);

    return (
      <>
        <Stack className={styles.root}>
          <Select
            allowDeselect={false}
            checkIconPosition="right"
            data={classificationOptions}
            disabled={isLoading || isWarehouse}
            label="Classification"
            onChange={classification.field.onChange}
            value={classification.field.value}
            withScrollArea={false}
          />
          <Select
            allowDeselect={false}
            checkIconPosition="right"
            data={HOUR_TYPE_OPTIONS}
            disabled={isLoading || isProjectManagement}
            label="Hour type"
            onChange={hourType.field.onChange}
            value={hourType.field.value}
            withScrollArea={false}
          />
          {hourType.field.value === "Straight time" ? (
            <NumberInput
              decimalScale={2}
              hideControls={true}
              label="Shift differential"
              onChange={differential.field.onChange}
              placeholder={DEFAULT_PLACEHOLDER}
              rightSection={
                <Group className={styles.percent} onMouseDown={PREVENT_DEFAULT}>
                  %
                </Group>
              }
              rightSectionPointerEvents="none"
              rightSectionWidth={42}
              styles={DIFFERENTIAL_INPUT_STYLES}
              value={
                differential.field.value === 0
                  ? EMPTY_STRING
                  : differential.field.value
              }
            />
          ) : null}
        </Stack>
        <FormFooter
          rightSection={
            <Group>
              <Button
                className={styles.button}
                onClick={onClose}
                variant="default"
              >
                Cancel
              </Button>
              <Tooltip
                className={styles.tooltip}
                disabled={!isFormInvalid}
                label="Cannot set only one field to default"
              >
                <Button
                  className={styles.button}
                  disabled={isFormInvalid}
                  loading={
                    addState.isLoading ||
                    updateState.isLoading ||
                    deleteState.isLoading
                  }
                  onClick={handleSubmit(handleDialogSubmit)}
                  variant="filled"
                >
                  Apply
                </Button>
              </Tooltip>
            </Group>
          }
        />
      </>
    );
  },
);

interface Args {
  addCrewMix: (arg: AddCrewMixArgs) => Promise<void>;
  crewMixSelections: {
    classification: string;
    hourType: string;
    differential: string | number;
  };
  crewMixOnLineItem: LoadedCrewMix | undefined;
  deleteCrewMix: (arg: number) => Promise<void>;
  laborSource: LaborSource;
  record: BundleLineItem;
  selectedPackage: PackageId | undefined;
  updateCrewMix: (arg: UpdateCrewMixArgs) => Promise<void>;
}

async function saveLineItemCrewMix({
  addCrewMix,
  crewMixSelections,
  crewMixOnLineItem,
  deleteCrewMix,
  laborSource,
  record,
  selectedPackage,
  updateCrewMix,
}: Args) {
  if (selectedPackage == null || record.trade == null) {
    return;
  }

  const isWarehouse = record.trade === TRADE_OPTIONS_FOR_LABOR.warehouse;
  const isProjectManagement =
    record.trade === TRADE_OPTIONS_FOR_LABOR.projectManagement;

  if (
    (isWarehouse && crewMixSelections.hourType === HOUR_TYPE_OPTIONS[0]) ||
    (isProjectManagement &&
      crewMixSelections.classification === DEFAULT_CLASSIFICATION) ||
    (!isWarehouse &&
      !isProjectManagement &&
      crewMixSelections.classification === DEFAULT_CLASSIFICATION)
  ) {
    /* 
      user resets line item crew mix to defaults
    */
    deleteCrewMix(record.crew_mix_id);
  } else if (record.crew_mix_id == null) {
    /* 
      user sets line item crew mix instead of using package level crew mix
    */
    const percentagesAttributes = laborSource.attributes.labor_types
      .map((laborType): Array<ClassificationLaborAttributes> => {
        const isSelectedLaborType =
          laborType.name === crewMixSelections.classification;

        return [
          {
            labor_type_id: laborType.id,
            labor_type_time: LaborTypeTime.StraightTime,
            percentage:
              isSelectedLaborType &&
              crewMixSelections.hourType ===
                HOUR_TYPE_LOOKUP[LaborTypeTime.StraightTime]
                ? 1
                : 0,
            wage: laborType.straight_time,
            wage_total: parseFloat(laborType.straight_total),
          },
          {
            labor_type_id: laborType.id,
            labor_type_time: LaborTypeTime.OverTime,
            percentage:
              isSelectedLaborType &&
              crewMixSelections.hourType ===
                HOUR_TYPE_LOOKUP[LaborTypeTime.OverTime]
                ? 1
                : 0,
            wage: laborType.over_time,
            wage_total: parseFloat(laborType.over_total),
          },
          {
            labor_type_id: laborType.id,
            labor_type_time: LaborTypeTime.DoubleTime,
            percentage:
              isSelectedLaborType &&
              crewMixSelections.hourType ===
                HOUR_TYPE_LOOKUP[LaborTypeTime.DoubleTime]
                ? 1
                : 0,
            wage: laborType.double_time,
            wage_total: parseFloat(laborType.double_total),
          },
        ];
      })
      .flat();

    await addCrewMix({
      crew_heads: 0,
      labor_source_id: parseFloat(laborSource.id),
      package_id: selectedPackage.id,
      resource_id: record.id,
      resource_type: EstimationResourceType.LineItem,
      trade: record.trade,
      differential:
        typeof crewMixSelections.differential == "string"
          ? 0
          : crewMixSelections.differential,
      escalation: 0,
      escalation_type: "fixed",
      crew_mix_percentages_attributes: percentagesAttributes ?? EMPTY_ARRAY,
    });
  } else if (record.crew_mix_id != null) {
    /* 
      user updates line item crew mix to have different options
    */
    const percentagesAttributes =
      crewMixOnLineItem?.attributes.crew_mix_percentages
        .map((obj): ClassificationLaborAttributes => {
          const isSelectedClassification =
            laborSource.attributes.labor_types.find(
              (laborType) =>
                laborType.name === crewMixSelections.classification,
            )?.id === obj.labor_type_id;

          const isSelectedHourType =
            Object.keys(HOUR_TYPE_LOOKUP)[
              Object.values(HOUR_TYPE_LOOKUP).indexOf(
                crewMixSelections.hourType,
              )
            ] === obj.labor_type_time;

          return {
            ...obj,
            labor_type_percentage: parseFloat(obj.labor_type_percentage),
            percentage: isSelectedClassification && isSelectedHourType ? 1 : 0,
            wage_total: parseFloat(obj.wage_total),
          };
        })
        .filter((entry) => entry != null);

    await updateCrewMix({
      crew_mix_id: record.crew_mix_id,
      differential:
        typeof crewMixSelections.differential == "string"
          ? 0
          : crewMixSelections.hourType ===
              HOUR_TYPE_LOOKUP[LaborTypeTime.StraightTime]
            ? crewMixSelections.differential
            : 0,
      crew_mix_percentages_attributes: percentagesAttributes ?? EMPTY_ARRAY,
    });
  }
}
