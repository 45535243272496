import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { MatchParam } from "src/constants/matches";
import { api } from "src/data/api/api";
import { GetLaborSourceOptionsReturns } from "src/data/api/types/getLaborSourceOptions";
import { GetPackageTotalsReturns } from "src/data/api/types/getPackageTotals";
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from "src/utils/empty";
import { formatNumber } from "src/utils/formatNumber";
import { PACKAGE_PARAM } from "../Table/constants";
import { ESCALATION_TYPES } from "./constants";
import { LaborTypeTime } from "./util/utils";

export interface CrewMixEntry {
  classificationEntries?: ReadonlyArray<ClassificationEntry>;
  compositeRate: number;
  compositeEscalation: number;
  compositeEscalationType: string; // "percentage" or "fixed"
  crewHeads: number;
  crewMixId?: string;
  crewMixPercentageData: ReadonlyArray<{
    readonly crewMixPercentageId: number;
    readonly laborTimeType: string;
    readonly laborTypeId: number;
  }>;
  differential: number;
  isCrewMixFaulty: boolean;
  laborSourceId: number;
  totalHours: number;
  trade: string;
}

export interface ClassificationEntry {
  allottedHours: number;
  classificationId: number;
  classificationName: string;
  crewHeads: number;
  crewPercentage: number;
  doubleTimePercentage: number;
  doubleTimeWageBase: number;
  doubleTimeWageTotal: number;
  straightTimePercentage: number;
  straightTimeWageAdjusted: number;
  straightTimeWageBase: number;
  straightTimeWageTotal: number;
  overTimePercentage: number;
  overTimeWageBase: number;
  overTimeWageTotal: number;
}

export const CREW_MIX_ENTRY_KEYS = {
  classificationEntries: "classificationEntries",
  compositeRate: "compositeRate",
  compositeEscalation: "compositeEscalation",
  compositeEscalationType: "compositeEscalationType",
  crewHeads: "crewHeads",
  crewMixPercentageData: "crewMixPercentageData",
  differential: "differential",
  isCrewMixFaulty: "isCrewMixFaulty",
  laborSourceId: "laborSourceId",
  totalHours: "totalHours",
  trade: "trade",
} as const;

export const CLASSIFICATION_ENTRY_KEYS = {
  allottedHours: "allottedHours",
  classificationId: "classificationId",
  classificationName: "classificationName",
  crewHeads: "crewHeads",
  crewPercentage: "crewPercentage",
  doubleTimePercentage: "doubleTimePercentage",
  doubleTimeWageBase: "doubleTimeWageBase",
  doubleTimeWageTotal: "doubleTimeWageTotal",
  straightTimePercentage: "straightTimePercentage",
  straightTimeWageAdjusted: "straightTimeWageAdjusted",
  straightTimeWageBase: "straightTimeWageBase",
  straightTimeWageTotal: "straightTimeWageTotal",
  overTimePercentage: "overTimePercentage",
  overTimeWageBase: "overTimeWageBase",
  overTimeWageTotal: "overTimeWageTotal",
} as const;

export const TIME_TYPE_KEYS: Record<
  LaborTypeTime,
  { percentage: keyof ClassificationEntry }
> = {
  [LaborTypeTime.StraightTime]: {
    percentage: "straightTimePercentage",
  },
  [LaborTypeTime.DoubleTime]: {
    percentage: "doubleTimePercentage",
  },
  [LaborTypeTime.OverTime]: {
    percentage: "overTimePercentage",
  },
};

type Args = ReadonlyArray<string>;

export interface ResetEntryArgs {
  readonly trade: keyof GetLaborSourceOptionsReturns["collection"];
  readonly laborSourceId: string;
}

export interface SetCrewMixEntryArgs {
  readonly key: string;
  readonly trade: string;
  readonly value: string | number | boolean;
}

export interface SetClassificationEntryArgs {
  readonly classificationId: number;
  readonly key: string;
  readonly trade: string;
  readonly value: string | number;
}

interface EntryResetState {
  readonly laborSourceId: string;
  readonly trade: keyof GetLaborSourceOptionsReturns["collection"];
}

interface Returns {
  getCrewMixEntry: (arg: string) => CrewMixEntry | undefined;
  resetCrewMixEntry: (arg: ResetEntryArgs) => void;
  setClassificationEntry: (arg: SetClassificationEntryArgs) => void;
  setCrewMixEntry: (arg: SetCrewMixEntryArgs) => void;
}

export function useCrewMixData(trades: Args): Returns {
  const [params] = useSearchParams();
  const [global, setGlobal] =
    React.useState<ReadonlyArray<CrewMixEntry>>(EMPTY_ARRAY);
  const [isToBeInitialized, setIsToBeInitialized] = React.useState(false);
  const [entryReset, setEntryReset] =
    React.useState<ReadonlyArray<EntryResetState>>(EMPTY_ARRAY);
  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();
  const selectedPackageId = params.get(PACKAGE_PARAM) ?? EMPTY_STRING;

  /* 
  load packages & package totals
  */
  const packagesQuery = api.endpoints.getPackages.useQuery(
    estimationId ?? EMPTY_STRING,
  );

  const { isLoaded: isPackagesLoaded, data: packages } = React.useMemo(() => {
    const isLoaded = packagesQuery.isSuccess || packagesQuery.isError;
    const data = packagesQuery.currentData?.collection;
    return { isLoaded, data };
  }, [
    packagesQuery.currentData?.collection,
    packagesQuery.isError,
    packagesQuery.isSuccess,
  ]);

  const packageTotalsQuery = api.endpoints.getPackageTotals.useQuery({
    packageId: selectedPackageId,
  });

  const selectedPackage = React.useMemo(() => {
    if (selectedPackageId != null && isPackagesLoaded) {
      return (
        packages?.find((entry) => entry.id.toString() === selectedPackageId) ??
        packages?.[0]
      );
    }
  }, [isPackagesLoaded, packages, selectedPackageId]);

  const { isLoaded: isPackageTotalsLoaded, data: packageTotals } =
    React.useMemo(() => {
      const isLoaded =
        packageTotalsQuery.isSuccess || packageTotalsQuery.isError;
      const data = packageTotalsQuery.currentData;
      return { isLoaded, data };
    }, [
      packageTotalsQuery.isSuccess,
      packageTotalsQuery.isError,
      packageTotalsQuery.currentData,
    ]);

  /* 
  load crew mixes
  */
  const crewMixIds = selectedPackage?.crew_mix_id ?? EMPTY_ARRAY;
  const crewMixQueries = crewMixIds?.map((crewMixId) =>
    api.endpoints.getCrewMix.useQuery(crewMixId),
  );

  const { isLoaded: isCrewMixesLoaded, data: crewMixes } = React.useMemo(() => {
    const isLoaded = crewMixQueries?.every(
      (queryResult) => queryResult.isSuccess || queryResult.isError,
    );

    // Package comes with crew mixes for every possible trade
    const data = crewMixQueries
      ?.map((queryResult) => queryResult.currentData?.crew_mix.data)
      .filter((crewMix) =>
        trades.find((trade) => trade === crewMix?.attributes.trade),
      )
      .filter((crewMix) => crewMix != null);

    return { isLoaded, data };
  }, [crewMixQueries, trades]);

  /* 
  load labor sources & labor sources options
  */
  const laborSourcesQuery = api.endpoints.getLaborSources.useQuery();
  const { isLoaded: isLaborSourcesLoaded, data: laborSources } =
    React.useMemo(() => {
      const isLoaded = laborSourcesQuery.isSuccess || laborSourcesQuery.isError;
      const data = laborSourcesQuery.currentData?.collection.data;
      return { isLoaded, data };
    }, [laborSourcesQuery]);

  const crewMixDefaultsQuery = api.endpoints.getLaborSourceOptions.useQuery();
  const { isLoaded: isCrewMixDefaultsLoaded, data: crewMixDefaults } =
    React.useMemo(() => {
      const isLoaded =
        crewMixDefaultsQuery.isSuccess || crewMixDefaultsQuery.isError;
      const data = crewMixDefaultsQuery.currentData?.collection ?? EMPTY_OBJECT;
      return { isLoaded, data };
    }, [crewMixDefaultsQuery]);

  React.useEffect(() => {
    if (
      isCrewMixesLoaded &&
      isLaborSourcesLoaded &&
      isPackagesLoaded &&
      isPackageTotalsLoaded &&
      isCrewMixDefaultsLoaded &&
      (laborSources ?? EMPTY_ARRAY).length > 0 &&
      Object.keys(crewMixDefaults).length > 0 &&
      (global == null || global === EMPTY_ARRAY)
    ) {
      setIsToBeInitialized(true);
    } else {
      setIsToBeInitialized(false);
    }
  }, [
    isCrewMixesLoaded,
    crewMixes,
    isLaborSourcesLoaded,
    laborSources,
    isPackagesLoaded,
    global,
    isPackageTotalsLoaded,
    isCrewMixDefaultsLoaded,
    crewMixDefaults,
  ]);

  /* 
  initialize global data
  */
  React.useEffect(() => {
    if (!isToBeInitialized) {
      return;
    }

    /* 
    create the top-level entry for each trade
    */
    const initialGlobal = trades
      .map((trade) => {
        const crewMix = crewMixes?.find(
          (crewMixEntry) => crewMixEntry.attributes.trade === trade,
        );

        const percentagesSum =
          crewMix?.attributes.crew_mix_percentages.reduce(
            (total, percentageEntry) => percentageEntry.percentage + total,
            0,
          ) ?? 0;

        const isPercentagesFaulty = Math.abs(percentagesSum - 1) >= 0.001;
        const selectedLaborSource = laborSources?.find(
          (laborSource) =>
            parseInt(laborSource.id) === crewMix?.attributes.labor_source.id,
        );

        /* 
        faulty if a wage on crew_mix_percentage is 0 but wage on labor_type is not
        */
        const isWagesFaulty =
          crewMix?.attributes.crew_mix_percentages.find((entry) => {
            const laborType = selectedLaborSource?.attributes.labor_types.find(
              (_laborType) => _laborType.id === entry.labor_type_id,
            );

            if (laborType == null) {
              return; // no labor_type data, leave crew_mix alone; don't reset
            }

            if (new Date(laborType.updated_at) > new Date(entry.updated_at)) {
              return; // labor_type has been updated recently, leave crew_mix alone; don't reset
            }

            const isWageBaseZero = entry.wage === 0;
            const isWageTotalZero = parseFloat(entry.wage_total) === 0;
            switch (entry.labor_type_time) {
              case LaborTypeTime.StraightTime:
                return (
                  (isWageTotalZero &&
                    parseFloat(laborType.straight_total) !== 0) ||
                  (isWageBaseZero && laborType.straight_time !== 0)
                );

              case LaborTypeTime.OverTime:
                return (
                  (isWageTotalZero && parseFloat(laborType.over_total) !== 0) ||
                  (isWageBaseZero && laborType.over_time !== 0)
                );

              case LaborTypeTime.DoubleTime:
                return (
                  (isWageTotalZero &&
                    parseFloat(laborType.double_total) !== 0) ||
                  (isWageBaseZero && laborType.double_time !== 0)
                );
              default:
                return true;
            }
          }) != null;

        if (
          crewMix == null ||
          crewMix.attributes.crew_mix_percentages.length === 0 ||
          isWagesFaulty ||
          isPercentagesFaulty
        ) {
          const laborCode =
            crewMixDefaults[
              trade as keyof GetLaborSourceOptionsReturns["collection"]
            ].labor_source;

          const laborSourceId =
            laborSources?.find(
              (laborSource) => laborSource.attributes.code === laborCode,
            )?.id ?? "1";

          setEntryReset((state) => [
            ...state,
            { laborSourceId, trade: trade as EntryResetState["trade"] },
          ]);
        }

        const crewMixPercentageData =
          crewMix?.attributes.crew_mix_percentages.map((percentageObject) => {
            return {
              crewMixPercentageId: percentageObject.id,
              laborTimeType: percentageObject.labor_type_time,
              laborTypeId: percentageObject.labor_type_id,
            };
          });

        const laborSourceId = crewMix?.attributes.labor_source.id;

        /* 
        create the entries for each classification
        */
        const classificationEntries = laborSources
          ?.find((laborSource) => parseInt(laborSource.id) === laborSourceId)
          ?.attributes.labor_types.map((classification) => {
            const straightTimeData =
              crewMix?.attributes.crew_mix_percentages.find(
                (crewMixPercentage) =>
                  crewMixPercentage.labor_type_time ===
                    LaborTypeTime.StraightTime &&
                  crewMixPercentage.labor_type_id === classification.id,
              );

            const overTimeData = crewMix?.attributes.crew_mix_percentages.find(
              (crewMixPercentage) =>
                crewMixPercentage.labor_type_time === LaborTypeTime.OverTime &&
                crewMixPercentage.labor_type_id === classification.id,
            );

            const doubleTimeData =
              crewMix?.attributes.crew_mix_percentages.find(
                (crewMixPercentage) =>
                  crewMixPercentage.labor_type_time ===
                    LaborTypeTime.DoubleTime &&
                  crewMixPercentage.labor_type_id === classification.id,
              );

            const crewPercentage =
              (straightTimeData?.percentage ?? 0) +
              (overTimeData?.percentage ?? 0) +
              (doubleTimeData?.percentage ?? 0);

            return {
              allottedHours:
                formatNumber(
                  parseFloat(
                    packageTotals?.total_hours_by_trade[
                      trade as keyof GetPackageTotalsReturns["total_hours_by_trade"]
                    ] ?? "0",
                  ) * crewPercentage,
                  0,
                  2,
                ) ?? 0,
              classificationId: classification.id,
              classificationName: classification.name,
              crewHeads: straightTimeData?.crew_heads ?? 0,
              crewPercentage: crewPercentage,
              // Convert percent of total labor hours to percent of crew mix hrs
              doubleTimePercentage:
                crewPercentage === 0
                  ? 0
                  : formatNumber(
                      (doubleTimeData?.percentage ?? 0) / crewPercentage,
                      0,
                      4,
                    ) ?? 0,
              doubleTimeWageBase: doubleTimeData?.wage ?? 0,
              doubleTimeWageTotal: parseFloat(
                doubleTimeData?.wage_total ?? "0",
              ),
              straightTimePercentage:
                crewPercentage === 0
                  ? 0
                  : formatNumber(
                      (straightTimeData?.percentage ?? 0) / crewPercentage,
                      0,
                      4,
                    ) ?? 0,
              straightTimeWageAdjusted:
                parseFloat(straightTimeData?.wage_total ?? "0") +
                (straightTimeData?.wage ?? 0) *
                  parseFloat(crewMix?.attributes.differential ?? "0"),
              straightTimeWageBase: straightTimeData?.wage ?? 0,
              straightTimeWageTotal: parseFloat(
                straightTimeData?.wage_total ?? "0",
              ),
              overTimePercentage:
                crewPercentage === 0
                  ? 0
                  : formatNumber(
                      (overTimeData?.percentage ?? 0) / crewPercentage,
                      0,
                      4,
                    ) ?? 0,
              overTimeWageBase: overTimeData?.wage ?? 0,
              overTimeWageTotal: parseFloat(overTimeData?.wage_total ?? "0"),
            };
          });

        return {
          classificationEntries: classificationEntries ?? EMPTY_ARRAY,
          compositeRate: crewMix?.attributes.weighted_average ?? 0,
          compositeEscalation: parseFloat(
            crewMix?.attributes.escalation ?? "0",
          ),
          compositeEscalationType:
            crewMix?.attributes.escalation_type ?? ESCALATION_TYPES.fixed,
          crewHeads: crewMix?.attributes.crew_heads ?? 0,
          crewMixId: crewMix?.id, // must allow this to be possibly nullish
          crewMixPercentageData: crewMixPercentageData ?? EMPTY_ARRAY,
          differential: parseFloat(crewMix?.attributes.differential ?? "0"),
          isCrewMixFaulty: false,
          laborSourceId: laborSourceId ?? 0,
          totalHours: parseFloat(
            packageTotals?.total_hours_by_trade[
              trade as keyof GetPackageTotalsReturns["total_hours_by_trade"]
            ] ?? "0",
          ),
          trade: trade,
        };
      })
      .filter((entry) => entry != null);

    setGlobal(initialGlobal);
  }, [
    crewMixDefaults,
    crewMixes,
    isToBeInitialized,
    laborSources,
    packageTotals?.total_hours_by_trade,
    trades,
  ]);

  const calcCompositeRate = React.useCallback(
    (crewMix: CrewMixEntry): number => {
      const totalLaborCost =
        crewMix.classificationEntries?.reduce((total, entry) => {
          return (
            total +
            entry.allottedHours *
              (entry.straightTimeWageAdjusted * entry.straightTimePercentage +
                entry.overTimeWageTotal * entry.overTimePercentage +
                entry.doubleTimeWageTotal * entry.doubleTimePercentage)
          );
        }, 0) ?? 0;

      const baseCompositeRate = totalLaborCost / crewMix.totalHours;
      return (
        formatNumber(
          crewMix.compositeEscalationType === ESCALATION_TYPES.percentage
            ? baseCompositeRate * (1 + crewMix.compositeEscalation)
            : baseCompositeRate + crewMix.compositeEscalation,
          0,
          2,
        ) ?? 0
      );
    },
    [],
  );

  const setCrewMixEntry = React.useCallback(
    ({ key, trade, value }: SetCrewMixEntryArgs): void => {
      setGlobal((state) =>
        state.map((crewMixEntry) => {
          if (crewMixEntry.trade === trade) {
            const updatedEntry = {
              ...crewMixEntry,
              [key]: value,
            };

            return {
              ...updatedEntry,
              compositeRate: calcCompositeRate(updatedEntry),
            };
          }
          return crewMixEntry;
        }),
      );
    },
    [calcCompositeRate],
  );

  const setClassificationEntry = React.useCallback(
    ({
      classificationId,
      key,
      trade,
      value,
    }: SetClassificationEntryArgs): void => {
      setGlobal((state) =>
        state.map((crewMixEntry) => {
          if (crewMixEntry.trade === trade) {
            const updatedEntry = {
              ...crewMixEntry,
              classificationEntries: crewMixEntry.classificationEntries?.map(
                (classificationEntry) => {
                  if (
                    classificationEntry.classificationId === classificationId
                  ) {
                    return {
                      ...classificationEntry,
                      [key]: value,
                    };
                  }
                  return classificationEntry;
                },
              ),
              compositeRate: calcCompositeRate(crewMixEntry),
            };

            return {
              ...updatedEntry,
              compositeRate: calcCompositeRate(updatedEntry),
            };
          }
          return crewMixEntry;
        }),
      );
    },
    [calcCompositeRate],
  );

  const resetCrewMixEntry = React.useCallback(
    ({ trade, laborSourceId }: ResetEntryArgs): void => {
      const laborSource = laborSources?.find(
        (laborSourceData) => laborSourceData.id === laborSourceId,
      );

      const crewMix = global.find(
        (crewMixEntry) => crewMixEntry.trade === trade,
      );

      const totalHours = crewMix?.totalHours;
      const crewMixDefault = crewMixDefaults[trade].distribution;
      const classificationEntries = laborSource?.attributes.labor_types.map(
        (laborType) => {
          /* 
          Get classification's default mix percentage
          */
          const crewPercentage = Object.keys(crewMixDefault)
            .map((key, index) => {
              if (laborType.name === key) {
                return Object.values(crewMixDefault)[index] as number;
              }
            })
            .filter((percentage) => percentage != null)[0];

          return {
            allottedHours:
              formatNumber((totalHours ?? 0) * (crewPercentage ?? 0), 0, 2) ??
              0,
            classificationId: laborType.id,
            classificationName: laborType.name,
            crewHeads: 0,
            crewPercentage: crewPercentage ?? 0,
            doubleTimePercentage: 0,
            doubleTimeWageBase: laborType.double_time,
            doubleTimeWageTotal: parseFloat(laborType.double_total),
            straightTimePercentage: (crewPercentage ?? 0) === 0 ? 0 : 1,
            straightTimeWageAdjusted: parseFloat(laborType.straight_total), // use 'total' since differential resetting to 0
            straightTimeWageBase: laborType.straight_time,
            straightTimeWageTotal: parseFloat(laborType.straight_total),
            overTimePercentage: 0,
            overTimeWageBase: laborType.over_time,
            overTimeWageTotal: parseFloat(laborType.over_total),
          };
        },
      );

      setGlobal((state) =>
        state.map((crewMixEntry) => {
          if (crewMixEntry.trade === trade) {
            return {
              ...crewMixEntry,
              compositeRate: calcCompositeRate(crewMixEntry),
              crewHeads: 0,
              differential: 0,
              laborSourceId: parseFloat(laborSourceId),
              classificationEntries: classificationEntries ?? EMPTY_ARRAY,
            };
          }
          return crewMixEntry;
        }),
      );
    },
    [calcCompositeRate, crewMixDefaults, global, laborSources],
  );

  const getCrewMixEntry = React.useCallback(
    (trade: string): CrewMixEntry | undefined => {
      return global.find((crewMixEntry) => crewMixEntry.trade === trade);
    },
    [global],
  );

  React.useEffect(() => {
    if (entryReset === EMPTY_ARRAY) {
      return;
    }

    entryReset.forEach((reset) => {
      resetCrewMixEntry({
        trade: reset.trade,
        laborSourceId: reset.laborSourceId,
      });

      setCrewMixEntry({
        key: CREW_MIX_ENTRY_KEYS.isCrewMixFaulty,
        trade: reset.trade,
        value: true,
      });
    });

    setEntryReset(EMPTY_ARRAY);
  }, [entryReset, resetCrewMixEntry, setCrewMixEntry]);

  return {
    getCrewMixEntry,
    resetCrewMixEntry,
    setClassificationEntry,
    setCrewMixEntry,
  };
}
