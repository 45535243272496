import { Menu, Tabs } from "@mantine/core";
import { IconCopy, IconEdit, IconTrash } from "@tabler/icons-react";
import { useCallback, useState } from "react";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import {
  useDeletePackageMutation,
  useUpdatePackageMutation,
} from "src/data/api/api";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import EditableInput from "./EditableInput";
import { CONTEXT_MENU_ICON_SIZE, PACKAGE_PARAM } from "./Table/constants";

interface Props {
  packageTab: PackageId;
}

const PackageTab = ({ packageTab }: Props) => {
  const [packageId] = useQueryStringParams(PACKAGE_PARAM);
  const { setAction, setSelectedPackages } = useSelectedLineItems();
  const [opened, setOpened] = useState(false);
  const [editable, setEditable] = useState(false);
  const [updatePackage] = useUpdatePackageMutation();
  const [deletePackage] = useDeletePackageMutation();

  const handleCopyPackage = useCallback(() => {
    setAction("copy");
    setSelectedPackages([Number(packageId)]);
  }, [packageId, setAction, setSelectedPackages]);

  const handleContextMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (packageId === packageTab.id.toString()) {
        e.preventDefault();

        setOpened(true);
      }
    },
    [packageId, packageTab.id],
  );

  const handleSubmit = useCallback(
    (title: string) => {
      updatePackage({
        id: packageTab.id.toString(),
        package: {
          title,
        },
      }).then(() => setEditable(false));
    },
    [packageTab.id, updatePackage],
  );

  const handleDeletePackage = useCallback(() => {
    if (packageId != null) {
      deletePackage(packageId);
    }
  }, [deletePackage, packageId]);

  const handleRename = useCallback(() => {
    setEditable(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handleBlur = useCallback(() => {
    setEditable(false);
  }, []);

  return (
    <Menu
      arrowPosition="center"
      closeOnItemClick
      onClose={handleClose}
      opened={opened}
      position="right-start"
      shadow="md"
      width={200}
    >
      <Menu.Target>
        <Tabs.Tab
          onContextMenu={handleContextMenu}
          value={packageTab.id.toString()}
        >
          <EditableInput
            isEditable={editable}
            onBlur={handleBlur}
            onSubmit={handleSubmit}
            value={packageTab.title || packageTab.id.toString()}
          />
        </Tabs.Tab>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconEdit size={CONTEXT_MENU_ICON_SIZE} />}
          onClick={handleRename}
        >
          Rename
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          leftSection={<IconCopy size={CONTEXT_MENU_ICON_SIZE} />}
          onClick={handleCopyPackage}
        >
          Copy
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          disabled={packageTab.title?.toLowerCase() === "base"}
          leftSection={<IconTrash size={CONTEXT_MENU_ICON_SIZE} />}
          onClick={handleDeletePackage}
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default PackageTab;
