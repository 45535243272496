import { ActionIcon, Group, Stack, Text } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Paths } from "src/constants/paths";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { useProfitCalculation } from "src/hooks/useProfitCalculation";
import { formatCurrency } from "src/utils/formatCurrency";
import { DISTRIBUTION_PARAM, PRICE_METHOD } from "../../constants";
import { EditIcon } from "../../EditIcon";
import CreateBundleButtons from "../CreateBundleButtons";
import styles from "./TotalSellPriceSection.module.scss";

interface Props {
  selectedPackage: PackageId;
}

const TotalSellPriceSection = React.memo<Props>(
  function _TotalSellPriceSection({ selectedPackage }) {
    const location = useLocation();
    const navigate = useNavigate();
    const profitCalculation = useProfitCalculation(
      selectedPackage.id.toString(),
    );
    const [searchParams] = useSearchParams();

    const navigateToMargin = React.useCallback(() => {
      if (profitCalculation?.distribution != null) {
        const distribution =
          profitCalculation.distribution === "package"
            ? "main"
            : profitCalculation.distribution;
        searchParams.set(DISTRIBUTION_PARAM, distribution);
      }

      if (profitCalculation?.formula != null) {
        searchParams.set(PRICE_METHOD, profitCalculation.formula);
      }

      navigate(
        location.pathname +
          `/${Paths.MARGINS}?` +
          new URLSearchParams(searchParams),
      );
    }, [
      location.pathname,
      navigate,
      profitCalculation?.distribution,
      profitCalculation?.formula,
      searchParams,
    ]);

    return (
      <Group className={styles.root}>
        <CreateBundleButtons packageId={selectedPackage.id.toString()} />
        <Group className={styles.costComponentsWrapper}>
          <Stack
            className={classNames(styles.calculatedCost, styles.costComponent)}
          >
            <span className={styles.title}>Total cost</span>
            <span className={styles.value}>
              {formatCurrency(
                Number(selectedPackage?.subtotal) +
                  Number(selectedPackage.other_expenses),
              )}
            </span>
          </Stack>
          <Text fw="bold" fz="h2">
            +
          </Text>
          <Stack
            className={classNames(styles.calculatedCost, styles.costComponent)}
          >
            <span className={styles.title}>Sales tax</span>
            <span className={styles.value}>
              {formatCurrency(Number(selectedPackage?.taxes))}
            </span>
          </Stack>
          <Text fw="bold" fz="h2">
            +
          </Text>
          <Stack className={classNames(styles.margin, styles.costComponent)}>
            <Group className={styles.marginComponent}>
              <span className={styles.title}>Margin</span>
              <ActionIcon
                onClick={navigateToMargin}
                style={{
                  minWidth: "16px",
                  minHeight: "16px",
                  width: "16px",
                  height: "16px",
                }}
                variant="transparent"
              >
                <EditIcon color="black" />
              </ActionIcon>
            </Group>
            <span className={classNames(styles.marginValue, styles.value)}>
              {formatCurrency(Number(selectedPackage?.margin))}
            </span>
          </Stack>
          <Text fw="bold" fz="h2">
            =
          </Text>
          <Stack className={classNames(styles.totalSell, styles.costComponent)}>
            <span className={styles.title}>Total sell price</span>
            <span className={styles.value}>
              {formatCurrency(Number(selectedPackage?.grand_total))}
            </span>
          </Stack>
        </Group>
      </Group>
    );
  },
);

export default TotalSellPriceSection;
