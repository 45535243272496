import React, { useCallback } from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";
import {
  UnitPosition,
  VALUE_CELL_UNIT_DISPLAYS,
  valueDisplayStyles,
} from "../constants";
import styles from "../EstimationColumnLabor.module.scss";
import { QuantityCell } from "../QuantityCell";
import { TextCell } from "../TextCell";
import { TradeCell } from "../TradeCell";
import { ValueDisplayCell } from "../ValueDisplayCell";

export function useEstimationColumnLabor(
  parentLineItem: BundleLineItem,
  isEditable: boolean,
  handleUpdate: (bundleId: number, lineItem: LineItem) => void,
) {
  const WIDTH = "150px";
  const WIDTH_DOUBLE = "180px";

  const renderQuantity = useCallback(
    (record: BundleLineItem) => (
      <QuantityCell
        column="labor"
        handleUpdate={handleUpdate}
        isEditable={isEditable}
        parent={parentLineItem}
        record={record}
      />
    ),
    [handleUpdate, isEditable, parentLineItem],
  );

  const renderTrades = useCallback(
    (record: BundleLineItem) => {
      const updateTrade = (bundleId: number, lineItem: LineItem) => {
        const newLineItem: LineItem = {
          ...lineItem,
          id: record.id > 0 ? record.id : undefined,
          cost_type: record.cost_type,
          parent_id: record.parent_id,
          package_id: record.package_id,
          display_attributes: {
            display: "inline",
          },
        };

        handleUpdate(bundleId, newLineItem);
      };

      return (
        <TradeCell
          key={record.trade}
          handleUpdate={updateTrade}
          isEditable={isEditable}
          record={record}
        />
      );
    },
    [handleUpdate, isEditable],
  );

  return React.useMemo(() => {
    return [
      {
        accessor: "trade",
        cellsClassName: styles.column,
        title: "Trade",
        width: WIDTH,
        render: renderTrades,
      },
      {
        accessor: "description",
        cellsClassName: styles.column,
        title: "Description",
        width: WIDTH_DOUBLE,
        render: (v: BundleLineItem) => {
          const handleDescription = (value: string) => {
            handleUpdate(v.bundle_id, {
              id: v.id > 0 ? v.id : undefined,
              description: value,
              parent_id: v.parent_id,
              package_id: v.package_id,
              display_attributes: {
                display: "inline",
              },
            });
          };

          return (
            <TextCell
              crewMixId={v.crew_mix_id}
              disabled={!isEditable}
              handleUpdate={handleDescription}
              value={v.description ?? EMPTY_STRING}
            />
          );
        },
      },
      {
        accessor: "hours",
        cellsClassName: styles.column,
        title: "Hours",
        width: "130px",
        render: renderQuantity,
      },
      {
        accessor: "subtotal",
        cellsClassName: styles.column,
        title: "Subtotal",
        width: "130px",
        render: (v: BundleLineItem) => {
          if (v.subtotal != null) {
            return (
              <ValueDisplayCell
                displayClassName={valueDisplayStyles}
                maxPrecision={2}
                minPrecision={2}
                unitDisplay={VALUE_CELL_UNIT_DISPLAYS.DOLLAR}
                unitPosition={UnitPosition.Prefix}
                value={Number(v.subtotal)}
              />
            );
          }
        },
      },
    ];
  }, [handleUpdate, isEditable, renderQuantity, renderTrades]);
}
