import { EMPTY_STRING } from "./empty";

export function formatCurrency(
  value: number | undefined,
  minDecimalPlaces: number = 0,
  maxDecimalPlaces: number = 0,
) {
  if (value == null) {
    return EMPTY_STRING;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits:
      minDecimalPlaces > maxDecimalPlaces ? maxDecimalPlaces : minDecimalPlaces,
    maximumFractionDigits: maxDecimalPlaces,
  }).format(value);
}
