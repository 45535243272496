import { ActionIcon, Modal } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { Attachment } from "src/data/api/types/shared/attachment";
import styles from "./AttachmentViewer.module.scss";

interface Props {
  readonly attachment: Attachment;
  readonly onClose: () => void;
  readonly onDownload: () => void;
}

export const AttachmentViewer = React.memo<Props>(function _AttachmentViewer({
  attachment,
  onClose,
  onDownload,
}) {
  const { isImage, isPdf } = React.useMemo(() => {
    return {
      isImage: ["image/png", "image/jpeg"].includes(attachment.content_type),
      isPdf: attachment.content_type === "application/pdf",
    };
  }, [attachment.content_type]);

  return (
    <Modal.Root
      closeOnClickOutside={false}
      fullScreen={true}
      onClose={onClose}
      opened={true}
      zIndex={9999}
    >
      <Modal.Overlay>
        <Modal.Content className={styles.content}>
          <Modal.Header className={styles.header}>
            {!isPdf ? (
              <ActionIcon
                className={styles.downloadIcon}
                onClick={onDownload}
                variant="subtle"
              >
                <IconDownload size={20} />
              </ActionIcon>
            ) : null}
            <Modal.CloseButton className={styles.closeIcon} />
          </Modal.Header>

          <div
            className={classNames(
              styles.previewWrapper,
              isImage ? styles.imageWrapper : null,
            )}
          >
            {isPdf ? (
              <iframe
                className={styles.previewPdf}
                src={attachment.url}
              ></iframe>
            ) : null}

            {isImage ? (
              <img className={styles.previewImage} src={attachment.url} />
            ) : null}
          </div>
        </Modal.Content>
      </Modal.Overlay>
    </Modal.Root>
  );
});
