import { createContext, useMemo, useState } from "react";

interface ContextType {
  expandableGroups: number[];
  setExpandableGroups: React.Dispatch<React.SetStateAction<number[]>>;
  expandableItems: number[];
  setExpandedItems: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ExpandableLineItemsContext = createContext<ContextType>({
  expandableItems: [],
  setExpandedItems: () => {},
  expandableGroups: [],
  setExpandableGroups: () => {},
});

interface Props {
  children: React.ReactNode;
  initialItems: number[];
  initialGroups: number[];
}

function ExpandableLineItemsProvider({
  children,
  initialGroups,
  initialItems,
}: Props) {
  const [expandableItems, setExpandedItems] = useState<number[]>(initialItems);
  const [expandableGroups, setExpandableGroups] =
    useState<number[]>(initialGroups);

  const value = useMemo(() => {
    return {
      expandableItems,
      setExpandedItems,
      expandableGroups,
      setExpandableGroups,
    };
  }, [expandableGroups, expandableItems]);

  return (
    <ExpandableLineItemsContext.Provider value={value}>
      {children}
    </ExpandableLineItemsContext.Provider>
  );
}

export default ExpandableLineItemsProvider;
