import { Group, Loader, Stack, Text } from "@mantine/core";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { MatchParam } from "src/constants/matches";
import { Paths } from "src/constants/paths";
import { PieChartDollar } from "../../icons/PieChartDollar";
import { BackToEstimationViewButton } from "../Frames/BackToEstimationViewButton";
import EstimationSummary from "./components/EstimationSummary";

const EstimationSummaryView = memo(() => {
  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();

  if (estimationId == null) {
    return <Loader />;
  }

  return (
    <Stack h="100%" p="sm" w="100%">
      <BackToEstimationViewButton view={Paths.SUMMARY} />
      <Group align="center">
        <PieChartDollar size={24} />
        <Text fz="h3">Summary</Text>
      </Group>
      <EstimationSummary estimationId={estimationId} />
    </Stack>
  );
});

export default EstimationSummaryView;
