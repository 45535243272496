import { Flex } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { useExpandableLineItems } from "src/context/ExpandableLineItemsProvider";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";
import { isTruthy } from "src/utils/isTruthy";
import { DROPDOWN_PLACEHOLDER, DropdownType } from "./constants";
import styles from "./CostTypeCell.module.scss";
import { DropdownCell } from "./DropdownCell";
import { ExpandIcon } from "./ExpandIcon";
import { useCostTypesOptions } from "./hooks/useCostTypesOptions";

interface Props {
  isEditable?: boolean;
  record: BundleLineItem;
  handleUpdate: (bundleId: number, lineItem: LineItem) => void;
}

export const CostTypeCell = React.memo<Props>(function _CostTypeCell({
  record,
  handleUpdate,
  isEditable = true,
}) {
  const { displayClassName, getOptions, getCostType } = useCostTypesOptions();
  const { expandableItems, setExpandedItems } = useExpandableLineItems();

  const updateExpandable = React.useCallback(() => {
    let newExpandables = expandableItems.slice();

    if (expandableItems.includes(record.id)) {
      newExpandables = newExpandables.filter((item) => item !== record.id);
    } else {
      newExpandables = newExpandables.concat(record.id);
    }

    setExpandedItems(newExpandables);
  }, [expandableItems, record.id, setExpandedItems]);

  const updateCostType = React.useCallback(
    (value: string, category?: string | null) => {
      const costType = getCostType(value);
      const defaultUnit = costType?.units.find((unit) => unit.default)?.value;
      const defaultTrade = costType?.trades.find(
        (trade) => trade.default,
      )?.value;

      let options: LineItem = {
        id: record.id,
        cost_type: value,
        category,
        quantity_value: ["fixed", "calculated"].includes(
          defaultUnit ?? EMPTY_STRING,
        )
          ? "1"
          : undefined,
        quantity_unit: defaultUnit,
        trade: defaultTrade,
      };

      if (isTruthy(costType?.other_expense) && isTruthy(costType.default)) {
        options = {
          ...options,
          calculate_cost: true,
          calculation_percentage: costType.default,
        };
      }

      handleUpdate(record.bundle_id, options);
    },
    [getCostType, handleUpdate, record.bundle_id, record.id],
  );

  const options = React.useMemo(() => {
    const currentOptions = getOptions(record.cost_type);

    if (record.parent_id != null) {
      return currentOptions.filter((option) =>
        ["material", "equipment", record.cost_type].includes(option.value),
      );
    }

    return currentOptions;
  }, [getOptions, record.cost_type, record.parent_id]);

  const isExpandable = React.useMemo(() => {
    return (
      record.parent_id == null &&
      record.children?.length > 0 &&
      record.children?.filter(
        (child) => child.display_attributes?.display !== "inline",
      ).length > 0
    );
  }, [record.children, record.parent_id]);

  return (
    <Flex
      className={classNames(
        styles.root,
        record.parent_id != null ? styles.childItem : null,
      )}
    >
      {isExpandable ? (
        <ExpandIcon
          isExpanded={expandableItems.includes(record.id)}
          onClick={updateExpandable}
        />
      ) : null}

      <DropdownCell
        category={record.category}
        disabled={!isEditable}
        displayClassName={displayClassName}
        handleUpdate={updateCostType}
        options={options}
        placeholders={DROPDOWN_PLACEHOLDER[DropdownType.CostType]}
        value={record.cost_type}
      />
    </Flex>
  );
});
