import { useCallback, useMemo } from "react";
import { useScope } from "src/context/ScopeProvider/useScope";
import { useGetLineItemOptionsQuery } from "src/data/api/api";
import { getDropdownOptions } from "../../getDropdownOptions";
import { DropdownType } from "../constants";

export const useCostTypesOptions = () => {
  const target = useScope();
  const { data } = useGetLineItemOptionsQuery(target);
  const { displayClassName, options } = getDropdownOptions(
    DropdownType.CostType,
  );

  const costTypes = useMemo(() => {
    if (data == null) {
      return [];
    }

    return Object.keys(data.collection.cost_types).filter(
      (key) => data.collection.cost_types[key]?.selectable === true,
    );
  }, [data]);

  const getCostType = useCallback(
    (name: string) => {
      return data?.collection.cost_types[name];
    },
    [data?.collection.cost_types],
  );

  const getOptions = useCallback(
    (currentValue: string | null) => {
      const selectableCostTypes = costTypes.slice();

      if (currentValue != null) {
        selectableCostTypes.push(currentValue);
      }

      return (
        options[0]?.options.filter((option) =>
          selectableCostTypes.includes(option.value),
        ) ?? []
      ).map((option1) => ({
        ...option1,
        categories: getCostType(option1.value)?.categories ?? [],
      }));
    },
    [costTypes, getCostType, options],
  );

  return { displayClassName, getOptions, getCostType };
};
