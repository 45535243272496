import React from "react";
import { useCreateLineItemMutation } from "src/data/api/api";

interface Returns {
  createGroupBundle: () => void;
  createLineItemBundle: () => void;
}

export function useCreateBundle(packageId: string | null): Returns {
  const [createBundle] = useCreateLineItemMutation();
  const createGroupBundle = React.useCallback(() => {
    if (packageId != null) {
      createBundle({
        bundle: {
          package_id: packageId,
          bundle_type: "group",
        },
      });
    }
  }, [createBundle, packageId]);

  const createLineItemBundle = React.useCallback(() => {
    if (packageId != null) {
      createBundle({
        bundle: {
          package_id: packageId,
          bundle_type: "line_item",
        },
      });
    }
  }, [createBundle, packageId]);

  return { createGroupBundle, createLineItemBundle };
}
