import { Button } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useCallback } from "react";

interface Props {
  title: string;
  value: string;
  isSelected: boolean;
  onClick: (v: string) => void;
}

const MarginButton = ({ value, title, isSelected, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <Button
      color={isSelected ? undefined : "#36454F"}
      leftSection={isSelected ? <IconCheck size="16px" /> : undefined}
      onClick={handleClick}
      variant={isSelected ? "filled" : "outline"}
    >
      {title}
    </Button>
  );
};

export default MarginButton;
