import { createContext, useMemo, useState } from "react";

interface ContextType {
  isEditable: boolean;
  setIsEditable: (b: boolean) => void;
}

export const EditableContext = createContext<ContextType>({
  isEditable: false,
  setIsEditable: () => {},
});

interface Props {
  children: React.ReactNode;
}

function EditableProvider({ children }: Props) {
  const [isEditable, setIsEditable] = useState(false);

  const value = useMemo(() => {
    return { isEditable, setIsEditable };
  }, [isEditable]);

  return (
    <EditableContext.Provider value={value}>
      {children}
    </EditableContext.Provider>
  );
}

export default EditableProvider;
