import { Text } from "@mantine/core";
import { useMemo } from "react";
import { PRICE_METHOD } from "src/components/EstimationView/Table/constants";
import { CostCell } from "src/components/Frames/Table/CostCell";
import { LaborHoursCell } from "src/components/Frames/Table/LaborHoursCell";
import { MarginCell } from "src/components/Frames/Table/MarginCell";
import { MarginHealthCell } from "src/components/Frames/Table/MarginHealthCell";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { formatCurrency } from "src/utils/formatCurrency";

export const useMainColumns = () => {
  const [priceMethod] = useQueryStringParams(PRICE_METHOD);

  return useMemo(() => {
    return [
      {
        accessor: "package",
        title: "PACKAGE",
        render: (v: PackageTotal) => <div>{v.name}</div>,
      },
      {
        accessor: "labor_hrs",
        title: "LABOR HOURS",
        render: (v: PackageTotal) => <LaborHoursCell record={v} />,
      },
      {
        accessor: "cost",
        title: "COST",
        render: (v: PackageTotal) => <CostCell record={v} />,
      },
      {
        accessor: "margin",
        title: priceMethod === "markup" ? "MARKUP" : "MARGIN",
        render: (v: PackageTotal) => <MarginCell record={v} />,
      },
      {
        accessor: "sell_price",
        title: "SELL PRICE",
        render: (v: PackageTotal) => (
          <Text fz="sm">{formatCurrency(Number(v.grand_total))}</Text>
        ),
      },
      {
        accessor: "margin_health",
        title: priceMethod === "markup" ? "MARKUP HEALTH" : "MARGIN HEALTH",
        render: (v: PackageTotal) => <MarginHealthCell record={v} />,
      },
    ];
  }, [priceMethod]);
};
