import { Button, Group, Modal, Tabs, Tooltip } from "@mantine/core";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FormFooter } from "src/components/Frames/FormFooter";
import { LoadingScreen } from "src/components/Frames/LoadingScreen";
import { MatchParam } from "src/constants/matches";
import { api } from "src/data/api/api";
import {
  AddCrewMixArgs,
  ClassificationLaborAttributes,
} from "src/data/api/types/addCrewMix";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { LoadedCrewMix } from "src/data/api/types/shared/crewMix";
import { UpdateCrewMixArgs } from "src/data/api/types/updateCrewMix";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { capitalize } from "src/utils/capitalize";
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from "src/utils/empty";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import { PACKAGE_PARAM } from "../Table/constants";
import { PackageCrewMixMenu } from "./PackageCrewMixMenu";
import styles from "./SetCrewMixDialog.module.scss";
import { CrewMixEntry, useCrewMixData } from "./useCrewMixData";
import { EstimationResourceType, LaborTypeTime } from "./util/utils";
import { formatCrewMixPercentage } from "src/utils/formatCrewMixPercentage";

interface Props {
  readonly onClose: () => void;
}

export const SetCrewMixDialog = React.memo<Props>(function _SetCrewMixDialog({
  onClose,
}) {
  const [params] = useSearchParams();
  const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
  const [isNoData, setIsNoData] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<string | null>("0");
  const [isSaveLoading, setIsSaveLoading] = React.useState<boolean>(false);
  const [isSaveStarted, setIsSaveStarted] = React.useState<boolean>(false);
  const [isInputValid, setIsInputValid] = React.useState<boolean>(true);
  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();
  const selectedPackageId = params.get(PACKAGE_PARAM) ?? EMPTY_STRING;

  const [addCrewMix, addMutationState] = api.endpoints.addCrewMix.useMutation();

  const [deleteCrewMix, deleteMutationState] =
    api.endpoints.deleteCrewMix.useMutation();

  const [updateCrewMix, updateMutationState] =
    api.endpoints.updateCrewMix.useMutation();

  /* 
  track save start/loading state
  */
  React.useEffect(() => {
    if (
      addMutationState.isLoading ||
      deleteMutationState.isLoading ||
      updateMutationState.isLoading
    ) {
      setIsSaveLoading(true);
    } else {
      setIsSaveLoading(false);
    }

    if (
      !addMutationState.isUninitialized ||
      !deleteMutationState.isUninitialized ||
      !updateMutationState.isUninitialized
    ) {
      setIsSaveStarted(true);
    } else {
      setIsSaveStarted(false);
    }
  }, [addMutationState, deleteMutationState, updateMutationState]);

  React.useEffect(() => {
    if (isSaveStarted && !isSaveLoading) {
      onClose();
    }
  }, [isSaveLoading, isSaveStarted, onClose]);

  /* 
  load packages and package totals
  */
  const packagesQuery = api.endpoints.getPackages.useQuery(
    estimationId ?? EMPTY_STRING,
  );

  const { isLoaded: isPackagesLoaded, data: packages } = React.useMemo(() => {
    const isLoaded = packagesQuery.isSuccess || packagesQuery.isError;
    const data = packagesQuery.currentData?.collection;
    return { isLoaded, data };
  }, [packagesQuery]);

  const packageTotalsQuery = api.endpoints.getPackageTotals.useQuery({
    packageId: selectedPackageId,
  });

  const { isLoaded: isPackageTotalsLoaded, data: packageTotals } =
    React.useMemo(() => {
      const isLoaded =
        packageTotalsQuery.isSuccess || packageTotalsQuery.isError;
      const data = packageTotalsQuery.currentData;
      return { isLoaded, data };
    }, [
      packageTotalsQuery.currentData,
      packageTotalsQuery.isError,
      packageTotalsQuery.isSuccess,
    ]);

  const selectedPackage = React.useMemo(() => {
    if (selectedPackageId != null && isPackagesLoaded) {
      return (
        packages?.find((entry) => entry.id.toString() === selectedPackageId) ??
        packages?.[0]
      );
    }
  }, [isPackagesLoaded, packages, selectedPackageId]);

  /* 
  load crew mixes
  */
  const crewMixIds = selectedPackage?.crew_mix_id;
  const crewMixQueries = crewMixIds?.map((id) =>
    api.endpoints.getCrewMix.useQuery(id),
  );

  const { isLoaded: isCrewMixesOnPackageLoaded, data: crewMixesOnPackage } =
    React.useMemo(() => {
      const isLoaded =
        crewMixQueries?.filter((query) => query.isSuccess || query.isError)
          .length === (crewMixIds?.length ?? 0);

      const data = crewMixQueries
        ?.map((queryResult) => queryResult.currentData?.crew_mix.data)
        .filter((crewMix) => crewMix != null);

      return { isLoaded, data };
    }, [crewMixIds, crewMixQueries]);

  const tradesData = React.useMemo(() => {
    if (isPackagesLoaded) {
      const tradesKeys = Object.keys(
        packageTotals?.total_hours_by_trade ?? EMPTY_OBJECT,
      );

      const tradesHours = Object.values(
        packageTotals?.total_hours_by_trade ?? EMPTY_OBJECT,
      );

      const nonZeroIndices = tradesHours
        .map((trade, index) => (parseFloat(trade) > 0 ? index : -1))
        .filter((index) => index !== -1);

      const count = nonZeroIndices.length;
      const list: ReadonlyArray<string> = nonZeroIndices
        .map((index) => tradesKeys[index])
        .filter((key) => key != null)
        .sort((a, b) => a.localeCompare(b));

      const info = list.map((trade) => ({
        label: capitalize(trade),
        value: trade,
      }));
      return { list, info, count };
    }
  }, [isPackagesLoaded, packageTotals?.total_hours_by_trade]);

  /* 
  load labor sources & labor source options
  */
  const laborSourcesQuery = api.endpoints.getLaborSources.useQuery();
  const { isLoaded: isLaborSourcesLoaded, data: laborSources } =
    React.useMemo(() => {
      const isLoaded = laborSourcesQuery.isSuccess || laborSourcesQuery.isError;
      const data = laborSourcesQuery.currentData?.collection.data;
      return { isLoaded, data };
    }, [laborSourcesQuery]);

  const crewMixDefaultsQuery = api.endpoints.getLaborSourceOptions.useQuery();
  const { isLoaded: isCrewMixDefaultsLoaded, data: crewMixDefaults } =
    React.useMemo(() => {
      const isLoaded =
        crewMixDefaultsQuery.isSuccess || crewMixDefaultsQuery.isError;
      const data = crewMixDefaultsQuery.currentData?.collection ?? EMPTY_OBJECT;
      return { isLoaded, data };
    }, [crewMixDefaultsQuery]);

  const {
    getCrewMixEntry,
    setClassificationEntry,
    setCrewMixEntry,
    resetCrewMixEntry,
  } = useCrewMixData(tradesData?.list ?? EMPTY_ARRAY);

  React.useEffect(() => {
    if (
      isCrewMixesOnPackageLoaded &&
      isPackagesLoaded &&
      isPackageTotalsLoaded &&
      isLaborSourcesLoaded &&
      isCrewMixDefaultsLoaded &&
      tradesData?.list.length === tradesData?.count
    ) {
      setIsDataLoaded(true);
    }

    if (
      isDataLoaded &&
      (tradesData == null ||
        tradesData.list.length === 0 ||
        laborSources?.length === 0 ||
        Object.keys(crewMixDefaults).length === 0)
    ) {
      setIsNoData(true);
    }
  }, [
    crewMixDefaults,
    isCrewMixDefaultsLoaded,
    isCrewMixesOnPackageLoaded,
    isDataLoaded,
    isLaborSourcesLoaded,
    isPackageTotalsLoaded,
    isPackagesLoaded,
    laborSources,
    packageTotals,
    tradesData,
  ]);

  React.useEffect(() => {
    const global = tradesData?.list
      .map((trade) => getCrewMixEntry(trade))
      .filter((entry) => entry != null);

    if (global == null) {
      return;
    }

    const isCrewPercentageInvalid = global.some(
      (entry) =>
        formatNumberHundredths(
          entry.classificationEntries?.reduce(
            (total, _entry) => total + _entry.crewPercentage,
            0,
          ) ?? 0,
        ) !== 1,
    );

    const isHoursAllocationInvalid = global.some((entry) =>
      entry.classificationEntries?.some(
        (_entry) => formatNumberHundredths(_entry.straightTimePercentage) < 0,
      ),
    );

    setIsInputValid(!isCrewPercentageInvalid && !isHoursAllocationInvalid);
  }, [getCrewMixEntry, tradesData?.list]);

  const handleDialogSubmit = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      const global = tradesData?.list
        .map((trade) => getCrewMixEntry(trade))
        .filter((entry) => entry != null);

      if (global == null || !isInputValid) {
        event.preventDefault();
        return;
      }

      await Promise.allSettled(
        global?.map(async (crewMixEntry) => {
          savePackageCrewMixes({
            addCrewMix: async (args: AddCrewMixArgs) => {
              addCrewMix(args);
            },
            crewMixEntry,
            crewMixesOnPackage,
            deleteCrewMix: async (arg: number) => {
              deleteCrewMix(arg);
            },
            updateCrewMix: async (args: UpdateCrewMixArgs) => {
              updateCrewMix(args);
            },
            selectedPackage,
          });
        }),
      );
    },
    [
      addCrewMix,
      crewMixesOnPackage,
      deleteCrewMix,
      getCrewMixEntry,
      isInputValid,
      selectedPackage,
      tradesData?.list,
      updateCrewMix,
    ],
  );

  const zeroDataState = (
    <Group className={styles.zeroDataRoot}>
      <div className={styles.zeroDataTabs}>TRADES</div>
      <div className={styles.zeroDataPanel}>
        <div className={styles.zeroDataHeading}>
          No labor data present in the estimation table.
        </div>
        Please add your expected labor to your estimation table before setting
        up your crew mix
      </div>
    </Group>
  );

  return (
    <Modal.Root
      centered={true}
      className={styles.root}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={onClose}
      opened={true}
      size={950}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header className={styles.header}>
          <Modal.Title className={styles.title}>
            Crew mix -{" "}
            <span className={styles.packageName}>{selectedPackage?.title}</span>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Body className={styles.body}>
          {!isDataLoaded ? (
            <div className={styles.panel}>
              <LoadingScreen loadingScreenType={LoadingScreenType.CrewMix} />
            </div>
          ) : isNoData ? (
            zeroDataState
          ) : (
            <Tabs
              className={styles.tabs}
              defaultValue={"0"}
              onChange={setActiveTab}
              orientation="vertical"
              radius={0}
              variant="pills"
            >
              <Tabs.List className={styles.tabsList}>
                {tradesData?.info.map((trade, index) => (
                  <Tabs.Tab
                    key={trade.label}
                    className={styles.tabItem}
                    value={index.toString()}
                  >
                    {trade.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              {tradesData?.info.map((trade, index) => {
                return (
                  <Tabs.Panel
                    key={trade.label}
                    className={styles.panel}
                    value={index.toString()}
                  >
                    <PackageCrewMixMenu
                      getCrewMixEntry={getCrewMixEntry}
                      isTabActive={activeTab === index.toString()}
                      resetCrewMixEntry={resetCrewMixEntry}
                      setClassificationEntry={setClassificationEntry}
                      setCrewMixEntry={setCrewMixEntry}
                      trade={trade}
                    />
                  </Tabs.Panel>
                );
              })}
            </Tabs>
          )}
          <FormFooter
            className={styles.footer}
            leftSection={<div></div>}
            rightSection={
              <Group gap={10}>
                <Button
                  className={styles.buttons}
                  onClick={onClose}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Tooltip
                  className={styles.tooltip}
                  disabled={isInputValid}
                  label="Please address validation errors on all trade tabs"
                  multiline={true}
                  offset={{ mainAxis: 5, crossAxis: 5 }}
                  position="top-end"
                  w={250}
                >
                  <Button
                    className={styles.buttons}
                    data-disabled={isNoData || !isInputValid}
                    loading={isSaveStarted && isSaveLoading}
                    onClick={handleDialogSubmit}
                  >
                    Save
                  </Button>
                </Tooltip>
              </Group>
            }
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
});

interface Args {
  readonly addCrewMix: (arg: AddCrewMixArgs) => Promise<void>;
  readonly crewMixEntry: CrewMixEntry;
  readonly crewMixesOnPackage: LoadedCrewMix[] | undefined;
  readonly deleteCrewMix: (arg: number) => Promise<void>;
  readonly selectedPackage: PackageId | undefined;
  readonly updateCrewMix: (arg: UpdateCrewMixArgs) => Promise<void>;
}

async function savePackageCrewMixes({
  addCrewMix,
  crewMixEntry,
  crewMixesOnPackage,
  deleteCrewMix,
  selectedPackage,
  updateCrewMix,
}: Args) {
  try {
    const existingCrewMix = crewMixesOnPackage?.find(
      (crewMix) => crewMix?.id === crewMixEntry.crewMixId,
    );

    const isUnionChanged =
      crewMixEntry.laborSourceId !==
      existingCrewMix?.attributes.labor_source.id;

    if (
      crewMixEntry.crewMixId != null &&
      (isUnionChanged || crewMixEntry.isCrewMixFaulty)
    ) {
      // Redundant nullish check since TS can't tell that it's non-nullish
      if (crewMixEntry.crewMixId == null) {
        return;
      }

      await deleteCrewMix(parseInt(crewMixEntry.crewMixId));
    }

    if (
      crewMixEntry.crewMixId == null ||
      isUnionChanged ||
      crewMixEntry.isCrewMixFaulty
    ) {
      if (selectedPackage == null) {
        return;
      }

      const classificationPercentages = crewMixEntry.classificationEntries?.map(
        (classificationEntry): Array<ClassificationLaborAttributes> => {
          const crewPercentage = classificationEntry.crewPercentage;
          return [
            {
              crew_heads: classificationEntry.crewHeads,
              labor_type_id: classificationEntry.classificationId,
              labor_type_time: LaborTypeTime.StraightTime,
              labor_type_percentage: classificationEntry.crewPercentage,
              percentage: formatCrewMixPercentage(
                classificationEntry.straightTimePercentage * crewPercentage
              ),
              wage: classificationEntry.straightTimeWageBase,
              wage_total: classificationEntry.straightTimeWageTotal,
            },
            {
              crew_heads: 0,
              labor_type_id: classificationEntry.classificationId,
              labor_type_time: LaborTypeTime.OverTime,
              labor_type_percentage: 0,
              percentage: formatCrewMixPercentage(
                classificationEntry.overTimePercentage * crewPercentage
              ),
              wage: classificationEntry.overTimeWageBase,
              wage_total: classificationEntry.overTimeWageTotal,
            },
            {
              crew_heads: 0,
              labor_type_id: classificationEntry.classificationId,
              labor_type_time: LaborTypeTime.DoubleTime,
              labor_type_percentage: 0,
              percentage: formatCrewMixPercentage(
                classificationEntry.doubleTimePercentage * crewPercentage
              ),
              wage: classificationEntry.doubleTimeWageBase,
              wage_total: classificationEntry.doubleTimeWageTotal,
            },
          ];
        },
      );

      const percentagesAttributes = classificationPercentages?.flat();

      await addCrewMix({
        crew_heads: crewMixEntry.crewHeads,
        labor_source_id: crewMixEntry.laborSourceId,
        package_id: selectedPackage.id,
        resource_id: selectedPackage.id,
        resource_type: EstimationResourceType.Package,
        trade: crewMixEntry.trade,
        differential: crewMixEntry.differential,
        escalation: crewMixEntry.compositeEscalation,
        escalation_type: crewMixEntry.compositeEscalationType,
        crew_mix_percentages_attributes: percentagesAttributes ?? EMPTY_ARRAY,
      });
    } else if (!isUnionChanged) {
      const percentagesAttributes = crewMixEntry.crewMixPercentageData
        .map((percentageEntry) => {
          const classificationEntry = crewMixEntry.classificationEntries?.find(
            (entry) => entry.classificationId === percentageEntry.laborTypeId,
          );

          if (classificationEntry == null) {
            return undefined;
          }

          const crewPercentage = classificationEntry.crewPercentage;
          switch (percentageEntry.laborTimeType) {
            case LaborTypeTime.StraightTime:
              return {
                crew_heads: classificationEntry.crewHeads,
                id: percentageEntry.crewMixPercentageId,
                labor_type_percentage: classificationEntry.crewPercentage,
                percentage: formatCrewMixPercentage(
                  classificationEntry.straightTimePercentage * crewPercentage
                ),
                wage: classificationEntry.straightTimeWageBase,
                wage_total: classificationEntry.straightTimeWageTotal,
              };

            case LaborTypeTime.OverTime:
              return {
                crew_heads: 0,
                id: percentageEntry.crewMixPercentageId,
                percentage: formatCrewMixPercentage(
                  classificationEntry.overTimePercentage * crewPercentage
                ),
              };

            case LaborTypeTime.DoubleTime:
              return {
                crew_heads: 0,
                id: percentageEntry.crewMixPercentageId,
                percentage: formatCrewMixPercentage(
                  classificationEntry.doubleTimePercentage * crewPercentage
                ),
              };
          }
        })
        .filter((entry) => entry != null);

      await updateCrewMix({
        crew_heads: crewMixEntry.crewHeads,
        crew_mix_id: parseInt(crewMixEntry.crewMixId),
        differential: crewMixEntry.differential,
        escalation: crewMixEntry.compositeEscalation,
        escalation_type: crewMixEntry.compositeEscalationType,
        crew_mix_percentages_attributes: percentagesAttributes,
      });
    }
  } catch {
    // TODO: add error handling
  }
}
