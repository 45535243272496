import { LabelValue } from "src/types/util/labelValue";

const buildType = (label: string, method: string, level: string) => ({
  label,
  value: label
    .split(" ")
    .map((string) => string.toLowerCase())
    .join("_"),
  level,
  method,
});

export type MarginInputType = LabelValue & {
  level: string;
  method: string;
};

export const applicationLevelButtons: LabelValue[] = [
  { label: "Main", value: "main" },
  { label: "By Cost type", value: "cost_type" },
  { label: "By Trade", value: "trade" },
];

export const priceMethodButtons: LabelValue[] = [
  {
    label: "Margin",
    value: "margin",
  },
  {
    label: "Markup",
    value: "markup",
  },
];

const trades = [
  "Quoted Items",
  "Sheet Metal Fabrication",
  "Sheet metal",
  "Piping",
  "Control",
  "Start Up",
  "Plumbing",
  "Service",
  "Subcontracts",
  "Engineering",
  "Warehouse",
  "Project Management",
  "Other Expenses",
  "Quote",
];

const costTypes = [
  "Equipment",
  "Material",
  "Labor",
  "Subcontractor",
  "Engineering",
];

const markupMainLevel: MarginInputType[] = [
  {
    label: "Enter markup %",
    value: "package",
    level: "main",
    method: "markup",
  },
];

const markupCostTypeLevel: MarginInputType[] = costTypes.map((costType) =>
  buildType(costType, "markup", "cost_type"),
);

const markupTradeLevel: MarginInputType[] = trades.map((trade) =>
  buildType(trade, "markup", "trade"),
);

const markupInputs = markupMainLevel.concat(
  markupCostTypeLevel,
  markupTradeLevel,
);

const marginMainLevel = [
  {
    label: "Enter margin %",
    value: "package",
    level: "main",
    method: "margin",
  },
];

const marginCostTypeLevel = costTypes.map((costType) =>
  buildType(costType, "margin", "cost_type"),
);
const marginTradeLevel = trades.map((trade) =>
  buildType(trade, "margin", "trade"),
);

const marginInputs = marginMainLevel.concat(
  marginCostTypeLevel,
  marginTradeLevel,
);

export const inputs: MarginInputType[] = marginInputs.concat(markupInputs);
