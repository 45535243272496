import { modals } from "@mantine/modals";
import React from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { ManageAttachmentsContent } from "../ManageAttachmentsContent";

export const useAttachments = () => {
  return React.useCallback((record: BundleLineItem) => {
    modals.open({
      title: "Attachments",
      centered: true,
      children: (
        <ManageAttachmentsContent onClose={modals.closeAll} record={record} />
      ),
      closeOnEscape: false,
      transitionProps: {
        transition: "pop",
      },
    });
  }, []);
};
