import { DataTable } from "mantine-datatable";
import { useMemo } from "react";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import { useMainColumns } from "../EstimationSummaryView/hooks/useMainColumns";

interface Props {
  packages: readonly PackageId[];
}

const EstimationOverview = ({ packages }: Props) => {
  const columns = useMainColumns();

  const records = useMemo(() => {
    return packages.map((_package) => {
      return {
        display_name: _package.title || _package.id.toString(),
        grand_total: _package.grand_total,
        formula: _package.formula,
        subtotal: formatNumberHundredths(
          Number(_package.subtotal) + Number(_package.other_expenses),
        ).toString(),
        apply_margin: _package.apply_margin,
        taxes: _package.taxes,
        margin: _package.margin,
        name: _package.title || _package.id.toString(),
        total_hours: _package.total_hours,
        total_labor_cost: _package.total_labor_cost,
        other_expenses: _package.other_expenses,
      };
    });
  }, [packages]);

  return (
    <DataTable
      columns={columns}
      idAccessor={"name"}
      records={records}
      withColumnBorders
      withTableBorder
    />
  );
};

export default EstimationOverview;
