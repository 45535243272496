import { createContext, useMemo, useState } from "react";

interface ContextType {
  hoveredId: number | null;
  setHoveredId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const HoveredContext = createContext<ContextType>({
  hoveredId: null,
  setHoveredId: () => {},
});

interface Props {
  children: React.ReactNode;
}

const HoveredLineItemProvider = ({ children }: Props) => {
  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const value = useMemo(
    () => ({
      hoveredId,
      setHoveredId,
    }),
    [hoveredId],
  );

  return (
    <HoveredContext.Provider value={value}>{children}</HoveredContext.Provider>
  );
};

export default HoveredLineItemProvider;
