import { notifications } from "@mantine/notifications";
import { useCallback } from "react";
import { useLazyGetPackagesQuery } from "src/data/api/api";

export const useLazyEstimationBasePrice = (estimationId: string) => {
  const [getPackages, { isLoading }] = useLazyGetPackagesQuery();

  const getBasePrice = useCallback(async () => {
    try {
      const data = await getPackages(estimationId).unwrap();

      if (data != null) {
        return data.collection.find(
          (_package) => _package.title?.toLowerCase() === "base",
        )?.grand_total;
      }
    } catch (err) {
      notifications.show({
        title: "Error",
        message: "Could not find packages for current estimation",
      });
    }
  }, [estimationId, getPackages]);

  return { getBasePrice, isLoading };
};
