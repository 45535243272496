import { modals } from "@mantine/modals";
import { useCallback } from "react";
import CreateAssemblyForm from "src/components/Forms/CreateAssemblyForm";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";

export const useCreateAssembly = () => {
  const { selectedItems, setSelectedItems } = useSelectedLineItems();

  const onSuccess = useCallback(() => {
    modals.closeAll();
    setSelectedItems([]);
  }, [setSelectedItems]);

  return useCallback(
    () =>
      modals.open({
        title: "Save as assembly",
        children: (
          <CreateAssemblyForm
            onClose={modals.closeAll}
            onSuccess={onSuccess}
            selectedItemIds={selectedItems.map((r) => r.id)}
          />
        ),
      }),
    [onSuccess, selectedItems],
  );
};
