import { CheckIcon, Group, Popover, ScrollArea } from "@mantine/core";
import { useCallback } from "react";
import { Category } from "src/data/api/types/getCostTypes";
import { DropdownOption } from "../../constants";
import styles from "./CategoryPopover.module.scss";

interface Props {
  currentCategory?: string | null;
  open: boolean;
  item: DropdownOption;
  handleUpdate: (value: string, category?: string) => void;
  setOpenPopover: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryPopover = ({
  currentCategory,
  open,
  handleUpdate,
  item,
  setOpenPopover,
}: Props) => {
  const handleClick = useCallback(
    (category: Category) => {
      setOpenPopover(false);
      handleUpdate(item.value, category.value);
    },
    [handleUpdate, item.value, setOpenPopover],
  );

  return (
    <Popover
      opened={open}
      position="right-start"
      shadow="md"
      withinPortal={false}
    >
      <Popover.Dropdown
        style={{
          left: "190px",
          width: "200px",
          maxHeight: "200px",
          overflow: "scroll",
          padding: "8px 0px 8px 4px",
        }}
      >
        <ScrollArea.Autosize scrollbars="y" type="always">
          {item.categories?.map((category) => (
            <Group
              key={category.value}
              className={styles.popoverItem}
              gap="xs"
              onClick={() => handleClick(category)}
            >
              {currentCategory === category.value ? (
                <CheckIcon size={12} />
              ) : null}
              {category.label}
            </Group>
          ))}
        </ScrollArea.Autosize>
      </Popover.Dropdown>
    </Popover>
  );
};

export default CategoryPopover;
