import { NumberFormatter, Text } from "@mantine/core";
import React from "react";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import styles from "./LaborHoursCell.module.scss";

interface Props {
  record: PackageTotal;
}

export const LaborHoursCell = React.memo<Props>(function _LaborHoursCell({
  record,
}) {
  const value = formatNumberHundredths(Number(record.total_hours));

  return (
    <Text className={styles.root} span={true}>
      <NumberFormatter
        decimalSeparator="."
        thousandSeparator=","
        value={value}
      />{" "}
      hrs
    </Text>
  );
});
