import { Avatar, Group, Menu } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { authSlice } from "src/data/slices/auth";
import { useAppDispatch } from "src/data/store";
import styles from "./ProfileLogoSlim.module.scss";

interface Props {
  readonly innerRef?: React.ForwardedRef<HTMLDivElement>;
}
export const ProfileLogoSlim = React.memo<Props>(function _ProfileLogoSlim({
  innerRef,
}) {
  const dispatch = useAppDispatch();
  const [logout] = api.useLogoutMutation();
  const navigate = useNavigate();

  const handleLogout = React.useCallback(() => {
    logout();
    dispatch(authSlice.actions.logout());
  }, [dispatch, logout]);

  const handleOpenProfile = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.PROFILE);
  }, [navigate]);

  return (
    <Group ref={innerRef} gap="sm">
      <Menu
        arrowPosition="center"
        closeOnItemClick={true}
        position="right-end"
        shadow="md"
        width={150}
      >
        <Menu.Target>
          <Avatar
            autoContrast={true}
            className={styles.avatar}
            styles={{
              root: {
                backgroundColor: "white",
              },
            }}
          />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item className={styles.menuProfile} onClick={handleOpenProfile}>
            Profile
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item className={styles.menuLogout} onClick={handleLogout}>
            Sign out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
});
