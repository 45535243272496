import {
  CloseButton,
  Group,
  Loader,
  Modal,
  Stack,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconSearch } from "@tabler/icons-react";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import AssemblyEditForm from "src/components/Forms/AssemblyEditForm/AssemblyEditForm";
import EditableProvider from "src/context/EditableProvider";
import { useLazyGetAssembliesQuery } from "src/data/api/api";
import { AssemblyType } from "src/data/api/types/getAssemblies";
import { AssemblyIcon } from "src/icons/AssemblyIcon";
import styles from "./Assemblies.module.scss";
import Assembly from "./components/Assembly";

interface Props {
  height: number;
  open: boolean;
  onClose: () => void;
}

const Assemblies = ({ open, onClose, height }: Props) => {
  const [value, setValue] = useDebouncedState("", 300);
  const [opened, { open: openView, close }] = useDisclosure(false);
  const [selectedAssembly, setSelectedAssembly] = useState<AssemblyType | null>(
    null,
  );
  const [assemblies, setAssemblies] = useState<AssemblyType[]>([]);
  const [activeTab, setActiveTab] = useState<string | null>("created_by_me");
  const [getAssemblies, { isLoading }] = useLazyGetAssembliesQuery();

  const handleSelect = useCallback(
    (assembly: AssemblyType) => {
      setSelectedAssembly(assembly);
      openView();
    },
    [openView],
  );

  const onFavorite = useCallback((assemblyId: number) => {
    setAssemblies((_assemblies) => {
      return _assemblies.map((assembly) => {
        if (assembly.attributes.id === assemblyId) {
          return {
            ...assembly,
            attributes: {
              ...assembly.attributes,
              is_favorite: !assembly.attributes.is_favorite,
            },
          };
        }

        return assembly;
      });
    });
  }, []);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.currentTarget.value);
    },
    [setValue],
  );

  useEffect(() => {
    let allowUpdate = true;

    const fetch = async () => {
      try {
        const { data } = await getAssemblies({
          filter: activeTab || "created_by_me",
          title: value,
        });

        if (data != null && allowUpdate === true) {
          setAssemblies(data.collection);
        }
      } catch (err) {
        notifications.show({
          title: "Error",
          message: "Could not fetch Assemblies",
        });
      }
    };

    fetch();

    return () => {
      allowUpdate = false;
    };
  }, [activeTab, getAssemblies, value]);

  return (
    <EditableProvider>
      <Stack
        className={styles.assyContent}
        h={height}
        p={open ? "xs" : 0}
        w={open ? 350 : 0}
      >
        <Stack className={styles.assyContent}>
          <Group className={styles.header}>
            <Group gap="xs">
              <AssemblyIcon color="white" size={16} />
              <Text className={styles.paneTitle}>Assemblies</Text>
            </Group>
            <CloseButton
              onClick={onClose}
              size={22}
              styles={{
                root: {
                  color: "white",
                },
              }}
              variant="transparent"
            />
          </Group>

          <Tabs onChange={setActiveTab} value={activeTab}>
            <Tabs.List className={styles.tabsList}>
              <Tabs.Tab value="created_by_me">Created by me</Tabs.Tab>
              <Tabs.Tab value="favorites">Favorites</Tabs.Tab>
              <Tabs.Tab value="all">All</Tabs.Tab>
            </Tabs.List>
          </Tabs>

          <TextInput
            defaultValue={""}
            leftSection={<IconSearch color="#CED4DA" size="18px" />}
            onChange={handleChange}
            placeholder="Search..."
            styles={{
              input: {
                backgroundColor: "var(--mantine-color-dark-5)",
                color: "var(--mantine-color-gray-4)",
              },
            }}
            w="100%"
          />

          {isLoading ? (
            <Loader />
          ) : (
            assemblies.map((assembly) => (
              <Assembly
                key={assembly.id}
                assembly={assembly}
                onFavorite={onFavorite}
                onSelect={handleSelect}
              />
            ))
          )}
        </Stack>
      </Stack>
      <Modal
        onClose={close}
        opened={opened}
        radius={8}
        size="70%"
        styles={{
          title: {
            fontWeight: 600,
          },
        }}
        title={selectedAssembly?.attributes.title}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        {selectedAssembly ? (
          <AssemblyEditForm assemblyId={selectedAssembly.attributes.id} />
        ) : null}
      </Modal>
    </EditableProvider>
  );
};

export default Assemblies;
