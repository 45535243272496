import { Button, Group, LoadingOverlay, NumberInput } from "@mantine/core";
import { useShallowEffect } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconCurrencyDollar, IconPencil, IconX } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";
import { Control, useController } from "react-hook-form";
import { CalculatedByType } from "src/data/api/types/shared/bid";
import { useLazyEstimationBasePrice } from "src/hooks/useEstimationBasePrice";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import { NewBid } from "../Forms/types/bid";

interface Props {
  control: Control<NewBid["estimation"]>;
  index: number;
  status: string;
  estimationId: string;
  calculatedBy?: CalculatedByType | null;
}

export const EstimationTotalBaseSalePrice = ({
  control,
  index,
  status,
  estimationId,
  calculatedBy,
}: Props) => {
  const [disabled, setDisabled] = useState(
    calculatedBy === CalculatedByType.System,
  );
  const { getBasePrice, isLoading } = useLazyEstimationBasePrice(estimationId);

  const handleDisabled = useCallback(() => {
    setDisabled(false);
  }, []);

  const handleEnabled = useCallback(() => {
    setDisabled(true);
  }, []);

  const totalSellAmount = useController({
    control,
    name: `estimations.${index}.total`,
  });

  const calculatedByField = useController({
    control,
    name: `estimations.${index}.calculated_by`,
  });

  const allowToFetchPackages = useMemo(() => {
    return disabled === true && ["submitted", "lost"].includes(status);
  }, [disabled, status]);

  useShallowEffect(() => {
    const fetchPackages = async () => {
      try {
        const basePrice = await getBasePrice();

        totalSellAmount.field.onChange(
          formatNumberHundredths(Number(basePrice)),
        );
      } catch (err) {
        notifications.show({
          title: "Error",
          message: "Could not find packages for current estimation",
        });
      }
    };

    if (allowToFetchPackages === true) {
      fetchPackages();
    }
  }, [estimationId, getBasePrice, status, disabled]);

  const button = useMemo(() => {
    if (calculatedBy === CalculatedByType.System) {
      return disabled ? (
        <Button
          leftSection={<IconPencil size={14} />}
          onClick={handleDisabled}
          p={0}
          variant="transparent"
          w={100}
        >
          Override
        </Button>
      ) : (
        <Button
          color="black"
          leftSection={<IconX size={14} />}
          onClick={handleEnabled}
          p={0}
          variant="transparent"
          w={100}
        >
          Cancel
        </Button>
      );
    }
  }, [calculatedBy, disabled, handleDisabled, handleEnabled]);

  const onChange = useCallback(
    (value: number | string) => {
      const newCalculatedBy: CalculatedByType =
        calculatedBy !== CalculatedByType.Manual
          ? CalculatedByType.Inactive
          : CalculatedByType.Manual;
      totalSellAmount.field.onChange(value);
      calculatedByField.field.onChange(newCalculatedBy);
    },
    [calculatedBy, calculatedByField.field, totalSellAmount.field],
  );

  return (
    <Group align="end" gap="xs">
      <LoadingOverlay
        overlayProps={{ radius: "sm", blur: 2 }}
        visible={isLoading}
        zIndex={1000}
      />

      <NumberInput
        allowNegative={false}
        decimalSeparator="."
        disabled={disabled}
        error={totalSellAmount.fieldState.error?.message}
        label="Total sell amount"
        leftSection={<IconCurrencyDollar size={16} />}
        onChange={onChange}
        styles={{
          root: {
            flexGrow: 1,
          },
        }}
        thousandSeparator=","
        value={totalSellAmount.field.value}
      />

      {button}
    </Group>
  );
};
