import classNames from "classnames";
import { forwardRef } from "react";
import { WithClassname } from "src/types/withClassName";
import styles from "./TableContainer.module.scss";

interface Props extends WithClassname {
  bundleId?: number;
  children: React.ReactNode[] | React.ReactNode;
  showNewItemButton?: boolean;
  onClick: () => void;
}

const TableContainer = forwardRef<HTMLDivElement, Props>(
  ({ children, className }, ref) => {
    return (
      <div ref={ref} className={classNames(styles.root, className)}>
        {children}
      </div>
    );
  },
);

export default TableContainer;
