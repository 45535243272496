import { Divider, Flex, Select, Stack, Text, TextInput } from "@mantine/core";
import { useDebouncedState, useDidUpdate } from "@mantine/hooks";
import { ChangeEvent, useCallback, useState } from "react";
import { useUnitOfMeasure } from "src/components/EstimationView/Table/hooks/useUnitOfMeasure";
import { Bundle } from "src/data/api/types/updateBundleArgs";

interface Props {
  disabled?: boolean;
  defaultUnit?: string;
  defaultQuantity?: string;
  onChange: (bundle: Bundle) => void;
}

export const UnitOfMeasure = ({
  disabled = false,
  defaultQuantity,
  defaultUnit,
  onChange,
}: Props) => {
  const unitOfMeasure = useUnitOfMeasure();
  const [unit, setUnit] = useState(defaultUnit);
  const [quantity, setQuantity] = useDebouncedState("", 200);

  const handleQuantity = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuantity(event.currentTarget.value);
    },
    [setQuantity],
  );

  const handleUnit = useCallback((value: string | null) => {
    setUnit(value || undefined);
  }, []);

  useDidUpdate(() => {
    if (quantity != null && unit != null) {
      onChange({
        unit_of_measure: `${quantity}/${unit}`,
      });
    }
  }, [quantity, unit, onChange]);

  return (
    <Stack gap={0} h={"60.8px"} justify="end" m={0} p={0}>
      <Text fw={500} fz="sm">
        Unit of measure
      </Text>
      <Flex
        style={{
          border: "1px solid #ced4da",
          borderRadius: "8px",
          boxSizing: "border-box",
        }}
      >
        <TextInput
          defaultValue={defaultQuantity}
          disabled={disabled}
          onChange={handleQuantity}
          styles={{
            root: {
              height: "34px",
            },
            input: {
              padding: "0 8px",
            },
          }}
          variant="unstyled"
          w={"70%"}
        />
        <Divider orientation="vertical" />
        <Select
          comboboxProps={{ width: 220, position: "bottom-start" }}
          data={unitOfMeasure}
          disabled={disabled}
          onChange={handleUnit}
          style={{
            alignSelf: "end",
          }}
          styles={{
            root: {
              height: "34px",
            },
            input: {
              padding: "0 8px",
            },
          }}
          value={unit}
          variant="unstyled"
          w={"30%"}
          withScrollArea={false}
        />
      </Flex>
    </Stack>
  );
};

export default UnitOfMeasure;
