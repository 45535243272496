import { Stack, Tooltip } from "@mantine/core";
import classNames from "classnames";
import { DataTableColumnTextAlign } from "mantine-datatable";
import React from "react";
import { formatNumber } from "src/utils/formatNumber";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import { formatCrewMixHours } from "src/utils/formatCrewMixHours";
import { formatCrewMixPercentage } from "src/utils/formatCrewMixPercentage";
import { formatNumericValue } from "src/utils/formatNumericValue";
import { DEFAULT_PLACEHOLDER } from "../Table/constants";
import { TRADE_OPTIONS_FOR_LABOR, UNITS } from "./constants";
import { CrewMixCell } from "./CrewMixCell";
import styles from "./CrewMixTable.module.scss";
import { LaborRateCell } from "./LaborRateCell";
import {
  CLASSIFICATION_ENTRY_KEYS,
  ClassificationEntry,
  CREW_MIX_ENTRY_KEYS,
  CrewMixEntry,
  SetClassificationEntryArgs,
  SetCrewMixEntryArgs,
} from "./useCrewMixData";
import {
  CrewMixMethodType,
  HoursMethodType,
  LaborTypeTime,
} from "./util/utils";

interface Props {
  readonly crewMixMethod: CrewMixMethodType;
  readonly displayTooltips: boolean;
  readonly getCrewMixEntry: (arg: string) => CrewMixEntry | undefined;
  readonly hoursMethod: HoursMethodType;
  readonly setClassificationEntry: (arg: SetClassificationEntryArgs) => void;
  readonly setCrewMixEntry: (arg: SetCrewMixEntryArgs) => void;
  readonly trade: string;
}
export function useCrewMixColumnDefById({
  crewMixMethod,
  displayTooltips,
  getCrewMixEntry,
  hoursMethod,
  setClassificationEntry,
  setCrewMixEntry,
  trade,
}: Props) {
  const [isCrewMixValid, setIsCrewMixValid] = React.useState<boolean>(true);
  const isByPercentMethod = crewMixMethod === CrewMixMethodType.Percent;
  const isByHoursMethod = hoursMethod === HoursMethodType.Hours;
  const totals = React.useMemo(() => {
    const crewMix = getCrewMixEntry(trade);
    const heads = crewMix?.crewHeads ?? 0;
    const hours = crewMix?.totalHours ?? 0;
    const crewPercentageTotal =
      crewMix?.classificationEntries?.reduce(
        (total, entry) => total + entry.crewPercentage,
        0,
      ) ?? 0;

    const isCrewMixInvalid = formatNumberHundredths(crewPercentageTotal) !== 1;
    const isHoursInvalid = crewMix?.classificationEntries?.some(
      (entry) => formatNumberHundredths(entry.straightTimePercentage) < 0,
    );

    const classificationData = crewMix?.classificationEntries?.map((entry) => {
      return { id: entry.classificationId, heads: entry.crewHeads };
    });

    return {
      heads,
      hours,
      crewPercentageTotal,
      classificationData,
      isCrewMixInvalid,
      isHoursInvalid,
    };
  }, [getCrewMixEntry, trade]);

  React.useEffect(() => {
    if (totals.isCrewMixInvalid) {
      setIsCrewMixValid(false);
    } else {
      setIsCrewMixValid(true);
    }
  }, [totals.isCrewMixInvalid]);

  return React.useMemo(() => {
    return [
      {
        accessor: "classification",
        title: "CLASSIFICATION",
        cellsClassName: styles.classificationCell,
        render: (v: ClassificationEntry) => {
          return (
            <Stack className={styles.classificationName}>
              {v.classificationName}
              <span
                className={styles.classificationWage}
              >{`$${formatNumericValue(v.straightTimeWageTotal, 2, 2)} /hour`}</span>
            </Stack>
          );
        },
        textAlign: "left" as DataTableColumnTextAlign,
      },
      {
        accessor: "crewMixPercentage",
        title: (
          <Tooltip
            className={!isCrewMixValid ? styles.tooltip : undefined}
            disabled={isCrewMixValid || !displayTooltips}
            label={
              formatNumberHundredths(totals.crewPercentageTotal) > 1
                ? "Crew mix is greater than 100%"
                : formatNumberHundredths(totals.crewPercentageTotal) < 1
                  ? "Crew mix is less than 100%"
                  : undefined
            }
            offset={{ mainAxis: -20, crossAxis: -40 }}
            opened={!isCrewMixValid && displayTooltips}
            position="top"
          >
            <span className={styles.headerTitle}>CREW MIX</span>
          </Tooltip>
        ),
        cellsClassName: () =>
          totals.isCrewMixInvalid ? styles.error : undefined,
        render: (v: ClassificationEntry) => {
          const handleUpdateAllocation = (value: number) => {
            // TODO: add validation
            if (isByPercentMethod) {
              const newCrewPercentage = value / 100;
              setClassificationEntry({
                classificationId: v.classificationId,
                key: CLASSIFICATION_ENTRY_KEYS.crewPercentage,
                trade,
                value: formatCrewMixPercentage(newCrewPercentage),
              });

              setClassificationEntry({
                classificationId: v.classificationId,
                key: CLASSIFICATION_ENTRY_KEYS.allottedHours,
                trade,
                value: formatCrewMixHours(newCrewPercentage * totals.hours),
              });
            } else {
              const totalHeads = totals.heads - v.crewHeads + value;
              totals.classificationData?.map((data) => {
                const newCrewPercentage =
                  (data.id !== v.classificationId ? data.heads : value) /
                  totalHeads;

                setClassificationEntry({
                  classificationId: data.id,
                  key: CLASSIFICATION_ENTRY_KEYS.allottedHours,
                  trade,
                  value: formatCrewMixHours(newCrewPercentage * totals.hours)
                });

                setClassificationEntry({
                  classificationId: data.id,
                  key: CLASSIFICATION_ENTRY_KEYS.crewPercentage,
                  trade,
                  value: formatCrewMixPercentage(newCrewPercentage),
                });

                setClassificationEntry({
                  classificationId: v.classificationId,
                  key: CLASSIFICATION_ENTRY_KEYS.crewHeads,
                  trade,
                  value: formatCrewMixPercentage(value),
                });

                setCrewMixEntry({
                  key: CREW_MIX_ENTRY_KEYS.crewHeads,
                  trade,
                  value: totalHeads,
                });
              });
            }

            /*
            ST/OT/DT side effects
            */
            if (value === 0) {
              // Empty out ST/OT/DT allocation if crewMix set to 0
              setClassificationEntry({
                classificationId: v.classificationId,
                key: CLASSIFICATION_ENTRY_KEYS.straightTimePercentage,
                trade,
                value: 0,
              });

              setClassificationEntry({
                classificationId: v.classificationId,
                key: CLASSIFICATION_ENTRY_KEYS.overTimePercentage,
                trade,
                value: 0,
              });

              setClassificationEntry({
                classificationId: v.classificationId,
                key: CLASSIFICATION_ENTRY_KEYS.doubleTimePercentage,
                trade,
                value: 0,
              });
            } else if (value !== 0 && v.crewPercentage === 0) {
              // If value changed from zero, set ST to 100%
              setClassificationEntry({
                classificationId: v.classificationId,
                key: CLASSIFICATION_ENTRY_KEYS.straightTimePercentage,
                trade,
                value: 1,
              });
            }
          };

          return (
            <CrewMixCell
              data={v}
              isByPercentMethod={isByPercentMethod}
              onSubmit={handleUpdateAllocation}
            />
          );
        },
      },
      {
        accessor: "straightTime",
        title: (
          <Tooltip
            className={totals.isHoursInvalid ? styles.tooltip : undefined}
            disabled={!totals.isHoursInvalid || !displayTooltips}
            label={"Straight time can't be less than 0"}
            offset={{ mainAxis: -20, crossAxis: 55 }}
            opened={totals.isHoursInvalid && displayTooltips}
            position="top"
          >
            <span className={styles.headerTitle}>STRAIGHT TIME</span>
          </Tooltip>
        ),
        cellsClassName: (v: ClassificationEntry) =>
          classNames(
            formatNumberHundredths(v.straightTimePercentage) < 0
              ? styles.error
              : undefined,
            styles.straightTime,
          ),
        render: (v: ClassificationEntry) => {
          const straightTimeHours = formatCrewMixHours(
            v.straightTimePercentage * v.allottedHours
          );

          const hoursDisplay =
            isNaN(v.straightTimePercentage) ||
            formatCrewMixHours(v.straightTimePercentage * v.allottedHours) === 0
              ? DEFAULT_PLACEHOLDER
              : straightTimeHours;

          const percentage =
            formatNumber(v.straightTimePercentage * 100, 0, 2) ?? 0;

          const unit =
            Math.abs(straightTimeHours) === 1 ? UNITS.hour : UNITS.hours;

          return (
            <div className={styles.straightTimePercent}>
              {isByHoursMethod ? (
                `${hoursDisplay} ${unit}`
              ) : (
                <Stack className={styles.straightTimePercentFlex}>
                  {percentage === 0 ? DEFAULT_PLACEHOLDER : percentage} %
                  <span className={styles.straightTimeHrs}>
                    {hoursDisplay} {unit}
                  </span>
                </Stack>
              )}
            </div>
          );
        },
      },
      {
        accessor: "overTime",
        title: "OVER TIME",
        cellsClassName: (v: ClassificationEntry) =>
          classNames(
            formatNumberHundredths(v.straightTimePercentage) < 0 &&
              formatNumberHundredths(v.overTimePercentage) !== 0
              ? styles.error
              : undefined,
            v.crewPercentage === 0 ? styles.overDoubleDisabled : null,
          ),
        render: (v: ClassificationEntry) => {
          const handleUpdateAllocation = (value: number) => {
            // TODO: add validation
            let overTimePercentage: number | undefined = undefined;
            const previousSum = v.straightTimePercentage + v.overTimePercentage;
            if (isByHoursMethod) {
              overTimePercentage = value / v.allottedHours;
            } else {
              overTimePercentage = value / 100;
            }

            setClassificationEntry({
              classificationId: v.classificationId,
              key: CLASSIFICATION_ENTRY_KEYS.overTimePercentage,
              trade,
              value: formatCrewMixPercentage(overTimePercentage),
            });

            // Add/deduct straightTimePercentage corresponding to overTimePercentage changes
            setClassificationEntry({
              classificationId: v.classificationId,
              key: CLASSIFICATION_ENTRY_KEYS.straightTimePercentage,
              trade,
              value:
                overTimePercentage + v.doubleTimePercentage === 0
                  ? 1
                  : formatCrewMixPercentage(previousSum - overTimePercentage),
            });
          };

          // TODO: add disabled logic
          return (
            <LaborRateCell
              data={v}
              disabled={
                trade === TRADE_OPTIONS_FOR_LABOR.projectManagement ||
                v.crewPercentage === 0
              }
              isByHoursMethod={isByHoursMethod}
              onSubmit={handleUpdateAllocation}
              timeType={LaborTypeTime.OverTime}
            />
          );
        },
      },
      {
        accessor: "doubleTime",
        title: "DOUBLE TIME",
        cellsClassName: (v: ClassificationEntry) =>
          classNames(
            formatNumberHundredths(v.straightTimePercentage) < 0 &&
              formatNumberHundredths(v.doubleTimePercentage) !== 0
              ? styles.error
              : undefined,
            v.crewPercentage === 0 ? styles.overDoubleDisabled : null,
            styles.doubleTimeCell,
          ),
        render: (v: ClassificationEntry) => {
          const handleUpdateAllocation = (value: number) => {
            // TODO: add validation
            let doubleTimePercentage: number | undefined = undefined;
            const previousSum =
              v.straightTimePercentage + v.doubleTimePercentage;
            if (isByHoursMethod) {
              doubleTimePercentage = value / v.allottedHours;
            } else {
              doubleTimePercentage = value / 100;
            }

            setClassificationEntry({
              classificationId: v.classificationId,
              key: CLASSIFICATION_ENTRY_KEYS.doubleTimePercentage,
              trade,
              value: formatCrewMixPercentage(doubleTimePercentage),
            });

            // Add/deduct straightTimePercentage corresponding to doubleTimePercentage changes
            setClassificationEntry({
              classificationId: v.classificationId,
              key: CLASSIFICATION_ENTRY_KEYS.straightTimePercentage,
              trade,
              value:
                doubleTimePercentage + v.overTimePercentage === 0
                  ? 1
                  : formatCrewMixPercentage(previousSum - doubleTimePercentage),
            });
          };

          // TODO: add disabled logic
          return (
            <LaborRateCell
              data={v}
              disabled={
                trade === TRADE_OPTIONS_FOR_LABOR.projectManagement ||
                v.crewPercentage === 0
              }
              isByHoursMethod={isByHoursMethod}
              onSubmit={handleUpdateAllocation}
              timeType={LaborTypeTime.DoubleTime}
            />
          );
        },
      },
    ];
  }, [
    displayTooltips,
    isByHoursMethod,
    isByPercentMethod,
    isCrewMixValid,
    setClassificationEntry,
    setCrewMixEntry,
    totals,
    trade,
  ]);
}
