import { NavLink, Stack, Tooltip } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import compactLogo from "src/assets/compact-logo.svg";
import { NAV_BAR_CONFIGS } from "src/constants/navBarConfigs";
import { Paths } from "src/constants/paths";
import styles from "./NavBar.module.scss";
import { ProfileLogoSlim } from "./ProfileLogoSlim";

export const NavBarSlim = React.memo(function _NavBarSlim() {
  const navigate = useNavigate();
  const location = useLocation();
  const isInEstimation = React.useMemo(() => {
    return location.pathname.includes(Paths.ESTIMATIONS);
  }, [location.pathname]);

  React.useEffect(() => {
    if (location.pathname === Paths.SLASH) {
      navigate(NAV_BAR_CONFIGS[0].path);
    }
  }, [location, location.pathname, navigate]);

  return (
    <Stack
      align="center"
      className={classNames(
        isInEstimation ? styles.darkRoot : styles.lightRoot,
        styles.root,
      )}
    >
      <div style={{ width: "100%", textAlign: "center" }}>
        <img alt="Grace logo" src={compactLogo} />
      </div>

      <Stack gap={0} style={{ width: "100%", height: "100%" }}>
        {NAV_BAR_CONFIGS.map((item) => (
          // TODO: make subcomponents out of these
          <Tooltip key={item.name} label={item.name} position="right">
            <NavLink
              key={item.name}
              active={location.pathname.includes(item.path)}
              className={classNames(
                isInEstimation ? styles.darkLink : styles.lightLink,
                styles.navLink,
              )}
              component="button"
              h={44}
              leftSection={isInEstimation ? item.iconWhite : item.icon}
              onClick={() => {
                navigate(item.path);
              }}
            />
          </Tooltip>
        ))}
      </Stack>
      <Tooltip label="Profile" position="right" refProp="innerRef">
        <ProfileLogoSlim />
      </Tooltip>
    </Stack>
  );
});
