import { IconCopy, IconCut, IconLogout, IconTrash } from "@tabler/icons-react";
import { useCallback } from "react";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import {
  useCloneItemsMutation,
  useDeleteBundleMutation,
} from "src/data/api/api";
import { ItemId } from "src/data/api/types/cloneItems";
import { PackageBundle } from "src/data/api/types/getBundles";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { CONTEXT_MENU_ICON_SIZE, PACKAGE_PARAM } from "../constants";

export const useGroupContextMenu = (packages: PackageId[]) => {
  const [deleteBundle] = useDeleteBundleMutation();
  const [packageId] = useQueryStringParams(PACKAGE_PARAM);
  const { setAction, setSelectedBundles } = useSelectedLineItems();
  const [cloneItems] = useCloneItemsMutation();

  const handleMove = useCallback(
    (locationId: number, bundles: ItemId[]) => {
      cloneItems({
        action_type: "cut",
        line_item_ids: [],
        package_ids: [],
        bundle_ids: bundles,
        location: {
          type: "Package",
          id: locationId,
        },
      });
      setAction(null);
      setSelectedBundles([]);
    },
    [cloneItems, setAction, setSelectedBundles],
  );

  const handleCopy = useCallback(
    (recordId: number) => {
      setAction("copy");
      setSelectedBundles([recordId]);
    },
    [setAction, setSelectedBundles],
  );

  const handleCut = useCallback(
    (recordId: number) => {
      setAction("cut");
      setSelectedBundles([recordId]);
    },
    [setAction, setSelectedBundles],
  );

  return useCallback(
    (record: PackageBundle) => {
      return [
        {
          key: "copy",
          icon: <IconCopy size={CONTEXT_MENU_ICON_SIZE} />,
          title: `Copy`,
          color: "green",
          onClick: () => handleCopy(record.id),
        },
        {
          key: "cut",
          icon: <IconCut size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Cut",
          color: "blue",
          onClick: () => handleCut(record.id),
        },
        {
          key: "move_to",
          icon: <IconLogout size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Move to",
          items: packages
            .filter((_package) => _package.id !== Number(packageId))
            .map((_package) => ({
              key: _package.id.toString(),
              title: _package.title,
              style: {
                width: "241px",
              },
              onClick: () => handleMove(_package.id, [{ id: record.id }]),
            })),
        },
        { key: "divider01" },
        {
          key: "delete",
          icon: <IconTrash size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Delete",
          color: "red",
          onClick: () => deleteBundle(record.id),
        },
      ];
    },
    [deleteBundle, handleCopy, handleCut, handleMove, packageId, packages],
  );
};
