import ActionCable from "actioncable";

const ConsumerSingleton = (function () {
  let consumer: ActionCable.Cable | null = null;

  return {
    getConsumer: (url: string) => {
      if (!consumer) {
        consumer = ActionCable.createConsumer(url);
      }
      return consumer;
    },
    resetConsumer: () => {
      if (consumer) {
        consumer.disconnect();
        consumer = null;
      }
    },
  };
})();

export { ConsumerSingleton };
