import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useContextMenu } from "mantine-contextmenu";
import { DataTable, DataTableRowClickHandler } from "mantine-datatable";
import React, { memo, useCallback } from "react";
import { useExpandableLineItems } from "src/context/ExpandableLineItemsProvider";
import { useUpdateAssemblyMutation } from "src/data/api/api";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { useCurrentPackage } from "../../hooks/useCurrentPackage";
import { useEstimationColumnMain } from "../../hooks/useEstimationColumnMain";
import { useLineItemsContextMenu } from "../../hooks/useLineItemsContextMenu";
import InnerChildItems from "../InnerChildItems";
import TableContainer from "../TableContainer";
import { useRowFactory } from "../TableRow";

const contextMenuOptions = {
  styles: {
    root: {
      width: "241px",
    },
    item: {
      padding: "8px",
    },
  },
};

const isExpandable = () => true;

interface Props {
  lineItems: BundleLineItem[];
  isEditable: boolean;
}

const AssemblyBundle = memo(({ lineItems, isEditable }: Props) => {
  const { showContextMenu } = useContextMenu();
  const { expandableItems } = useExpandableLineItems();
  const rowFactory = useRowFactory();

  const [updateLineItem] = useUpdateAssemblyMutation();
  const selectedPackageId = useCurrentPackage();

  const handleUpdate = useCallback(
    (bundleId: number, lineItem: LineItem) => {
      updateLineItem({
        bundle_id: bundleId,
        line_item: {
          line_items_attributes: [lineItem],
        },
      });
    },
    [updateLineItem],
  );

  const columnsMain = useEstimationColumnMain(handleUpdate, isEditable, true);
  const createLineItemsContextMenu = useLineItemsContextMenu(
    [],
    updateLineItem,
  );

  const handleNewLineItem = useCallback(() => {
    if (selectedPackageId != null && lineItems[0]?.bundle_id) {
      updateLineItem({
        bundle_id: lineItems[0].bundle_id,
        line_item: {
          line_items_attributes: [{}],
        },
      });
    }
  }, [lineItems, selectedPackageId, updateLineItem]);

  const expandable = useCallback(
    ({ record }: { record: BundleLineItem }) => record.children.length > 0,
    [],
  );

  const handleRowContextMenu: DataTableRowClickHandler<BundleLineItem> =
    useCallback(
      ({ record, event }) => {
        if (isEditable === true) {
          const contextMenu = createLineItemsContextMenu(record, []);

          showContextMenu(contextMenu, contextMenuOptions)(event);
        }
      },
      [createLineItemsContextMenu, isEditable, showContextMenu],
    );

  const handleInnerTable = useCallback(
    ({ record }: { record: BundleLineItem }) => {
      return (
        <InnerChildItems
          handleUpdate={handleUpdate}
          isAssembly={true}
          isEditable={isEditable}
          parentItem={record}
        />
      );
    },
    [handleUpdate, isEditable],
  );

  return (
    <TableContainer onClick={handleNewLineItem} showNewItemButton={isEditable}>
      <DataTable
        borderColor="var(--mantine-color-gray-2)"
        columns={columnsMain}
        highlightOnHover
        highlightOnHoverColor={"#00A69C1A"}
        noHeader={true}
        noRecordsIcon={<React.Fragment />}
        onRowContextMenu={handleRowContextMenu}
        records={lineItems}
        rowExpansion={{
          expanded: {
            recordIds: expandableItems,
          },
          expandable: expandable,
          allowMultiple: true,
          initiallyExpanded: isExpandable,
          content: handleInnerTable,
        }}
        rowFactory={rowFactory}
        withColumnBorders
        withTableBorder
      />
      {isEditable === true ? (
        <Button
          color="gray"
          fw={400}
          leftSection={<IconPlus size="16px" />}
          onClick={handleNewLineItem}
          opacity={0.7}
          variant="transparent"
        >
          New item
        </Button>
      ) : null}
    </TableContainer>
  );
});

export default AssemblyBundle;
