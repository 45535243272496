export const capitalize = (
  str: string,
  numberOfLettersToCapitalize: number = 1,
) => {
  const [firstWord, ...rest] = str.split("_");

  if (firstWord) {
    return [
      firstWord.slice(0, numberOfLettersToCapitalize).toUpperCase() +
        firstWord.slice(numberOfLettersToCapitalize),
      ...rest,
    ].join(" ");
  }

  return str;
};
