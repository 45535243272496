import { useMemo } from "react";
import { useScope } from "src/context/ScopeProvider/useScope";
import { useGetLineItemOptionsQuery } from "src/data/api/api";

export const useCostTypeByName = (name: string) => {
  const target = useScope();
  const { data } = useGetLineItemOptionsQuery(target);

  return useMemo(() => {
    if (data != null) {
      return data.collection.cost_types[name];
    }
  }, [data, name]);
};
