import { Button, Group, Modal, Stack, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCrane,
  IconLayoutGrid,
  IconTool,
  IconTools,
} from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { FormFooter } from "src/components/Frames/FormFooter";
import { WithClassname } from "src/types/withClassName";
import { ProposalTemplateType } from "./constants";
import { EstimationProposalTiptap } from "./EstimationProposalTiptap";
import { TemplateSelectOption } from "./TemplateSelectOption";
import optionStyles from "./TemplateSelectOption.module.scss";
import styles from "./TemplateSelectView.module.scss";

export interface TemplateOption extends WithClassname {
  readonly description: string;
  readonly icon: React.ReactElement;
  readonly label: string;
  readonly value: ProposalTemplateType;
}

const PROPOSAL_TEMPLATE_OPTIONS: ReadonlyArray<TemplateOption> = [
  {
    className: optionStyles.primaryColor,
    description: "Design build, plan & spec, or design assist",
    icon: <IconCrane size={24} />,
    label: "Construction",
    value: ProposalTemplateType.Construction,
  },
  {
    className: optionStyles.blueColor,
    description: "Diagnose and repair breakdowns",
    icon: <IconTool size={24} />,
    label: "Service",
    value: ProposalTemplateType.Service,
  },
  {
    className: optionStyles.orangeColor,
    description: "Perform system retrofit in existing buildings",
    icon: <IconTools size={24} />,
    label: "Retrofit",
    value: ProposalTemplateType.Retrofit,
  },
  {
    className: optionStyles.indigoColor,
    description: "Create a custom proposal",
    icon: <IconLayoutGrid size={24} />,
    label: "Custom",
    value: ProposalTemplateType.Blank,
  },
];

interface Props {
  readonly onPreview: (arg: ProposalTemplateType) => void;
  readonly onSelect: (arg: ProposalTemplateType) => void;
  readonly previewedTemplate: string;
  readonly previewedTemplateType: ProposalTemplateType | null;
}

export const TemplateSelectView = React.memo<Props>(
  function _TemplateSelectView({
    onPreview,
    onSelect,
    previewedTemplate,
    previewedTemplateType,
  }) {
    const [opened, { open, close }] = useDisclosure(false);
    const handleTemplatePreview = React.useCallback(
      (template: ProposalTemplateType): void => {
        onPreview(template);
        open();
      },
      [onPreview, open],
    );

    const handleTemplateSelect = React.useCallback((): void => {
      if (previewedTemplateType == null) {
        return;
      }

      onSelect(previewedTemplateType);
    }, [onSelect, previewedTemplateType]);

    const previewTitle = React.useMemo(() => {
      const previewedOption = PROPOSAL_TEMPLATE_OPTIONS.find(
        (option) => option.value === previewedTemplateType,
      );

      if (previewedTemplateType == null || previewedOption == null) {
        return;
      }

      return (
        <Group>
          <div className={styles.iconWrapper}>{previewedOption.icon}</div>
          {`${previewedOption.label} template`}
        </Group>
      );
    }, [previewedTemplateType]);

    const previewDialog = React.useMemo(() => {
      return (
        <Modal.Root
          centered={true}
          className={styles.root}
          onClose={close}
          opened={true}
          size={800}
        >
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header className={styles.header}>
              <Modal.Title className={styles.previewTitle}>
                {previewTitle ?? "Template preview"}
              </Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>

            <Modal.Body className={styles.body}>
              <div className={styles.previewWrapper}>
                <EstimationProposalTiptap
                  content={previewedTemplate}
                  isReadonly={true}
                />
              </div>
              <FormFooter
                className={styles.footer}
                rightSection={
                  <Group gap={10}>
                    <Button
                      className={styles.buttons}
                      onClick={close}
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      className={styles.buttons}
                      onClick={handleTemplateSelect}
                    >
                      Use template
                    </Button>
                  </Group>
                }
              />
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      );
    }, [close, handleTemplateSelect, previewTitle, previewedTemplate]);

    return (
      <>
        <Stack className={classNames(styles.root)}>
          <Title className={styles.title} order={4}>
            Select a proposal template
          </Title>
          {PROPOSAL_TEMPLATE_OPTIONS.map((option) => {
            return (
              <TemplateSelectOption
                key={option.value}
                className={styles.templateOption}
                onPreview={handleTemplatePreview}
                onSelect={onSelect}
                option={option}
              />
            );
          })}
        </Stack>
        {opened && previewDialog}
      </>
    );
  },
);
