import {
  AssemblyType,
  GetAssembliesReturns,
} from "src/data/api/types/getAssemblies";
import { GetAssemblyReturn } from "src/data/api/types/getAssembly";
import { BundleLineItem } from "src/data/api/types/getBundles";

const transformAssembly =
  (lineItemsWithParent: BundleLineItem[]) => (assembly: AssemblyType) => {
    return {
      ...assembly,
      attributes: {
        ...assembly.attributes,
        line_items: assembly.attributes.line_items
          .map((item) => {
            return {
              ...item,
              children: lineItemsWithParent
                .map((lineItem) => ({
                  ...lineItem,
                  children: lineItemsWithParent.filter(
                    (innerItem) => innerItem.parent_id === lineItem.id,
                  ),
                }))
                .filter((lineItem) => lineItem.parent_id === item.id),
            };
          })
          .filter(
            (item) =>
              lineItemsWithParent.some((lineItem) => lineItem.id === item.id) !=
              true,
          ),
      },
    };
  };

export const transformAssemblyResponse = (data: GetAssemblyReturn) => {
  const lineItemsWithParent = data.assembly.data.attributes.line_items
    .filter((item) => item.parent_id != null)
    .map((item) => ({
      ...item,
      children: [],
    }));

  return {
    ...data,
    assembly: {
      ...data.assembly,
      data: transformAssembly(lineItemsWithParent)(data.assembly.data),
    },
  };
};

export const transformAssembliesResponse = (data: GetAssembliesReturns) => {
  const lineItemsWithParent = data.collection
    .map((assembly) => assembly.attributes.line_items)
    .flat()
    .filter((item) => item.parent_id != null)
    .map((item) => ({
      ...item,
      children: [],
    }));

  return {
    ...data,
    collection: data.collection.map(transformAssembly(lineItemsWithParent)),
  };
};
