import { TextInput } from "@mantine/core";
import { useDebouncedState, useDidUpdate } from "@mantine/hooks";
import { ChangeEvent, useCallback } from "react";
import { Bundle } from "src/data/api/types/updateBundleArgs";

interface Props {
  disabled?: boolean;
  defaultValue: string;
  onChange: (bundle: Bundle) => void;
}

const AssemblyTitle = ({ defaultValue, onChange, disabled = false }: Props) => {
  const [title, setTitle] = useDebouncedState("", 200);

  const handleTitle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTitle(event.currentTarget.value);
    },
    [setTitle],
  );

  useDidUpdate(() => {
    if (title.length > 0 && title !== defaultValue) {
      onChange({
        title,
      });
    }
  }, [title, onChange]);

  return (
    <TextInput
      defaultValue={defaultValue}
      disabled={disabled}
      label="Assembly title"
      onChange={handleTitle}
      required
    />
  );
};

export default AssemblyTitle;
