/* eslint-disable immutable/no-mutation */
import { Button } from "@mantine/core";
import { useCallback } from "react";
import { Estimation } from "src/data/api/types/shared/bid";
import { downloadFile } from "src/utils/downloadFile";

interface Props {
  estimation: Estimation;
}

export const CostCodeButton = ({ estimation }: Props) => {
  const handleClick = useCallback(() => {
    if (estimation.attributes.accounting_csv_url) {
      downloadFile(
        estimation.attributes.accounting_csv_url,
        "cost_code_mapping.csv",
      );
    }
  }, [estimation.attributes.accounting_csv_url]);

  return (
    <Button
      disabled={estimation.attributes.status !== "won"}
      onClick={handleClick}
    >
      {estimation.attributes.status === "won" &&
      estimation.attributes.accounting_csv_url != null
        ? "Download"
        : "Preparing"}
    </Button>
  );
};
