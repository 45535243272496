import { Group, RingProgress, Text } from "@mantine/core";
import { useMemo } from "react";
import { PRICE_METHOD } from "src/components/EstimationView/Table/constants";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { calculatePercent } from "src/utils/calculatePercent";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";

interface Props1 {
  record: PackageTotal;
}

const getValues = (value: number) => {
  if (value <= 20) {
    return {
      background: "#FFF5F5",
      color: "#FA5252",
      value,
    };
  }

  if (value <= 40) {
    return {
      background: "#FFF4E6",
      color: "#FD7E14",
      value,
    };
  }

  return {
    background: "#EBFBEE",
    color: "#40C057",
    value,
  };
};

export const MarginHealthCell = ({ record }: Props1) => {
  const [priceMethod] = useQueryStringParams(PRICE_METHOD);

  const { background, color, value } = useMemo(() => {
    const formula = priceMethod ?? record.formula;

    if (record.formula === formula) {
      const percent = calculatePercent(
        Number(record.margin),
        Number(record.total_labor_cost),
      );

      return getValues(percent);
    }

    return getValues(0);
  }, [priceMethod, record.formula, record.margin, record.total_labor_cost]);

  return (
    <Group
      bg={background}
      px="md"
      style={{
        borderRadius: "4px",
      }}
      w="fit-content"
    >
      <Text c={color} fw="bolder">
        {formatNumberHundredths(value)}%
      </Text>
      <RingProgress
        c="red"
        sections={[{ value: 100 - value, color: color }]}
        size={30}
        thickness={5}
      />
    </Group>
  );
};
