import { ValuesUnion } from "src/types/valuesUnion";
import { Meta } from "../utils/meta";
import { LoadedAddress } from "./address";
import { Note } from "./note";
import { UserRole } from "./userRole";

export const CalculatedByType = {
  System: "system",
  Manual: "manual",
  Inactive: "inactive",
} as const;
export type CalculatedByType = ValuesUnion<typeof CalculatedByType>;

export interface Estimation {
  readonly attributes: {
    readonly bid_id: number;
    readonly id: number;
    readonly job_types?: ReadonlyArray<string>;
    readonly status: string;
    readonly total?: number;
    readonly uuid: string;
    readonly calculated_by?: CalculatedByType;
    readonly accounting_csv_url: string | null;
  };
  readonly id: string;
  readonly users: ReadonlyArray<{
    readonly first_name: string;
    readonly id: number;
    readonly last_name: string;
    readonly roles: ReadonlyArray<UserRole>;
    readonly uuid: string;
  }>;
}

export interface Customer {
  readonly association_id: number;
  readonly due_at: string;
  readonly id: number;
  readonly job_walk_at: string;
  readonly uuid: string;
}

export interface Contact {
  readonly association_id: number;
  readonly customer_id: string;
  readonly id: number;
  readonly uuid: string;
}

export interface LoadedBid {
  readonly attributes: {
    readonly address?: LoadedAddress;
    readonly address_details?: string;
    readonly bid_stage: string;
    readonly branch_id?: number;
    readonly construction_type: string;
    readonly contacts: ReadonlyArray<Contact>;
    readonly customers: ReadonlyArray<Customer>;
    readonly difficulty: string;
    readonly company_id: number;
    readonly company_uuid: string;
    readonly estimations: ReadonlyArray<Estimation>;
    readonly id: number;
    readonly job_site: {
      readonly id: number;
      readonly uuid: string;
    };
    readonly name: string;
    readonly notes: ReadonlyArray<Note>;
    readonly priority: string;
    readonly relationship: string;
    readonly uuid: string;
  };
  readonly id: string;
  readonly meta: Meta;
  readonly type: "bid";
}
