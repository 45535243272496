import { useMemo } from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import InlineLaborItems from "./components/InlineLaborItems";
import { useCostTypeByName } from "./hooks/useCostTypeByName";

interface Props {
  parent?: BundleLineItem;
  record: BundleLineItem;
  isEditable: boolean;
  handleUpdate: (bundleId: number, lineItem: LineItem) => void;
}

export const LaborCell = ({
  record,
  parent,
  isEditable,
  handleUpdate,
}: Props) => {
  const costType = useCostTypeByName(record.cost_type);

  const items = useMemo(
    () =>
      record.children.filter(
        (item) => item.display_attributes?.display === "inline",
      ),
    [record.children],
  );

  if (costType?.inlinable === true) {
    return (
      <InlineLaborItems
        grandParent={parent}
        handleUpdate={handleUpdate}
        isEditable={isEditable}
        laborItems={items}
        parent={record}
      />
    );
  }

  return null;
};
