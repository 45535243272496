import { Button } from "@mantine/core";
import { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ESTIMATION_HEADER_BUTTON_STYLES } from "src/components/EstimationView/constants";
import { Paths } from "src/constants/paths";
import { PieChartDollar } from "src/icons/PieChartDollar";
import { DISTRIBUTION_PARAM } from "../../constants";
import styles from "./EstimationSummaryButton.module.scss";

const EstimationSummaryButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateToSummary = useCallback(() => {
    searchParams.set(DISTRIBUTION_PARAM, "cost_type");

    navigate(
      location.pathname +
        `/${Paths.SUMMARY}?` +
        new URLSearchParams(searchParams),
    );
  }, [location.pathname, navigate, searchParams]);

  return (
    <Button
      className={styles.root}
      leftSection={<PieChartDollar size={14} />}
      onClick={navigateToSummary}
      styles={ESTIMATION_HEADER_BUTTON_STYLES}
      variant="outline"
    >
      Summary
    </Button>
  );
};

export default EstimationSummaryButton;
