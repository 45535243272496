import { Group, Stack } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { DropdownOption } from "./constants";
import styles from "./DropdownCellSelected.module.scss";

interface Props {
  displayClassName?: string;
  item: DropdownOption;
  category?: string | null;
  onClick?: () => void;
}

export const DropdownCellSelected = React.memo<Props>(
  function _DropdownCellSelected({
    displayClassName,
    item,
    onClick,
    category,
  }) {
    return (
      <Group
        align={category ? "flex-start" : "center"}
        className={classNames(styles.root, displayClassName, item.className)}
        onClick={onClick}
      >
        {item.icon ?? undefined}
        <Stack className={styles.selections}>
          <span className={styles.label}>{item.label}</span>
          {category ? (
            <span className={styles.category}>{category}</span>
          ) : null}
        </Stack>
      </Group>
    );
  },
);
