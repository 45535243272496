import { uniqBy } from "mantine-datatable";
import { useMemo } from "react";
import { useScope } from "src/context/ScopeProvider/useScope";
import { useGetLineItemOptionsQuery } from "src/data/api/api";

export const useUnitOfMeasure = () => {
  const target = useScope();
  const { data } = useGetLineItemOptionsQuery(target);

  return useMemo(() => {
    if (data == null) {
      return [];
    }

    return uniqBy(
      Object.values(data.collection.cost_types)
        .map((costType) => costType.units)
        .flat()
        .filter((value) => value.assembly === true),
      (u) => u.value,
    );
  }, [data]);
};
