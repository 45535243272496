import { Text } from "@mantine/core";
import React from "react";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "./CostCell.module.scss";
import { LaborHoursCell } from "./LaborHoursCell";

interface Props {
  record: PackageTotal;
  withTaxes?: boolean;
}

export const CostCell = React.memo<Props>(function _CostCell({
  record,
  withTaxes = true,
}) {
  const value = React.useMemo(() => {
    const cost = formatCurrency(
      withTaxes
        ? Number(record.subtotal) + Number(record.taxes)
        : Number(record.subtotal),
    );

    return cost;
  }, [record.subtotal, record.taxes, withTaxes]);

  return (
    <Text className={styles.root} display="flex">
      {value}
      {record.name === "labor" ? (
        <span className={styles.subtext}>
          (<LaborHoursCell record={record} />)
        </span>
      ) : null}
    </Text>
  );
});
