import { PackageId } from "src/data/api/types/getPackagesArgs";
import { formatNumberHundredths } from "./formatNumberHundredths";

export const buildPackageTotal = (_package: PackageId) => {
  return {
    display_name: _package.title || _package.id.toString(),
    grand_total: _package.grand_total,
    formula: _package.formula,
    subtotal: formatNumberHundredths(
      Number(_package.subtotal) + Number(_package.other_expenses),
    ).toString(),
    apply_margin: _package.apply_margin,
    taxes: _package.taxes,
    margin: _package.margin,
    name: _package.id.toString(),
    total_hours: _package.total_hours,
    total_labor_cost: _package.total_labor_cost,
    other_expenses: _package.other_expenses,
  };
};
