import {
  DEFAULT_CLASSIFICATION,
  HOUR_TYPE_OPTIONS,
} from "src/components/EstimationView/CrewMix/constants";

export interface LineItemCrewMix {
  readonly classification: string;
  readonly hourType: string;
  readonly differential: number;
}

export const DEFAULT_LINE_ITEM_CREW_MIX_VALUE: LineItemCrewMix = {
  classification: DEFAULT_CLASSIFICATION,
  hourType: HOUR_TYPE_OPTIONS[0] as string,
  differential: 0,
};
