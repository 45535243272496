import { useCallback, useMemo } from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { UnitType } from "src/data/api/types/getCostTypes";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import { isLaborUnit } from "src/utils/isLaborUnit";
import { isTruthy } from "src/utils/isTruthy";
import { useCostTypeByName } from "./useCostTypeByName";

const parentLabor = (unit: UnitType) => {
  return !["hours_per_unit", "units_per_hour"].includes(unit.value);
};

const inlineLabor = (unit: UnitType) => {
  return unit.value !== "% of trade total";
};

export const useExpenseQuantity = (
  record: BundleLineItem,
  handleUpdate: (bundleId: number, lineItem: LineItem) => void,
  column: "main" | "labor",
) => {
  const costType = useCostTypeByName(record.cost_type);

  const value = useMemo(() => {
    if (record.quantity_value != null)
      return formatNumberHundredths(Number(record.quantity_value));

    return EMPTY_STRING;
  }, [record.quantity_value]);

  const otherExpenseValue = useMemo(() => {
    if (record.calculate_cost === true && record.calculation_percentage != null)
      return formatNumberHundredths(record.calculation_percentage * 100);
    if (costType?.default != null)
      return formatNumberHundredths(costType.default * 100);

    return EMPTY_STRING;
  }, [costType?.default, record.calculate_cost, record.calculation_percentage]);

  const options = useMemo(() => {
    if (costType?.display_name === "Labor") {
      return costType.units.filter(
        column === "labor" ? inlineLabor : parentLabor,
      );
    }

    return costType?.units ?? [];
  }, [column, costType?.display_name, costType?.units]);

  const defaultUnit = useMemo(() => {
    if (costType?.display_name === "Labor") {
      return column === "labor" ? "hours_per_unit" : "fixed_hours";
    }
    return options.find((u) => u.default == true)?.value;
  }, [column, costType?.display_name, options]);

  const unit = useMemo(() => {
    if (record.quantity_unit != null) return record.quantity_unit;

    return defaultUnit ?? EMPTY_STRING;
  }, [defaultUnit, record.quantity_unit]);

  const total = useMemo(() => {
    let result: number | undefined;
    if (record.parent_id == null && unit !== "% of trade total") return;

    if (isLaborUnit(unit)) {
      result = Number(record.total_hours);
    } else {
      result = Number(record.total_quantity);
    }

    return result;
  }, [record.parent_id, record.total_hours, record.total_quantity, unit]);

  const updateQuantityOtherExpenses = useCallback(
    (_unit: string | null, calculation_percentage: string | null) => {
      if (_unit != null) {
        const percent = calculation_percentage
          ? Number(calculation_percentage) / 100
          : undefined;

        const lineItem: LineItem = {
          id: record.id,
          quantity_value: ["fixed", "calculated"].includes(_unit)
            ? undefined
            : "1",
          calculate_cost: ["% of subtotal", "calculated"].includes(_unit),
          quantity_unit: _unit,
          calculation_percentage: percent,
        };
        handleUpdate(record.bundle_id, lineItem);
      }
    },
    [handleUpdate, record.bundle_id, record.id],
  );

  const updateQuantity = useCallback(
    (_unit: string | null, quantity: string | null) => {
      if (isTruthy(_unit)) {
        let lineItem: LineItem = {
          id: record.id,
          quantity_value: quantity,
          quantity_unit: _unit,
        };

        if (record.cost_type === "labor") {
          if (_unit === "% of trade total") {
            lineItem = {
              ...lineItem,
              trade_hours_percentage: quantity ? Number(quantity) : undefined,
              calculate_hours: true,
            };
          } else {
            lineItem = {
              ...lineItem,
              calculate_hours: false,
            };
          }
        }

        handleUpdate(record.bundle_id, lineItem);
      }
    },
    [handleUpdate, record.bundle_id, record.cost_type, record.id],
  );

  return useMemo(() => {
    if (costType?.other_expense === true) {
      return {
        options,
        value: otherExpenseValue,
        updateQuantity: updateQuantityOtherExpenses,
        total: undefined,
        unit,
      };
    }

    return {
      options,
      value,
      updateQuantity,
      unit,
      total,
    };
  }, [
    costType?.other_expense,
    options,
    otherExpenseValue,
    total,
    unit,
    updateQuantity,
    updateQuantityOtherExpenses,
    value,
  ]);
};
