import { Group, SegmentedControl, Select, Stack } from "@mantine/core";
import React from "react";
import { asMutableArray } from "src/utils/asMutableArray";
import styles from "./Controls.module.scss";
import {
  METRIC_LABELS,
  MetricType,
  SCOPE_LABELS,
  ScopeType,
} from "./util/utils";

interface Props {
  readonly dates: ReadonlyArray<string>;
  readonly defaultDate: string;
  readonly metric: MetricType;
  readonly onDateSelect: (date: string | null) => void;
  readonly onMetricSelect: (metric: MetricType) => void;
  readonly onScopeSelect: (scope: ScopeType) => void;
  readonly scope: ScopeType;
}

export const Controls = React.memo<Props>(function _Controls({
  dates,
  defaultDate,
  metric,
  onDateSelect,
  onMetricSelect,
  onScopeSelect,
  scope,
}) {
  const handleScopeSelect = React.useCallback(
    (value: string) => {
      onScopeSelect(value as ScopeType);
    },
    [onScopeSelect],
  );

  const handleMetricSelect = React.useCallback(
    (value: string) => {
      onMetricSelect(value as MetricType);
    },
    [onMetricSelect],
  );

  return (
    <Group className={styles.root}>
      <Stack className={styles.toggleGroup}>
        Scope
        <SegmentedControl
          data={asMutableArray(SCOPE_LABELS)}
          onChange={handleScopeSelect}
          value={scope}
        />
      </Stack>
      <Stack className={styles.toggleGroup}>
        Metric
        <SegmentedControl
          data={asMutableArray(METRIC_LABELS)}
          onChange={handleMetricSelect}
          value={metric}
        />
      </Stack>
      <Stack className={styles.toggleGroup}>
        Time period
        <Select
          allowDeselect={false}
          className={styles.select}
          classNames={{
            input: styles.selectField,
            options: styles.selectOptions,
          }}
          data={dates}
          onChange={onDateSelect}
          value={defaultDate}
          withScrollArea={false}
        />
      </Stack>
    </Group>
  );
});
