import { Input, InputProps } from "@mantine/core";
import React from "react";
import { useCellKeystrokes } from "./Table/hooks/useCellKeystrokes";

const PACKAGE_INPUT_STYLES: InputProps["styles"] = {
  input: {
    lineHeight: "1.75",
    width: "8ch",
    borderRadius: "5px",
    height: "min-content",
    minHeight: "100%",
    fontSize: "14px",
  },
};

interface Props {
  isEditable: boolean;
  onBlur: () => void;
  onSubmit: (title: string) => void;
  value: string;
}

const EditableInput = React.memo<Props>(function _EditableInput({
  isEditable,
  onBlur,
  onSubmit,
  value,
}) {
  const [currentValue, setCurrentValue] = React.useState(value);
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(e.target.value);
    },
    [],
  );

  const handleSubmit = React.useCallback(() => {
    if (currentValue != null) {
      onSubmit(currentValue);
    }
  }, [currentValue, onSubmit]);

  const { handleEnterSubmit: handleKeyPresses } = useCellKeystrokes({
    onSubmit: handleSubmit,
  });

  if (isEditable === false) {
    return value;
  }

  return (
    <Input
      autoFocus
      onBlur={onBlur}
      onChange={handleChange}
      onKeyDown={handleKeyPresses}
      styles={PACKAGE_INPUT_STYLES}
      value={currentValue}
    />
  );
});

export default EditableInput;
