import { Group, Stack, Tabs } from "@mantine/core";
import { useCallback, useMemo } from "react";
import EstimationOverview from "src/components/EstimationOverview";
import {
  DISTRIBUTION_PARAM,
  PACKAGE_PARAM,
} from "src/components/EstimationView/Table/constants";
import MarginViewTable from "src/components/Tables/MarginViewTable";
import { useGetPackagesQuery } from "src/data/api/api";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import Distributions from "../Distributions";
import EditMarginButton from "../EditMarginButton";

interface Props {
  estimationId: string;
}

const EstimationSummary = ({ estimationId }: Props) => {
  const [selectedPackageId, setSelectedPackageId] =
    useQueryStringParams(PACKAGE_PARAM);
  const [distribution] = useQueryStringParams(DISTRIBUTION_PARAM);
  const { currentData } = useGetPackagesQuery(estimationId);

  const packages = useMemo(() => {
    if (currentData == null) {
      return [];
    }

    return currentData.collection;
  }, [currentData]);

  const handleTabChange = useCallback(
    (value: string | null) => {
      if (value == null) {
        return;
      }

      setSelectedPackageId(value);
    },
    [setSelectedPackageId],
  );

  return (
    <Stack>
      <Tabs
        activateTabWithKeyboard={false}
        onChange={handleTabChange}
        value={selectedPackageId}
        variant="default"
      >
        <Tabs.List>
          {packages.map((packageTab) => (
            <Tabs.Tab
              key={packageTab.id.toString()}
              value={packageTab.id.toString()}
            >
              {packageTab.title || packageTab.id}
            </Tabs.Tab>
          ))}
          <Tabs.Tab key="overview" value="overview">
            Overview
          </Tabs.Tab>
        </Tabs.List>
      </Tabs>

      {selectedPackageId === "overview" ? (
        <EstimationOverview packages={packages} />
      ) : (
        <>
          {selectedPackageId ? (
            <Group>
              <Distributions />
              <EditMarginButton selectedPackageId={selectedPackageId} />
            </Group>
          ) : null}

          {selectedPackageId != null && distribution != null ? (
            <MarginViewTable
              distribution={distribution}
              selectedPackageId={selectedPackageId}
            />
          ) : null}
        </>
      )}
    </Stack>
  );
};

export default EstimationSummary;
