import { useMemo } from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";
import { DROPDOWN_PLACEHOLDER, DropdownType } from "./constants";
import { DropdownCell } from "./DropdownCell";
import { useTradesOptions } from "./hooks/useTradesOptions";

interface Props {
  isEditable: boolean;
  record: BundleLineItem;
  handleUpdate: (bundleId: number, lineItem: LineItem) => void;
}

export const TradeCell = ({ record, handleUpdate, isEditable }: Props) => {
  const { displayClassName, getOptions } = useTradesOptions(record.cost_type);

  const updateTrade = (value: string) => {
    handleUpdate(record.bundle_id, {
      id: record.id,
      trade: value,
    });
  };

  const options = useMemo(() => {
    return getOptions(record.trade);
  }, [getOptions, record.trade]);

  return (
    <DropdownCell
      category={null}
      disabled={!isEditable}
      displayClassName={displayClassName}
      handleUpdate={updateTrade}
      options={options}
      placeholders={DROPDOWN_PLACEHOLDER[DropdownType.Trade]}
      value={record.trade ?? EMPTY_STRING}
    />
  );
};
