export const PieChartDollar = ({ size }: { size: number }) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1004 11.6252C23.0629 11.1752 22.6879 10.8377 22.2754 10.8377H13.1629V2.13771C13.1629 1.68771 12.7879 1.31271 12.3379 1.31271C12.2254 1.31271 12.1129 1.31271 12.0004 1.31271C5.88789 1.27521 0.900391 6.22521 0.900391 12.3002C0.900391 18.3752 5.88789 23.3252 12.0004 23.3252C18.1129 23.3252 23.1004 18.3752 23.1004 12.3002C23.1004 12.0752 23.1004 11.8502 23.1004 11.6252ZM11.4754 3.00021V11.2877L4.16289 17.4752C3.15039 16.0127 2.58789 14.2127 2.58789 12.3002C2.58789 7.35021 6.52539 3.26271 11.4754 3.00021ZM12.0004 21.6752C9.33789 21.6752 6.93789 20.5877 5.21289 18.8252L12.6004 12.5627H21.4129C21.3004 17.5877 17.1379 21.6752 12.0004 21.6752Z"
        fill="#111928"
      />
      <path
        d="M19.7627 7.16269H17.2502C16.8002 7.16269 16.3877 7.53769 16.3877 8.0252C16.3877 8.51269 16.7627 8.8877 17.2502 8.8877H18.7127V9.18769C18.7127 9.6377 19.0877 10.0502 19.5752 10.0502C20.0627 10.0502 20.4377 9.67519 20.4377 9.18769V8.7752C21.3377 8.51269 21.9752 7.68769 21.9752 6.67519C21.9752 5.47519 21.0002 4.5002 19.8002 4.5002H18.6377C18.3752 4.5002 18.1502 4.2752 18.1502 4.0127C18.1502 3.7502 18.3752 3.5252 18.6377 3.5252H21.1502C21.6002 3.5252 22.0127 3.1502 22.0127 2.6627C22.0127 2.1752 21.6377 1.8002 21.1502 1.8002H20.4377V1.5002C20.4377 1.0502 20.0627 0.637695 19.5752 0.637695C19.0877 0.637695 18.7127 1.0127 18.7127 1.5002V1.8002H18.6002C17.4002 1.8002 16.4252 2.7752 16.4252 3.9752C16.4252 5.1752 17.4002 6.1502 18.6002 6.1502H19.7627C20.0252 6.1502 20.2502 6.3752 20.2502 6.6377C20.2502 6.9377 20.0252 7.16269 19.7627 7.16269Z"
        fill="#111928"
      />
    </svg>
  );
};
