interface Props6 {
  color: string;
  size?: number;
}

export const AssemblyIcon = ({ color, size = 26 }: Props6) => {
  return (
    <svg
      fill="none"
      height={27}
      viewBox="0 0 26 27"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1665 15.1253L7.58317 18.3753V24.3337M2.1665 15.1253L7.58317 11.8753L12.9998 15.1253M2.1665 15.1253V21.0837L7.58317 24.3337M12.9998 15.1253V21.0837M12.9998 15.1253L18.4165 18.3753M12.9998 15.1253L18.4165 11.8753M12.9998 15.1253V9.16699M12.9998 21.0837L7.58317 24.3337M12.9998 21.0837L18.4165 24.3337M7.58317 18.424L12.9998 15.1415M18.4165 18.3753V24.3337M18.4165 18.3753L23.8332 15.1253M18.4165 11.8753L23.8332 15.1253M18.4165 11.8753V5.91699M23.8332 15.1253V21.0837L18.4165 24.3337M12.9998 9.16699L7.58317 5.91699L12.9998 2.66699L18.4165 5.91699M12.9998 9.16699L18.4165 5.91699M7.58317 5.94952V11.8591"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};
