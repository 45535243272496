import { Group, SegmentedControl, Stack, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import React from "react";
import { asMutableArray } from "src/utils/asMutableArray";
import styles from "./CrewMixControls.module.scss";
import {
  CREW_MIX_METHOD_LABELS,
  CrewMixMethodType,
  HOURS_METHOD_LABELS,
  HoursMethodType,
} from "./util/utils";

interface Props {
  readonly crewMixMethod: CrewMixMethodType;
  readonly hoursMethod: HoursMethodType;
  readonly onCrewMixMethodSelect: (value: string) => void;
  readonly onHoursMethodSelect: (method: HoursMethodType) => void;
}

export const CrewMixControls = React.memo<Props>(function _CrewMixControls({
  crewMixMethod,
  onCrewMixMethodSelect,
  onHoursMethodSelect,
  hoursMethod,
}) {
  const handleHoursMethodSelect = React.useCallback(
    (value: string) => {
      onHoursMethodSelect(value as HoursMethodType);
    },
    [onHoursMethodSelect],
  );

  const handleCrewMixMethodSelect = React.useCallback(
    (value: string) => {
      modals.openConfirmModal({
        title: "Please confirm your action",
        children: (
          <Text size="sm">
            If you change method, it will reset your crew mix values. Do you
            want to continue?
          </Text>
        ),
        withCloseButton: false,
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onConfirm: () => onCrewMixMethodSelect(value),
      });
    },
    [onCrewMixMethodSelect],
  );

  return (
    <Group className={styles.root}>
      <Stack className={styles.toggleGroup}>
        Crew mix method
        <SegmentedControl
          data={asMutableArray(CREW_MIX_METHOD_LABELS)}
          onChange={handleCrewMixMethodSelect}
          value={crewMixMethod}
        />
      </Stack>
      <Stack className={styles.toggleGroup}>
        Hours allocation
        <SegmentedControl
          data={asMutableArray(HOURS_METHOD_LABELS)}
          onChange={handleHoursMethodSelect}
          value={hoursMethod}
        />
      </Stack>
    </Group>
  );
});
