import { Draggable } from "@hello-pangea/dnd";
import { Box } from "@mantine/core";
import { IconArrowsMoveVertical } from "@tabler/icons-react";
import { memo, useMemo } from "react";
import { PackageBundle } from "src/data/api/types/getBundles";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import GroupBundle from "../GroupBundle";
import LineItemBundle from "../LineItemBundle/LineItemBundle";

interface Props {
  packageId: string;
  packages: PackageId[];
  bundle: PackageBundle;
  currentIndex: number;
}

const EstimationBundle = memo(({ packages, bundle, currentIndex }: Props) => {
  const bundleTable = useMemo(() => {
    if (["assembly", "line_item"].includes(bundle.bundle_type)) {
      return (
        <LineItemBundle
          lineItems={bundle.line_items}
          noHeader={currentIndex !== 0}
          packages={packages}
        />
      );
    }

    if (bundle.bundle_type === "group") {
      return (
        <GroupBundle
          bundle={bundle}
          noHeader={currentIndex !== 0}
          packages={packages}
        />
      );
    }
  }, [bundle, currentIndex, packages]);

  return (
    <Box pos="relative">
      <Draggable draggableId={bundle.id.toString()} index={currentIndex}>
        {(provider) => (
          <Box {...provider.draggableProps} ref={provider.innerRef}>
            {bundleTable}
            <span
              {...provider.dragHandleProps}
              style={{
                top: 0,
                left: -20,
                position: "absolute",
                zIndex: 1,
              }}
            >
              <IconArrowsMoveVertical size={14} />
            </span>
          </Box>
        )}
      </Draggable>
    </Box>
  );
});

export default EstimationBundle;
