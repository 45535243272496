import { PackageBundle } from "src/data/api/types/getBundles";

export const getLineItems = (bundles: PackageBundle[]) => {
  const groupsLineItems = bundles
    .filter((bundle) => bundle.bundle_type === "group")
    .map((bundle) => bundle.children)
    .flat()
    .map((bundle) => bundle.attributes.line_items)
    .flat();

  return bundles
    .map((bundle) => bundle.line_items)
    .flat()
    .concat(groupsLineItems);
};
