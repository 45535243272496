import { Group, Stack, Text } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { EstimationHeaderColumns } from "../../constants";
import styles from "./StickyHeader.module.scss";

interface Props {
  title: string;
  columns: ReadonlyArray<EstimationHeaderColumns>;
}

export const StickyHeader = React.memo<Props>(function _StickyHeader({
  title,
  columns,
}) {
  return (
    <Stack className={styles.root}>
      <Text
        className={classNames(
          styles.mainHeader,
          title === "LABOR" ? styles.inlineTable : null,
        )}
        component="span"
      >
        {title}
      </Text>
      <Group className={styles.container}>
        {columns.map((column) => (
          <Text
            key={column.value}
            className={classNames(styles.column, column.className ?? null)}
            w={column.width}
          >
            {column.label}
          </Text>
        ))}
      </Group>
    </Stack>
  );
});
