import { Box, Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useContextMenu } from "mantine-contextmenu";
import { DataTable, DataTableRowClickHandler } from "mantine-datatable";
import React from "react";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { CONTEXT_MENU_STYLES } from "../../constants";
import { useChildItemContextMenu } from "../../hooks/useChildItemContextMenu";
import { useEstimationColumnMain } from "../../hooks/useEstimationColumnMain";
import { useRowFactory } from "../TableRow";
import styles from "./InnerChildItems.module.scss";

interface Props {
  isAssembly?: boolean;
  isEditable: boolean;
  handleUpdate: (bundleId: number, lineItem: LineItem) => void;
  parentItem: BundleLineItem;
}

const InnerChildItems = React.memo<Props>(function _InnerChildItems({
  isAssembly = false,
  isEditable,
  handleUpdate,
  parentItem,
}) {
  const { selectedItems } = useSelectedLineItems();
  const { showContextMenu } = useContextMenu();
  const rowFactory = useRowFactory();
  const innerLineItems = React.useMemo(
    () =>
      parentItem.children.filter(
        (child) => child.display_attributes?.display !== "inline",
      ),
    [parentItem.children],
  );

  const addSubItem = React.useCallback(() => {
    handleUpdate(parentItem.bundle_id, {
      id: undefined,
      parent_id: parentItem.id,
      package_id: parentItem.package_id,
    });
  }, [
    handleUpdate,
    parentItem.bundle_id,
    parentItem.id,
    parentItem.package_id,
  ]);

  const columnsMain = useEstimationColumnMain(
    handleUpdate,
    isEditable,
    isAssembly,
    parentItem,
  );
  const createLineItemsContextMenu = useChildItemContextMenu(handleUpdate);

  const handleRowContextMenu: DataTableRowClickHandler<BundleLineItem> =
    React.useCallback(
      ({ record, event }) => {
        if (isEditable === true) {
          const contextMenu = createLineItemsContextMenu(record);
          showContextMenu(contextMenu, CONTEXT_MENU_STYLES)(event);
        }
      },
      [createLineItemsContextMenu, isEditable, showContextMenu],
    );

  if (innerLineItems.length > 0) {
    return (
      <Box className={styles.root}>
        <DataTable
          backgroundColor="var(--mantine-color-gray-0)"
          borderColor="var(--mantine-color-gray-2)"
          columns={columnsMain}
          height="fit-content"
          highlightOnHover={true}
          idAccessor={"id"}
          noHeader={true}
          noRecordsIcon={<React.Fragment />}
          onRowContextMenu={handleRowContextMenu}
          records={innerLineItems}
          rowFactory={rowFactory}
          styles={{
            table: {
              borderBottom: "1px solid var(--mantine-color-gray-2)",
              background:
                selectedItems.findIndex((item) => item.id === parentItem.id) >
                -1
                  ? "#ebf6f6"
                  : "inherit",
            },
          }}
          withColumnBorders={true}
        />
        {isEditable === true ? (
          <Button
            className={styles.newItem}
            leftSection={<IconPlus size="14px" />}
            onClick={addSubItem}
            variant="transparent"
          >
            New sub-item
          </Button>
        ) : null}
      </Box>
    );
  }

  return null;
});

export default InnerChildItems;
