import {
  ComboboxItem,
  ComboboxLikeRenderOptionInput,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import Mexp from "math-expression-evaluator";
import React, { ChangeEvent } from "react";
import { UnitType } from "src/data/api/types/getCostTypes";
import { EMPTY_STRING } from "src/utils/empty";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import { isLaborUnit } from "src/utils/isLaborUnit";
import { isTruthy } from "src/utils/isTruthy";
import { DEFAULT_PLACEHOLDER } from "./constants";
import { useCellKeystrokes } from "./hooks/useCellKeystrokes";
import styles from "./QuantityCellV2.module.scss";

const iconProps = {
  stroke: 1.5,
  color: "currentColor",
  opacity: 0.6,
  size: 18,
};

const validateNumber = (number: string) =>
  /^([1-9][0-9]*|0)([.][0-9]{1,2})?$/.test(number);

const validateExpression = (expression: string) =>
  /^=[0-9.+\-*/()]*$/.test(expression);

const validateIfEmpty = (number: string) => {
  return number === EMPTY_STRING || number[number.length - 1] === ".";
};

const formatNumber = (number: string) =>
  number.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

const DEFAULT_UNIT_WIDTH = 40;

interface Props {
  disabled?: boolean;
  handleUpdate: (unit: string | null, quantity: string | null) => void;
  isInlineLabor: boolean;
  options: UnitType[];
  total?: number;
  unit: string | null;
  value: string | null;
}

export const QuantityCellV2 = React.memo<Props>(function _QuantityCellV2({
  disabled = false,
  handleUpdate,
  isInlineLabor,
  options,
  total,
  unit,
  value,
}) {
  const quantityInputRef = React.useRef<HTMLInputElement | null>(null);
  const [quantity, setQuantity] = React.useState<string | null>(
    value ? formatNumber(value) : null,
  );
  const [unitValue, setUnitValue] = React.useState<string | null>(unit);
  const [quantityWidth, setQuantityWidth] = React.useState(50);
  const [unitWidth, setUnitWidth] = React.useState(DEFAULT_UNIT_WIDTH);
  const quantityRef = React.useRef<HTMLSpanElement | null>(null);
  const unitRef = React.useRef<HTMLSpanElement | null>(null);
  const disableQuantity = React.useMemo(() => {
    return ["fixed", "calculated"].includes(unitValue ?? EMPTY_STRING);
  }, [unitValue]);

  const displayName = React.useMemo(() => {
    return (
      options.find((o) => o.value === unitValue)?.display_name ?? EMPTY_STRING
    );
  }, [options, unitValue]);

  const handleSubmit = React.useCallback(() => {
    let finaleQuantity = quantity;

    if (quantity?.[0] === "=") {
      const mexp = new Mexp();

      try {
        finaleQuantity = mexp.eval(quantity.slice(1)).toString();
      } catch (err) {
        notifications.show({
          color: "red",
          title: "Error",
          message: "Incomplete formula",
        });
        finaleQuantity = null;
      }

      setQuantity(finaleQuantity ? formatNumber(finaleQuantity) : null);
    } else {
      finaleQuantity = finaleQuantity?.replace(/,/g, "") ?? null;
    }

    handleUpdate(unitValue, finaleQuantity);
  }, [handleUpdate, quantity, unitValue]);

  const handleQuantityChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value.replace(/,/g, "");
      //only allow numbers
      if (validateNumber(number)) {
        setQuantity(formatNumber(number));
      }
      //check if first character is = allow additional characters
      if (validateExpression(number)) {
        setQuantity(number);
      }

      //allow to set quantity if empty string or last character is .
      if (validateIfEmpty(event.target.value)) {
        setQuantity(event.target.value);
      }
    },
    [],
  );

  const handleUnitChange = React.useCallback(
    (newUnit: string | null) => {
      setUnitValue(newUnit);
      handleUpdate(newUnit, quantity);
    },
    [handleUpdate, quantity],
  );

  const { handleEnterBlur: handleQuantityKeyPresses } = useCellKeystrokes({
    ref: quantityInputRef,
  });

  const data = React.useMemo(() => {
    return options.map((o) => ({
      value: o.value,
      label: o.display_name ?? EMPTY_STRING,
    }));
  }, [options]);

  const renderSelectOption = React.useCallback(
    (item: ComboboxLikeRenderOptionInput<ComboboxItem>) => {
      const { checked, option } = item;
      const currentOption = options.find((o) => o.value === option.value);

      return (
        <Group className={styles.option}>
          {currentOption?.label}
          {checked && (
            <IconCheck style={{ marginInlineStart: "auto" }} {...iconProps} />
          )}
        </Group>
      );
    },
    [options],
  );

  React.useEffect(() => {
    if (disableQuantity === true) {
      setQuantity(EMPTY_STRING);
    }
  }, [disableQuantity]);

  React.useEffect(() => {
    if (quantityRef.current != null && quantity !== EMPTY_STRING) {
      setQuantityWidth(quantityRef.current.offsetWidth + 40);
    }

    if (unitRef.current != null && displayName !== EMPTY_STRING) {
      const offsetWidth = unitRef.current.offsetWidth;
      setUnitWidth(offsetWidth !== 0 ? offsetWidth + 10 : DEFAULT_UNIT_WIDTH);
    }
  }, [displayName, quantity]);

  return (
    <Stack className={styles.root}>
      <Group className={styles.top}>
        {disableQuantity ? (
          <Text className={styles.disabled}>{DEFAULT_PLACEHOLDER}</Text>
        ) : (
          <>
            {/* DO NOT REMOVE SPAN TAG */}
            <span ref={quantityRef} className={styles.hidden}>
              {quantity ?? EMPTY_STRING}
            </span>
            <TextInput
              ref={quantityInputRef}
              disabled={disabled || disableQuantity}
              onBlur={handleSubmit}
              onChange={handleQuantityChange}
              onKeyDown={handleQuantityKeyPresses}
              placeholder={isInlineLabor ? "hours" : "qty"}
              styles={{
                input: {
                  width: `${quantityWidth}px`,
                  height: "min-content",
                  minHeight: "min-content",
                  padding: "0",
                  lineHeight: "1.5",
                  textAlign: "right",
                  fontSize: "var(--mantine-font-size-xs)",
                },
              }}
              value={quantity ?? EMPTY_STRING}
              variant="unstyled"
            />
          </>
        )}
        {/* DO NOT REMOVE SPAN TAG */}
        <span ref={unitRef} className={styles.hidden}>
          {displayName ?? EMPTY_STRING}
        </span>
        <Select
          allowDeselect={false}
          comboboxProps={{ width: 220, position: "bottom-start" }}
          data={data}
          disabled={disabled}
          leftSection={null}
          onBlur={handleSubmit}
          onChange={handleUnitChange}
          placeholder="select"
          renderOption={renderSelectOption}
          searchable
          styles={{
            dropdown: {
              width: "180px",
            },
            input: {
              width: `${unitWidth}px`,
              height: "min-content",
              minHeight: "min-content",
              padding: "0",
              lineHeight: "1.5",
              textAlign: "right",
              fontSize: "var(--mantine-font-size-xs)",
            },
            section: {
              display: "none",
            },
          }}
          value={unitValue}
          variant="unstyled"
          withScrollArea={false}
        />
      </Group>
      {isTruthy(total) && unitValue != null ? (
        <Text
          className={styles.bottom}
        >{`${formatNumberHundredths(total)} ${isLaborUnit(unit ?? EMPTY_STRING) ? "hrs" : displayName} TOTAL`}</Text>
      ) : null}
    </Stack>
  );
});
