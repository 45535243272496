import { Group, Skeleton, Stack } from "@mantine/core";
import React from "react";
import { LoadingScreenType } from "src/types/loadingScreenType";
import styles from "./LoadingScreen.module.scss";

interface Props {
  readonly loadingScreenType: LoadingScreenType;
}

export const LoadingScreen = React.memo<Props>(function _LoadingScreen({
  loadingScreenType,
}) {
  const rowNumber = Math.floor(Math.random() * 11) + 5; // Generates a number between 5 and 15

  switch (loadingScreenType) {
    case LoadingScreenType.CrewMix:
      return (
        <Group className={styles.crewMixRoot}>
          <Stack>
            <Skeleton height={40} width={175} />
            <Skeleton height={40} width={175} />
            <Skeleton height={40} width={175} />
          </Stack>
          <Stack>
            <Skeleton height={35} width={550} />
            <Skeleton height={35} width={550} />
            <Skeleton height={35} width={550} />
            <Skeleton height={35} width={550} />
            <Skeleton height={35} width={550} />
          </Stack>
        </Group>
      );
    case LoadingScreenType.Profile:
      return (
        <Stack className={styles.mainStack}>
          <Skeleton className={styles.linksRow} height={20} width={150} />
          <Skeleton height={30} width={200} />
          <Skeleton height={20} width={200} />
          <Group align="flex-start">
            <Stack className={styles.column}>
              <Skeleton height={300} />
              <Skeleton height={150} />
              <Skeleton height={150} />
            </Stack>
            <Stack className={styles.column}>
              <Skeleton height={250} />
              <Skeleton height={150} />
            </Stack>
          </Group>
        </Stack>
      );

    case LoadingScreenType.Table:
      return (
        <Stack className={styles.mainStack}>
          <Skeleton height={40} />
          {Array.from({ length: rowNumber }, (_, index) => (
            <Skeleton key={index} height={30} />
          ))}
        </Stack>
      );

    case LoadingScreenType.Editor:
      return (
        <Stack className={styles.mainStack}>
          <Skeleton height={40} />
          {Array.from({ length: 11 }, (_, index) => (
            <Skeleton key={index} height={30} />
          ))}
        </Stack>
      );
  }
});
