import { Button, Group, Stack, Title } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import { ProposalTemplateType } from "./constants";
import styles from "./TemplateSelectOption.module.scss";
import { TemplateOption } from "./TemplateSelectView";

interface Props extends WithClassname {
  readonly onPreview: (arg: ProposalTemplateType) => void;
  readonly onSelect: (arg: ProposalTemplateType) => void;
  readonly option: TemplateOption;
}

export const TemplateSelectOption = React.memo<Props>(
  function _TemplateSelectOption({ className, onPreview, onSelect, option }) {
    const handleOptionClick = React.useCallback((): void => {
      onSelect(option.value);
    }, [onSelect, option.value]);

    const handlePreviewClick = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        onPreview(option.value);
      },
      [onPreview, option.value],
    );

    return (
      <Stack
        key={option.label}
        className={classNames(className, styles.optionRoot)}
        onClick={handleOptionClick}
      >
        <Group className={styles.contentFlex}>
          <div className={classNames(option.className, styles.iconWrapper)}>
            {option.icon}
          </div>
          <Stack className={styles.textFlex}>
            <Title className={styles.optionTitle} order={5}>
              {option.label}
            </Title>
            {option.description}
          </Stack>
        </Group>
        <Button
          className={styles.previewButton}
          leftSection={<IconEye size={14} />}
          onClick={handlePreviewClick}
          variant="subtle"
        >
          Preview
        </Button>
      </Stack>
    );
  },
);
