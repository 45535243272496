import { useMemo } from "react";
import { useGetProfitCalculationQuery } from "src/data/api/api";

export const useProfitCalculation = (packageId: string) => {
  const { data } = useGetProfitCalculationQuery(packageId);

  return useMemo(() => {
    if (data != null) {
      return data.profit_calculation.data.attributes;
    }
  }, [data]);
};
