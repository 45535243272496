import { Button } from "@mantine/core";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  DISTRIBUTION_PARAM,
  PRICE_METHOD,
} from "src/components/EstimationView/Table/constants";
import { EditIcon } from "src/components/EstimationView/Table/EditIcon";
import { Paths } from "src/constants/paths";
import { useProfitCalculation } from "src/hooks/useProfitCalculation";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";

interface Props {
  selectedPackageId: string;
}

const EditMarginButton = React.memo<Props>(function _EditMarginButton({
  selectedPackageId,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [level] = useQueryStringParams(DISTRIBUTION_PARAM);
  const profitCalculation = useProfitCalculation(selectedPackageId);

  const navigateToMargin = React.useCallback(() => {
    if (profitCalculation?.formula != null) {
      params.set(PRICE_METHOD, profitCalculation.formula);
    }

    if (level === "group") {
      params.set(DISTRIBUTION_PARAM, "main");
    }

    navigate(
      location.pathname.replace(Paths.SUMMARY, Paths.MARGINS) +
        "?" +
        new URLSearchParams(params),
    );
  }, [level, location.pathname, navigate, params, profitCalculation?.formula]);

  return (
    <Button
      leftSection={<EditIcon color="white" />}
      ml="auto"
      onClick={navigateToMargin}
    >
      Edit Margin
    </Button>
  );
});

export default EditMarginButton;
