import { modals } from "@mantine/modals";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { LineItemCrewMixFormContent } from "src/components/Forms/LineItemCrewMixFormContent";
import { MatchParam } from "src/constants/matches";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { EMPTY_STRING } from "src/utils/empty";
import { PACKAGE_PARAM } from "../constants";

export const useLineItemCrewMix = () => {
  const [params] = useSearchParams();
  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();
  const packageId = params.get(PACKAGE_PARAM) ?? EMPTY_STRING;
  return React.useCallback(
    (_record: BundleLineItem) => {
      modals.open({
        title: "Adjust crew mix for selected item",
        centered: true,
        children: (
          <LineItemCrewMixFormContent
            estimationId={estimationId}
            onClose={modals.closeAll}
            packageId={packageId}
            record={_record}
          />
        ),
        closeOnEscape: false,
        transitionProps: {
          transition: "pop",
        },
      });
    },
    [estimationId, packageId],
  );
};
