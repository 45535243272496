import React from "react";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import { useDeleteLineItemsMutation } from "src/data/api/api";
import { EMPTY_STRING } from "src/utils/empty";
import { useCreateBundle } from "./useCreateBundle";

export function useTableKeystrokes(packageId: string | null) {
  const { selectedItems, setSelectedItems } = useSelectedLineItems();
  const [deleteItem] = useDeleteLineItemsMutation();
  const { createGroupBundle, createLineItemBundle } =
    useCreateBundle(packageId);

  const deleteSelected = React.useCallback(() => {
    if (packageId == null) return;

    deleteItem({
      packageId: packageId,
      lineItems: {
        line_item_ids: selectedItems.map((item) => item.id),
      },
    });

    setSelectedItems([]);
  }, [deleteItem, packageId, selectedItems, setSelectedItems]);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      const isCreateAllowed = !["INPUT", "DIV", "BUTTON"].includes(
        activeElement?.tagName ?? EMPTY_STRING,
      );

      const isDeleteAllowed = !["DIV", "BUTTON"].includes(
        activeElement?.tagName ?? EMPTY_STRING,
      );

      if (isCreateAllowed) {
        if (event.ctrlKey && event.key === "a") {
          event.preventDefault();
          createLineItemBundle();
        } else if (event.ctrlKey && event.key === "g") {
          event.preventDefault();
          createGroupBundle();
        }
      }

      if (isDeleteAllowed) {
        if (event.ctrlKey && event.key === "d" && selectedItems.length >= 1) {
          event.preventDefault();
          deleteSelected();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    createGroupBundle,
    createLineItemBundle,
    deleteSelected,
    selectedItems.length,
  ]);
}
