import { EMPTY_STRING } from "./empty";

export function formatNumericValue(
  value: number | undefined,
  minDecimalPlaces: number,
  maxDecimalPlaces: number,
): string {
  if (value == null) {
    return EMPTY_STRING;
  }

  const factor = Math.pow(10, maxDecimalPlaces);
  const roundedValue = Math.round(value * factor) / factor;

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: minDecimalPlaces,
    maximumFractionDigits: maxDecimalPlaces,
  }).format(roundedValue);
}
