import { downloadFile } from "./downloadFile";

export async function downloadS3File(
  filename: string,
  url: string,
): Promise<void> {
  const downloadUrl = await fetch(url)
    .then((response) => response.blob())
    .then(URL.createObjectURL);

  downloadFile(downloadUrl, filename);
  URL.revokeObjectURL(downloadUrl);
}
