import { NumberInputProps, TextInputProps } from "@mantine/core";
import { Category } from "src/data/api/types/getCostTypes";
import { LabelValue } from "src/types/util/labelValue";
import { ValuesUnion } from "src/types/valuesUnion";
import columnStyles from "../Table/components/StickyTableHeaders/StickyHeader.module.scss";
import dropdownStyles from "./DropdownCell.module.scss";
import valueStyles from "./ValueDisplayCell.module.scss";

export const PACKAGE_PARAM = "packageId";
export const DISTRIBUTION_PARAM = "distribution";
export const PRICE_METHOD = "method";
export const KEYSTROKES = {
  CTRL_A: "ctrl + a",
  CTRL_G: "ctrl + g",
  CTRL_D: "ctrl + d",
};

export const CONTEXT_MENU_ICON_SIZE = 12;

export const CONTEXT_MENU_STYLES = {
  styles: {
    root: {
      width: "241px",
    },
    item: {
      padding: "8px",
    },
    divider: {
      backgroundColor: "var(--mantine-color-gray-3)",
      height: "1px",
    },
  },
};

export const DropdownType = {
  CostType: "Cost type",
  DescSubcontractor: "Subcontractor",
  DescEngineering: "Engineering",
  LaborForChildUnits: "Labor for child units",
  Trade: "Trade",
  Units: "Units",
} as const;
export type DropdownType = ValuesUnion<typeof DropdownType>;

type DropdownPlaceholders = {
  readonly [key in DropdownType]: {
    readonly active: string;
    readonly inactive: string;
  };
};

export const DROPDOWN_PLACEHOLDER: DropdownPlaceholders = {
  [DropdownType.CostType]: {
    active: "Cost type",
    inactive: "Select",
  },
  [DropdownType.Trade]: {
    active: "Trades",
    inactive: "Select",
  },
  [DropdownType.DescSubcontractor]: {
    active: "Description",
    inactive: "Select",
  },
  [DropdownType.DescEngineering]: {
    active: "Description",
    inactive: "Select",
  },
  [DropdownType.LaborForChildUnits]: {
    active: "unit",
    inactive: "unit",
  },
  [DropdownType.Units]: {
    active: "unit",
    inactive: "unit",
  },
};

export const UnitPosition = {
  Prefix: "Prefix",
  Suffix: "Suffix",
} as const;
export type UnitPosition = ValuesUnion<typeof UnitPosition>;

export const DEFAULT_PLACEHOLDER = "-";
export const UNIT_PLACEHOLDER = "unit";
export const VALUE_CELL_UNIT_DISPLAYS = {
  DOLLAR: "$",
  HOURS: "hrs",
};

export const valueDisplayStyles: Readonly<string | undefined> =
  valueStyles.subtotal;

export const TEXT_INPUT_STYLES: TextInputProps["styles"] = {
  input: {
    borderRadius: 0,
    fontSize: "var(--mantine-font-size-xs)",
  },
};

export const NUMERIC_INPUT_STYLES: NumberInputProps["styles"] = {
  input: {
    borderRadius: 0,
    textAlign: "right",
    fontSize: "var(--mantine-font-size-xs)",
  },
};

export const CONTEXT_MENU_KEYSTROKE_STYLES = {
  fontVariant: "small-caps",
  color: "var(--mantine-color-gray-6)",
  fontWeight: "500",
  fontSize: "11px",
};

type DropdownDisplayStyles = Readonly<Record<DropdownType, string | undefined>>;

export const dropdownDisplayStyles: DropdownDisplayStyles = {
  [DropdownType.CostType]: dropdownStyles.displayCostType,
  [DropdownType.Trade]: dropdownStyles.displayTrade,
  [DropdownType.DescEngineering]: dropdownStyles.displayDescription,
  [DropdownType.DescSubcontractor]: dropdownStyles.displayDescription,
  [DropdownType.LaborForChildUnits]: undefined, // TODO: these should be the same styles as cost type dropdown icon+label
  [DropdownType.Units]: undefined,
};

export interface DropdownOption extends LabelValue {
  readonly className?: string | undefined;
  readonly display?: string;
  readonly icon?: JSX.Element;
  readonly categories?: Category[];
}

export interface GroupDropdownOptions {
  readonly label: string;
  readonly options: ReadonlyArray<DropdownOption>;
}

export const TRADE_OPTIONS: ReadonlyArray<GroupDropdownOptions> = [
  {
    label: "",
    options: [
      {
        label: "Quoted items",
        value: "quoted_items",
      },
      {
        label: "Sheet metal fabrication",
        value: "sheet_metal_fabrication",
      },
      {
        label: "Sheet metal",
        value: "sheet_metal",
      },
      {
        label: "Piping",
        value: "piping",
      },
      {
        label: "Control",
        value: "control",
      },
      {
        label: "Plumbing",
        value: "plumbing",
      },
      {
        label: "Service",
        value: "service",
      },
      {
        label: "Subcontracts",
        value: "subcontracts",
      },
      {
        label: "Engineering",
        value: "engineering",
      },
      {
        label: "Warehouse",
        value: "warehouse",
      },
      {
        label: "Project management",
        value: "project_management",
      },
      {
        label: "Quote",
        value: "quote",
      },
      {
        label: "Start up",
        value: "start_up",
      },
      {
        label: "Other expenses",
        value: "other_expenses",
      },
    ],
  },
];

export const DESC_ENGINEERING_OPTIONS: ReadonlyArray<GroupDropdownOptions> = [
  {
    label: "",
    options: [
      {
        label: "Mechanical engineering",
        value: "Mechanical engineering",
      },
      {
        label: "Electrical engineering",
        value: "Electrical engineering",
      },
      {
        label: "Structural engineering",
        value: "Structural engineering",
      },
      {
        label: "Plumbing engineering",
        value: "Plumbing engineering",
      },
      {
        label: "Other engineering",
        value: "Other engineering",
      },
    ],
  },
];

export const DESC_SUBCONTRACTOR_OPTIONS: ReadonlyArray<GroupDropdownOptions> = [
  {
    label: "",
    options: [
      {
        label: "Insulation",
        value: "Insulation",
      },
      {
        label: "Control",
        value: "Control",
      },
      {
        label: "Crane & rigging",
        value: "Crane & rigging",
      },
      {
        label: "Electrical",
        value: "Electrical",
      },
      {
        label: "Fire life safety",
        value: "Fire life safety",
      },
      {
        label: "Water treatment",
        value: "Water treatment",
      },
      {
        label: "Roofing & waterproofing",
        value: "Roofing & waterproofing",
      },
      {
        label: "Painting",
        value: "Painting",
      },
      {
        label: "Framing",
        value: "Framing",
      },
      {
        label: "General contractors",
        value: "General contractors",
      },
      {
        label: "Steel",
        value: "Steel",
      },
      {
        label: "Demolition & abatement",
        value: "Demolition & abatement",
      },
      {
        label: "Concrete",
        value: "Concrete",
      },
      {
        label: "Piping freeze/taps",
        value: "Piping freeze/taps",
      },
      {
        label: "Scanning & utility location",
        value: "Scanning & utility location",
      },
      {
        label: "Coring & saw cutting",
        value: "Coring & saw cutting",
      },
      {
        label: "Glazing",
        value: "Glazing",
      },
      {
        label: "Special inspections",
        value: "Special inspections",
      },
      {
        label: "3rd party commissioning",
        value: "3rd party commissioning",
      },
      {
        label: "Misc subcontract",
        value: "Misc subcontract",
      },
    ],
  },
];

export const QUANTITY_UNIT_OPTIONS: ReadonlyArray<GroupDropdownOptions> = [
  {
    label: "Item",
    options: [
      {
        display: "ea",
        label: "Each",
        value: "each",
      },
    ],
  },
  {
    label: "Distance",
    options: [
      {
        display: "LF",
        label: "Linear foot (LF)",
        value: "linear_foot",
      },
      {
        display: "in",
        label: "Inch (in)",
        value: "inches",
      },
    ],
  },
  {
    label: "Weight",
    options: [
      {
        display: "lbs",
        label: "Pounds (lbs)",
        value: "pounds",
      },
      {
        display: "t",
        label: "Ton (t)",
        value: "tons",
      },
    ],
  },
  {
    label: "Time",
    options: [
      {
        display: "hrs",
        label: "Hours (hrs)",
        value: "hours",
      },
      {
        display: "%",
        label: "% of trade total",
        value: "percent",
      },
    ],
  },
  {
    label: "Area",
    options: [
      {
        display: "sq ft",
        label: "Square foot (sq ft)",
        value: "square_foot",
      },
      {
        display: "cu ft",
        label: "Cubic foot (cu ft)",
        value: "cubic_foot",
      },
      {
        display: "CY",
        label: "Cubic yard (CY)",
        value: "cubic_yard",
      },
    ],
  },
];

export interface EstimationHeaderColumns {
  className?: string;
  index: number;
  label: string;
  value: string;
  width: number;
}
export const mainColumns: ReadonlyArray<EstimationHeaderColumns> = [
  // { label: "", width: 37, index: 8, value: "drag_and_drop" },
  {
    index: 1,
    label: "",
    value: "checkbox",
    width: 41,
  },
  {
    className: columnStyles.noLeftBorder,
    index: 2,
    label: "COST TYPE",
    value: "cost_type",
    width: 190,
  },
  { label: "TRADE", width: 150, index: 3, value: "trade" },
  { label: "DESCRIPTION", width: 180, index: 4, value: "description" },
  {
    className: columnStyles.numericColumn,
    index: 5,
    label: "QTY & UNIT",
    value: "qty",
    width: 130,
  },
  {
    className: columnStyles.numericColumn,
    index: 6,
    label: "UNIT COST",
    value: "cost",
    width: 130,
  },
  {
    className: columnStyles.numericColumn,
    index: 7,
    label: "SUBTOTAL($)",
    value: "subtotal",
    width: 130,
  },
];

export const laborColumns: ReadonlyArray<EstimationHeaderColumns> = [
  {
    className: columnStyles.tradeColumn,
    index: 1,
    label: "TRADE",
    value: "trade",
    width: 150,
  },
  { label: "DESCRIPTION", width: 180, index: 2, value: "description" },
  {
    className: columnStyles.numericColumn,
    index: 3,
    label: "HOURS",
    value: "hours",
    width: 130,
  },
  {
    className: columnStyles.numericColumn,
    index: 5,
    label: "SUBTOTAL($)",
    value: "subtotal",
    width: 130,
  },
];
