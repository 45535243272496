import { ActionIcon, Group, Menu } from "@mantine/core";
import {
  IconDotsVertical,
  IconEdit,
  IconStar,
  IconTrash,
} from "@tabler/icons-react";
import styles from "./AssemblyMenu.module.scss";

interface Props2 {
  handleDelete: () => void;
  handleEdit: () => void;
}

const AssemblyMenu = ({ handleDelete, handleEdit }: Props2) => {
  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon size={20} variant="transparent">
          <IconDotsVertical color="white" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown className={styles.root}>
        <Menu.Item disabled={true}>
          <Group gap="xs">
            <IconStar size={16} />
            Favorites
          </Group>
        </Menu.Item>
        <Menu.Item onClick={handleDelete}>
          <Group gap="xs">
            <IconTrash size={16} />
            Delete
          </Group>
        </Menu.Item>
        <Menu.Item onClick={handleEdit}>
          <Group gap="xs">
            <IconEdit size={16} />
            Edit
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default AssemblyMenu;
