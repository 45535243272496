import {
  Button,
  Center,
  FileButton,
  Loader,
  Menu,
  Stack,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconDotsVertical, IconPlus } from "@tabler/icons-react";
import classNames from "classnames";
import CryptoJS from "crypto-js";
import React from "react";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { Attachment } from "src/data/api/types/shared/attachment";
import { downloadS3File } from "src/utils/downloadS3File";
import { EMPTY_OBJECT, EMPTY_STRING } from "src/utils/empty";
import { isTruthy } from "src/utils/isTruthy";
import { uploadS3File } from "src/utils/uploadS3File";
import { AttachmentViewer } from "../AttachmentViewer";
import { TEXT_INPUT_STYLES } from "./constants";
import { useCellKeystrokes } from "./hooks/useCellKeystrokes";
import styles from "./ManageAttachmentsContent.module.scss";

function getMD5Checksum(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // eslint-disable-next-line immutable/no-mutation
    reader.onload = () => {
      const wordArray = CryptoJS.lib.WordArray.create(
        reader.result as ArrayBuffer,
      );

      const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
      resolve(md5Hash);
    };

    // eslint-disable-next-line immutable/no-mutation
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

function findAttachment(
  attachments: ReadonlyArray<Attachment> | undefined,
  openMenuId: string | null,
): Attachment | undefined {
  return attachments?.find(
    (attachment) => attachment.id.toString() === (openMenuId ?? EMPTY_STRING),
  );
}

interface Props {
  readonly onClose: () => void;
  readonly record: BundleLineItem;
}

export const ManageAttachmentsContent = React.memo<Props>(
  function _ManageAttachmentsContent({ onClose, record }) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [openMenuId, setOpenMenuId] = React.useState<string | null>(null);
    const [selectedAttachment, setSelectedAttachment] =
      React.useState<Attachment | null>(null);

    const [isRename, setIsRename] = React.useState<boolean>(false);
    const [isRenameEdited, setIsRenameEdited] = React.useState<boolean>(false);
    const [filename, setFilename] = React.useState<string>(EMPTY_STRING);
    const [lastUpdatedId, setLastUpdatedId] = React.useState<number | null>(
      null,
    );

    const [tempFilenames, setTempFilenames] =
      React.useState<Record<number, string>>(EMPTY_OBJECT);

    const [isPreviewOpen, { open: openPreview, close: closePreview }] =
      useDisclosure(false);

    const { currentData: attachments } = api.endpoints.getAttachments.useQuery({
      objectId: record.id,
      objectType: "LineItem",
    });

    const [generatePresignedUrl] =
      api.endpoints.generatePresignedUrl.useMutation();

    const [attachFiles] = api.endpoints.addAttachments.useMutation();
    const [updateAttachment] = api.endpoints.updateAttachment.useMutation();
    const [deleteAttachment] = api.endpoints.deleteAttachment.useMutation();

    /* 
      Remove temp filename record for the attachment whose filename was just
      updated w/in useQuery
    */
    React.useEffect(() => {
      if (lastUpdatedId == null) return;
      const attachment = findAttachment(attachments, lastUpdatedId.toString());
      if (attachment == null) return;
      if (attachment.title === tempFilenames[lastUpdatedId]) {
        setTempFilenames((prev) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [lastUpdatedId]: _, ...rest } = prev;
          return rest;
        });
      }
    }, [attachments, lastUpdatedId, tempFilenames]);

    React.useEffect(() => {
      const attachment = findAttachment(attachments, openMenuId);
      if (attachment == null) {
        return;
      }

      setSelectedAttachment(attachment);
    }, [attachments, openMenuId]);

    /* 
      When renaming, highlight everything but extension
    */
    React.useEffect(() => {
      if (inputRef.current && !isRenameEdited && isRename) {
        const periodIndex = filename.lastIndexOf(".");
        const highlightStart = 0;
        const highlightEnd = periodIndex !== -1 ? periodIndex : filename.length;
        // eslint-disable-next-line immutable/no-mutation
        inputRef.current.value = filename;
        inputRef.current.setSelectionRange(highlightStart, highlightEnd);
        inputRef.current.focus();
      }
    }, [filename, isRename, isRenameEdited]);

    const handleOpenMenu = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenMenuId(event.currentTarget.dataset.id ?? EMPTY_STRING);
      },
      [],
    );

    const handleUploadAttachment = React.useCallback(
      async (files: ReadonlyArray<File>) => {
        const signedIds = await Promise.all(
          files.map(async (file) => {
            try {
              const checksum = await getMD5Checksum(file);
              const urlData = await generatePresignedUrl({
                byte_size: file.size,
                checksum: checksum,
                content_type: file.type,
                filename: file.name,
                object_id: record.id,
                object_type: "LineItem",
              }).unwrap();

              /* 
                Upload file to S3
              */
              const response = await uploadS3File(
                file,
                urlData.direct_upload,
                urlData.headers,
              );

              if (!response.ok) {
                throw new Error(
                  `Failed to upload file: ${response.statusText}`,
                );
              }

              return urlData.signed_id;
            } catch (error) {
              notifications.show({
                title: "Error",
                message: `Failed to upload file: ${file.name}`,
              });

              return EMPTY_STRING;
            }
          }),
        );

        attachFiles({
          object_id: record.id,
          object_type: "LineItem",
          signed_ids: signedIds.filter(isTruthy),
        });
      },
      [attachFiles, generatePresignedUrl, record.id],
    );

    const handleDownloadAttachment =
      React.useCallback(async (): Promise<void> => {
        if (selectedAttachment == null) return;

        try {
          await downloadS3File(
            selectedAttachment.title,
            selectedAttachment.url,
          );
        } catch (error) {
          notifications.show({
            title: "Error",
            message: `Failed to download file: ${selectedAttachment.title}`,
          });
        }
      }, [selectedAttachment]);

    const handleRenameClick = React.useCallback(() => {
      setIsRename(true);
      setIsRenameEdited(false);
      if (selectedAttachment == null) return;
      setFilename(selectedAttachment.title);
    }, [selectedAttachment]);

    const handleFilenameChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsRenameEdited((prev) => (prev === false ? true : prev));
        setFilename(event.currentTarget.value);
      },
      [],
    );

    const { handleEnterBlur: handleKeyPresses } = useCellKeystrokes({
      ref: inputRef,
    });

    const handleRenameAttachment = React.useCallback(async () => {
      if (selectedAttachment == null) return;

      /* 
        Ensure correct extension
      */
      const currentExtension = selectedAttachment.title.substring(
        selectedAttachment.title.lastIndexOf("."),
      );
      const hasCorrectExtension = filename.endsWith(currentExtension);
      const updatedFilename = hasCorrectExtension
        ? filename
        : `${filename}${currentExtension}`;

      setTempFilenames((prev) => ({
        ...prev,
        [selectedAttachment.id]: updatedFilename,
      }));

      await updateAttachment({
        id: selectedAttachment.id,
        object_id: record.id,
        object_type: "LineItem",
        title: updatedFilename,
      });

      setLastUpdatedId(selectedAttachment.id);
    }, [filename, record.id, selectedAttachment, updateAttachment]);

    const handleRenameBlur = React.useCallback(() => {
      setIsRename(false);
      setIsRenameEdited(false);
      handleRenameAttachment();
    }, [handleRenameAttachment]);

    const handleDeleteAttachment = React.useCallback(async () => {
      if (selectedAttachment == null) return;

      try {
        await deleteAttachment({
          attachment_id: selectedAttachment.id,
          object_id: record.id,
          object_type: "LineItem",
        });
      } catch (error) {
        notifications.show({
          title: "Error",
          message: `Failed to delete file: ${selectedAttachment.title}`,
        });
      }
    }, [deleteAttachment, record.id, selectedAttachment]);

    if (attachments == null) {
      return (
        <Center h={88}>
          <Loader size={32} />
        </Center>
      );
    }

    return (
      <>
        <Stack className={styles.documentList}>
          {attachments.map((attachment) => {
            return isRename && attachment.id.toString() === openMenuId ? (
              <TextInput
                key={attachment.id}
                ref={inputRef}
                className={styles.renameField}
                onBlur={handleRenameBlur}
                onChange={handleFilenameChange}
                onKeyDown={handleKeyPresses}
                rightSectionPointerEvents={
                  filename !== EMPTY_STRING ? "all" : "none"
                }
                styles={TEXT_INPUT_STYLES}
                value={filename}
                variant="unstyled"
              />
            ) : (
              <Menu
                key={attachment.id}
                arrowPosition="center"
                closeOnClickOutside={true}
                closeOnItemClick={true}
                position="right-start"
                shadow="md"
                width={125}
                withArrow={true}
              >
                <Menu.Target>
                  <Button
                    className={classNames(styles.documentItem, styles.button)}
                    data-id={attachment.id}
                    onClick={handleOpenMenu}
                    rightSection={
                      <IconDotsVertical
                        className={styles.actionButton}
                        size={14}
                        stroke={2}
                      />
                    }
                    styles={{
                      inner: {
                        justifyContent: "space-between",
                      },
                      section: {
                        margin: 0,
                      },
                    }}
                    variant="subtle"
                  >
                    {tempFilenames[attachment.id] ?? attachment.title}
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    className={styles.attachmentOption}
                    disabled={
                      attachment.content_type !== "application/pdf" &&
                      !["image/png", "image/jpeg"].includes(
                        attachment.content_type,
                      )
                    }
                    onClick={openPreview}
                  >
                    Preview
                  </Menu.Item>
                  <Menu.Item
                    className={styles.attachmentOption}
                    onClick={handleDownloadAttachment}
                  >
                    Download
                  </Menu.Item>
                  <Menu.Item
                    className={styles.attachmentOption}
                    onClick={handleRenameClick}
                  >
                    Rename
                  </Menu.Item>
                  <Menu.Item
                    className={classNames(
                      styles.attachmentOption,
                      styles.delete,
                    )}
                    onClick={handleDeleteAttachment}
                  >
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            );
          })}

          <FileButton
            accept="image/png,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple={true}
            onChange={handleUploadAttachment}
          >
            {(props) => (
              <Button
                {...props}
                className={classNames(styles.uploadButton, styles.button)}
                leftSection={<IconPlus size={16} />}
                styles={{
                  inner: {
                    justifyContent: "flex-start",
                  },
                  section: {
                    marginRight: "4px",
                  },
                }}
                variant="subtle"
              >
                Add attachment(s)
              </Button>
            )}
          </FileButton>
        </Stack>

        <FormFooter
          rightSection={
            <Button onClick={onClose} variant="default">
              Close
            </Button>
          }
        />

        {isPreviewOpen && selectedAttachment != null ? (
          <AttachmentViewer
            attachment={selectedAttachment}
            onClose={closePreview}
            onDownload={handleDownloadAttachment}
          />
        ) : null}
      </>
    );
  },
);
