import { Text } from "@mantine/core";
import { DataTableColumnTextAlign } from "mantine-datatable";
import { useMemo } from "react";
import { PRICE_METHOD } from "src/components/EstimationView/Table/constants";
import { CostCell } from "src/components/Frames/Table/CostCell";
import { GroupNameCell } from "src/components/Frames/Table/GroupNameCell";
import { LaborHoursCell } from "src/components/Frames/Table/LaborHoursCell";
import { MarginCell } from "src/components/Frames/Table/MarginCell";
import { MarginTotalCell } from "src/components/Frames/Table/MarginTotalCell";
import { PercentOfCostCell } from "src/components/Frames/Table/PercentOfCostCell";
import { TotalCostCell } from "src/components/Frames/Table/TotalCostCell";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { calculateTotal } from "src/utils/calculateTotal";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "../GroupColumns.module.scss";

export const useGroupColumns = (
  records: PackageTotal[],
  expandedLineItemNames: string[],
) => {
  const [priceMethod] = useQueryStringParams(PRICE_METHOD);
  const WIDTH = "13%";

  const showFooter = records.length > 0;

  return useMemo(() => {
    return [
      {
        width: "22%",
        accessor: "name",
        title: "GROUP",
        render: (v: PackageTotal) => (
          <GroupNameCell
            expandable={true}
            expanded={expandedLineItemNames.includes(v.name)}
            label={v.display_name}
          />
        ),
        footer: showFooter ? (
          <Text className={styles.totals}>Totals</Text>
        ) : null,
        textAlign: "left" as DataTableColumnTextAlign,
      },
      {
        width: WIDTH,
        accessor: "percent_of_cost",
        title: "% OF COST",
        render: (v: PackageTotal) => (
          <PercentOfCostCell record={v} records={records} withTaxes={true} />
        ),
        footer: showFooter ? <Text className={styles.totals}>100%</Text> : null,
      },
      {
        width: WIDTH,
        accessor: "labor_hrs",
        title: "LABOR HRS",
        render: (v: PackageTotal) => <LaborHoursCell record={v} />,
        footer: showFooter ? (
          <Text
            className={styles.totals}
          >{`${calculateTotal(records, "total_hours")} hrs`}</Text>
        ) : null,
      },
      {
        width: WIDTH,
        accessor: "cost",
        title: "COST",
        render: (v: PackageTotal) => <CostCell record={v} />,
        footer: showFooter ? <TotalCostCell records={records} /> : null,
      },
      {
        width: WIDTH,
        accessor: "margin",
        title: priceMethod === "markup" ? "MARKUP" : "MARGIN",
        render: (v: PackageTotal) => <MarginCell record={v} />,
        footer: showFooter ? <MarginTotalCell records={records} /> : null,
      },
      {
        width: WIDTH,
        accessor: "sell_price",
        title: "SELL PRICE",
        render: (v: PackageTotal) => (
          <div className={styles.content}>
            {formatCurrency(Number(v.grand_total))}
          </div>
        ),
        footer: showFooter ? (
          <Text className={styles.totals}>
            {formatCurrency(calculateTotal(records, "grand_total"))}
          </Text>
        ) : null,
      },
    ];
  }, [expandedLineItemNames, priceMethod, records, showFooter]);
};
