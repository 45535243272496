import { Box, Group, Loader, Stack } from "@mantine/core";
import { useMemo } from "react";
import {
  DISTRIBUTION_PARAM,
  PACKAGE_PARAM,
} from "src/components/EstimationView/Table/constants";
import EstimationMarginForm from "src/components/Forms/EstimationMarginForm";
import MarginViewTable from "src/components/Tables/MarginViewTable";
import { useGetPackagesQuery } from "src/data/api/api";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";

interface Props {
  estimationId: string;
}

const EstimationMargin = ({ estimationId }: Props) => {
  const { currentData } = useGetPackagesQuery(estimationId);
  const [selectedPackageId] = useQueryStringParams(PACKAGE_PARAM);
  const [distribution] = useQueryStringParams(DISTRIBUTION_PARAM);

  const packages = useMemo(() => {
    if (currentData == null) {
      return [];
    }

    return currentData.collection;
  }, [currentData]);

  const packageName = useMemo(() => {
    const thePackage = packages.find(
      (_package) => _package.id === Number(selectedPackageId),
    );

    return thePackage?.title || `Package #${thePackage?.id}`;
  }, [packages, selectedPackageId]);

  if (selectedPackageId == null) {
    return <Loader />;
  }

  return (
    <Stack>
      <h1>{packageName}</h1>

      <Group align="start" w="100%">
        <EstimationMarginForm packageId={selectedPackageId} />

        {distribution != null ? (
          <Box w="70%">
            <MarginViewTable
              distribution={distribution}
              selectedPackageId={selectedPackageId}
            />
          </Box>
        ) : null}
      </Group>
    </Stack>
  );
};

export default EstimationMargin;
