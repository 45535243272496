import { Button } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useCallback } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { EMPTY_STRING } from "src/utils/empty";
import { SPLIT_OPTIONS } from "../EstimationView/Proposal/constants";
import { PACKAGE_PARAM } from "../EstimationView/Table/constants";

interface Props {
  view: string;
}

export const BackToEstimationViewButton = ({ view }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [packageId] = useQueryStringParams(PACKAGE_PARAM);

  const handleClick = useCallback(() => {
    const newLocation = location.pathname.replace(`/${view}`, "");

    navigate({
      pathname: newLocation,
      search: createSearchParams({
        packageId: packageId ?? EMPTY_STRING,
        split: SPLIT_OPTIONS.mid,
      }).toString(),
    });
  }, [location.pathname, navigate, packageId, view]);

  return (
    <Button
      leftSection={<IconArrowLeft size={16} />}
      onClick={handleClick}
      size="md"
      styles={{
        root: {
          alignSelf: "start",
          padding: 0,
        },
      }}
      variant="transparent"
    >
      Back to Estimation
    </Button>
  );
};
