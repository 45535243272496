import { Text } from "@mantine/core";
import React from "react";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "./TotalCostCell.module.scss";

interface Props {
  records: PackageTotal[];
}

export const TotalCostCell = React.memo<Props>(function _TotalCostCell({
  records,
}) {
  const value = React.useMemo(() => {
    return records
      .map((record) => Number(record.subtotal) + Number(record.taxes))
      .reduce((acc, record) => acc + record, 0);
  }, [records]);

  return <Text className={styles.root}>{formatCurrency(value)}</Text>;
});
