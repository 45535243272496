import { ValuesUnion } from "src/types/valuesUnion";

export const LoadingScreenType = {
  CrewMix: "CrewMix",
  Dashboard: "Dashboard",
  Editor: "Editor",
  Profile: "Profile",
  Table: "Table",
} as const;
export type LoadingScreenType = ValuesUnion<typeof LoadingScreenType>;
