export function formatNumber(
  value: number | null | undefined,
  minDecimalPlaces: number,
  maxDecimalPlaces: number,
): number | undefined {
  if (value == null) {
    return undefined;
  }

  const result = parseFloat(
    new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: minDecimalPlaces,
      maximumFractionDigits: maxDecimalPlaces,
      useGrouping: false,
    }).format(value),
  );

  return isNaN(result) ? undefined : result;
}
