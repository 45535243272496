import { useCallback, useMemo } from "react";
import { useScope } from "src/context/ScopeProvider/useScope";
import { useGetLineItemOptionsQuery } from "src/data/api/api";
import { getDropdownOptions } from "../../getDropdownOptions";
import { DropdownType } from "../constants";

export const useTradesOptions = (costType: string) => {
  const target = useScope();
  const { data } = useGetLineItemOptionsQuery(target);
  const { displayClassName, options } = getDropdownOptions(DropdownType.Trade);

  const trades = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.collection.cost_types[costType]?.trades || [];
  }, [costType, data]);

  const getOptions = useCallback(
    (currentValue: string | null) => {
      const currentTrades = trades.map((trade) => trade.value);

      if (currentValue != null) {
        currentTrades.push(currentValue);
      }

      return (
        options[0]?.options.filter((option) =>
          currentTrades.includes(option.value),
        ) ?? []
      );
    },
    [options, trades],
  );

  return { displayClassName, getOptions };
};
