/* eslint-disable immutable/no-mutation */
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { removeAuthToken } from "../api/utils";

export interface AuthState {
  readonly token: string | undefined;
}

const INITIAL_STATE: AuthState = {
  token: localStorage.getItem("token") ?? undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthState>) => {
      state.token = action.payload.token;
    },
    logout: (state) => {
      removeAuthToken();
      state.token = undefined;
    },
  },
});
