import { Textarea } from "@mantine/core";
import { useDebouncedState, useDidUpdate } from "@mantine/hooks";
import { ChangeEvent, useCallback } from "react";
import { Bundle } from "src/data/api/types/updateBundleArgs";

interface Props1 {
  disabled?: boolean;
  defaultValue: string;
  onChange: (bundle: Bundle) => void;
}

const AssemblyScope = ({ disabled, defaultValue, onChange }: Props1) => {
  const [scope, setScope] = useDebouncedState("", 200);

  const handleScope = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setScope(event.currentTarget.value);
    },
    [setScope],
  );

  useDidUpdate(() => {
    if (scope.length > 0 && scope !== defaultValue) {
      onChange({
        scope,
      });
    }
  }, [scope, onChange]);

  return (
    <Textarea
      autosize
      defaultValue={defaultValue}
      disabled={disabled}
      label="Scope"
      maxRows={7}
      minRows={3}
      onChange={handleScope}
      required
    />
  );
};

export default AssemblyScope;
